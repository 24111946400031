import route from 'ziggy-js';
import httpClient from '@/services/api/ky';

interface WeFileSettings {
  token: string;
  api_base_url: string;
}

export default {
  settings: {
    async get(): Promise<WeFileSettings> {
      return await httpClient.get(route('we-file.settings.get')).json();
    },
  },
};
