<script lang="ts" setup>
import { ref, computed } from 'vue';
import { clsx } from 'clsx';

import AppButton from '@/components/AppButton.vue';
import FontIcon from '@/components/FontIcon.vue';
import useTime from '@/composables/useTime';

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['change']);
const input = ref<HTMLInputElement>();

const props = withDefaults(
  defineProps<{
    size?: null | 'small' | 'large';
    max?: number;
    min?: number;
    disabled?: boolean;
  }>(),
  {
    size: null,
    max: 5985,
    min: 0,
  },
);

const modelValue = defineModel<number | null>({
  required: true,
});

const { convertMinutesToTime, parseMinutes } = useTime();

function increase(value: number) {
  modelValue.value = (modelValue.value ?? 0) + value >= props.max ? props.max : (modelValue.value ?? 0) + value;
  emit('change', modelValue.value);
}

function decrease(value: number) {
  modelValue.value = (modelValue.value ?? 0) - value <= props.min ? props.min : (modelValue.value ?? 0) - value;
  emit('change', modelValue.value);
}

function onChange(event: Event) {
  const input = event.target as HTMLInputElement;
  const { value } = input;
  const minutes = parseMinutes(value);
  // const rounded = minutes || Math.round(minutes / 15) * 15;
  if (minutes >= props.max) {
    input.value = convertMinutesToTime(props.max.toString());
    modelValue.value = props.max;
  } else if (minutes <= props.min) {
    input.value = convertMinutesToTime(props.min.toString());
    modelValue.value = props.min;
  } else {
    input.value = convertMinutesToTime(minutes.toString());
    modelValue.value = minutes;
  }
  emit('change', modelValue.value);
}

const classes = computed(() =>
  clsx('form-wrapper', 'd-flex', 'align-items-center', {
    [`is-${props.size}`]: props.size,
  }),
);
</script>

<template>
  <div :class="classes">
    <AppButton
      @click.prevent="decrease(15)"
      type="button"
      light
      circle
      size="small"
      class="mr-1"
      :disabled="disabled || (modelValue ?? 0) <= min"
    >
      <FontIcon name="minus" />
    </AppButton>
    <input
      v-bind="$attrs"
      class="form-control text-center w-auto"
      :value="convertMinutesToTime(modelValue ?? 0)"
      @keydown.enter.prevent="input?.blur"
      placeholder="00:00"
      size="4"
      :disabled="disabled"
      @change="onChange"
      ref="input"
    />
    <AppButton
      @click.prevent="increase(15)"
      type="button"
      light
      circle
      size="small"
      class="ml-1"
      :disabled="disabled || (modelValue ?? 0) >= max"
    >
      <FontIcon name="plus" />
    </AppButton>
  </div>
  <slot />
</template>
