<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { useModal } from 'vue-final-modal';
import { debounce } from 'perfect-debounce';
import { useRouteQuery } from '@vueuse/router';

import toast from '@/services/toast';
import api from '@/services/api';
import progress from '@/services/progress';
import useLoader from '@/composables/useLoader';
import authStore from '@/store/AuthStore';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  FontIcon,
  FormLabel,
  FormNumber,
  HelpInformation,
  SalaryCodeModal,
} from '@/components';
import { ConfirmDialogConfirmParams } from '@/types/Common';
import { ISalaryCodeResource, ISalarySettingsRequest, SalarySystem } from '@/types/Salary';

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { isSuperAdminRole, isCustomerAdminRole } = authStore();

const settings = reactive<ISalarySettingsRequest>({
  system: null,
  mileage_allowance_amount: 0,
});

const page = useRouteQuery('page', '1', { transform: Number });
const perPage = ref(0);
const total = ref(0);

const salarySettingsExists = ref(false);
const salarySettingsLoading = ref(false);

const salaryCodes = ref<ISalaryCodeResource[]>([]);
const salaryCodesLoading = ref(false);

const canManage = computed(() => isCustomerAdminRole || isSuperAdminRole);

async function getSalarySettings() {
  try {
    salarySettingsLoading.value = true;
    const response = await api.salary.settings.index();
    salarySettingsExists.value = !!response.data;
    settings.system = response.data?.system ?? null;
    settings.mileage_allowance_amount = response.data?.mileage_allowance_amount ?? 0;
  } catch (error) {
    console.error(error);
  } finally {
    salarySettingsLoading.value = false;
  }
}

async function updateSalarySettings() {
  try {
    progress.start();
    salarySettingsLoading.value = true;
    await api.salary.settings.update(settings);
    salarySettingsExists.value = true;
  } catch (error) {
    console.error(error);
  } finally {
    salarySettingsLoading.value = false;
    progress.done();
  }
}

async function getSalaryCodes() {
  try {
    salaryCodesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    const response = await api.salary.codes.index({ searchParams });
    salaryCodes.value = response.data;
    if (response.meta) {
      perPage.value = response.meta.per_page;
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  } finally {
    salaryCodesLoading.value = false;
  }
}

function onCreate() {
  if (settings.system === null) return;
  const { open, close, destroy } = useModal({
    component: SalaryCodeModal,
    attrs: {
      salaryCode: null,
      system: settings.system,
      async onCreated() {
        await getSalaryCodes();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEdit(salaryCode: ISalaryCodeResource) {
  if (settings.system === null) return;
  const { open, close, destroy } = useModal({
    component: SalaryCodeModal,
    attrs: {
      salaryCode,
      system: settings.system,
      async onUpdated() {
        await getSalaryCodes();
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onDelete(salaryCode: ISalaryCodeResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('salary.codes.destroy.title'),
      message: t('salary.codes.destroy.text', { name: salaryCode.name }),
      async onConfirm({ setLoading }: ConfirmDialogConfirmParams) {
        try {
          setLoading(true);
          await api.salary.codes.delete(salaryCode.id);
          salaryCodes.value = salaryCodes.value.filter(({ id }) => id !== salaryCode.id);
          toast.success(t('common.messages.has_been_deleted', { name: salaryCode.name }));
          await close();
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

onMounted(async () => {
  loader.start();
  await Promise.all([getSalaryCodes(), getSalarySettings()]);
  loader.finish();

  watch(settings, debounce(updateSalarySettings, 1000), { deep: true });
});

watch(page, async () => {
  loader.start();
  await getSalaryCodes();
  loader.finish();
});
</script>

<template>
  <div class="container-wide">
    <!-- Settings header -->
    <div class="d-flex align-items-center mb-3">
      <h1 class="mb-0" v-text="t('salary.settings.title')" />
      <HelpInformation class="ml-1" translation="salary.settings.help" />
    </div>

    <!-- Settings -->
    <AppBox>
      <AppBoxBody>
        <div class="form-group row d-flex align-items-center">
          <div class="col-md-2">
            <FormLabel class="mb-0" html-for="settings_system" required>
              {{ t('salary.settings.attributes.system') }}
            </FormLabel>
          </div>
          <div class="col-md-4">
            <VueSelect
              input-id="settings_system"
              v-model="settings.system"
              :options="Object.values(SalarySystem)"
              :clearable="false"
              :disabled="salarySettingsLoading"
              :get-option-label="(option: SalarySystem) => option.charAt(0).toUpperCase() + option.slice(1)"
              :placeholder="t('common.select')"
            />
          </div>
        </div>
        <div class="form-group row d-flex align-items-center mb-0">
          <div class="col-md-2">
            <FormLabel class="mb-0 text-nowrap" html-for="settings_mileage_allowance_amount" required>
              {{ t('salary.settings.attributes.mileage_allowance_amount') }}
            </FormLabel>
          </div>
          <div class="col-md-4">
            <FormNumber
              :length="6"
              id="settings_mileage_allowance_amount"
              v-model="settings.mileage_allowance_amount"
              :min="0"
              :max="100000"
              :step="0.1"
              :decimal-places="2"
              :disabled="salarySettingsLoading"
            />
          </div>
        </div>
      </AppBoxBody>
    </AppBox>

    <!-- Loader -->
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>

    <!-- Salary codes -->
    <template v-else>
      <div class="mt-4" v-if="salarySettingsExists">
        <!-- Codes header -->
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-3">
            <h2 class="mb-0" v-text="t('salary.codes.title')" />
            <HelpInformation class="ml-1" translation="salary.codes.help" />
          </div>
          <AppButton v-if="canManage" color="secondary" @click.prevent="onCreate" :disabled="loader.isLoading.value">
            {{ t('salary.codes.add_new') }}
          </AppButton>
        </div>
        <!-- Table -->
        <AppAlert v-if="salaryCodes.length === 0">
          {{ t('salary.codes.empty') }}
        </AppAlert>
        <template v-else>
          <AppTable hoverable>
            <AppTableHead>
              <AppTableTr>
                <AppTableTh nowrap>{{ t('salary.codes.attributes.name') }}</AppTableTh>
                <AppTableTh nowrap class="text-right">{{ t('salary.codes.attributes.system_id') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('salary.codes.attributes.type') }}</AppTableTh>
                <AppTableTh nowrap class="text-right" v-if="settings.system === SalarySystem.VISMA">
                  {{ t('salary.codes.attributes.absence_type') }}
                </AppTableTh>
                <AppTableTh nowrap class="text-right">
                  {{ t('salary.codes.attributes.is_default') }}
                </AppTableTh>
                <AppTableTh nowrap>{{ t('salary.codes.attributes.client_work_time') }}</AppTableTh>
                <AppTableTh nowrap class="text-right" v-if="canManage">{{ t('common.actions') }}</AppTableTh>
              </AppTableTr>
            </AppTableHead>
            <AppTableBody>
              <AppTableTr v-for="salaryCode in salaryCodes" :key="salaryCode.id">
                <AppTableTd nowrap><strong v-text="salaryCode.name" /></AppTableTd>
                <AppTableTd nowrap class="text-right">{{ salaryCode.system_id }}</AppTableTd>
                <AppTableTd nowrap>{{ salaryCode.type }}</AppTableTd>
                <AppTableTd nowrap class="text-right" v-if="settings.system === SalarySystem.VISMA">
                  {{ salaryCode.absence_type }}
                </AppTableTd>
                <AppTableTd nowrap class="text-right">
                  <FontIcon class="text-3" v-if="salaryCode.is_default" name="check " />
                </AppTableTd>
                <AppTableTd nowrap>{{ t(`common.${salaryCode.client_work_time ? 'yes' : 'no'}`) }}</AppTableTd>
                <AppTableTd nowrap class="text-right" v-if="canManage">
                  <AppButton
                    v-if="!salaryCode.is_in_use"
                    @click.stop.prevent="onEdit(salaryCode)"
                    size="small"
                    light
                    circle
                    v-tooltip.left="t('common.tooltip.edit', { name: salaryCode.name })"
                  >
                    <FontIcon name="pencil" />
                  </AppButton>
                  <AppButton
                    v-if="!salaryCode.is_in_use"
                    @click.stop.prevent="onDelete(salaryCode)"
                    class="ml-2"
                    color="danger"
                    size="small"
                    light
                    circle
                    v-tooltip.left="t('common.tooltip.delete', { name: salaryCode.name })"
                  >
                    <FontIcon name="trash" />
                  </AppButton>
                </AppTableTd>
              </AppTableTr>
            </AppTableBody>
          </AppTable>
          <AppPagination :per-page="perPage" :total="total" v-model="page" />
        </template>
      </div>
      <AppAlert class="mt-3" v-else type="danger">{{ t('salary.message.system_is_not_set') }}</AppAlert>
    </template>
  </div>
</template>
