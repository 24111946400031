export default {
  index: {
    title: 'ToDo & meddelanden',
    help: '',
    create: 'Skapa',
  },
  attributes: {
    type: 'Typ',
    receiver: 'Mottagare',
    text: 'Text',
    deadline: 'Deadline',
    status: 'Status',
    created_by: 'Skapad av',
    updated_at: 'Uppdaterad av',
  },
  filters: {
    search: 'Sök',
    from_date: 'Från datum',
    to_date: 'Till datum',
    created_by: 'Skapad av',
    receiver: 'Mottagare',
    client: 'Kund',
    project: 'Projekt',
    type: 'Typ',
    status: 'Status',
  },
  tooltip: {
    complete: 'Klarmarkera uppgift',
    edit: 'Redigera uppgift',
    destroy: 'Radera uppgift',
  },
  empty: 'Inga uppgifter',
  // Confirms
  confirm: {
    destroy: {
      title: 'Radera uppgift',
      text: 'Är du säker att du vill radera uppgiften?',
    },
  },
};
