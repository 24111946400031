<script setup lang="ts">
import { inject, ref, Ref } from 'vue';
import { IEventTimelineWeek } from '@/types/Event';
import { AppBox, AppBoxBody, AppButton, FontIcon, PlanningProgressMultiBar } from '@/components';
import useTime from '@/composables/useTime';

defineProps<{
  collapsed: boolean;
  data: IEventTimelineWeek['tasks']['events'][0]['projects'][0];
  areAllTaskDone: boolean;
  clientUuid: string;
  loadingTasksToDone: number[];
}>();

const emit = defineEmits<{
  (e: 'toggle', projectId: number): void;
  (e: 'taskStartDrag', event: DragEvent, id: number): void;
  (e: 'taskEndDrag', event: DragEvent): void;
  (e: 'taskEdit', taskId: number): void;
  (e: 'taskDone', taskId: number): void;
}>();

const { convertMinutesToTime } = useTime();

const hideDone = inject<Ref<number>>('hide_done', ref(0));
</script>

<template>
  <AppBox shadow v-if="!(hideDone === 1 && areAllTaskDone)">
    <AppBoxBody>
      <div class="row d-flex align-items-center">
        <div class="col-3">
          <RouterLink
            target="_blank"
            :to="{ name: 'projects.edit', params: { uuid: clientUuid, id: data.id } }"
            class="pointer unlink px-0"
          >
            <div class="pl-4">
              <strong v-text="data.name" />
            </div>
          </RouterLink>
        </div>
        <div class="col-1 text-right">
          <AppButton size="small" @click.prevent="emit('toggle', data.id)" light circle>
            <FontIcon v-if="collapsed" name="chevron-down" />
            <FontIcon v-else name="chevron-up" />
          </AppButton>
        </div>
        <div class="col-1 text-center">
          <strong v-text="convertMinutesToTime(data.time.budgeted)" />
        </div>
        <div class="col-1">
          <FontIcon v-if="areAllTaskDone" class="text-success-500 text-2 ml-3" name="check" />
        </div>
        <div class="col-6">
          <PlanningProgressMultiBar
            :green="data.time.finished"
            :orange="data.time.reported"
            :gray="Math.max(data.time.remaining, 0)"
          />
        </div>
      </div>
      <div class="mt-2" v-if="!collapsed">
        <div
          class="row align-items-center py-3"
          :draggable="!task.done_at"
          v-for="(task, index) in data.tasks"
          :key="index"
          :class="[{ draggable: !task.done_at }, hideDone === 1 && task.done_at ? 'd-none' : 'd-flex']"
          @dragstart="emit('taskStartDrag', $event, task.id)"
          @dragend="emit('taskEndDrag', $event)"
        >
          <div class="col-4 font-bold">
            <div @click.prevent="emit('taskEdit', task.id)" class="pointer text-secondary-500 text-5 pl-4">
              {{ task.name }}
              <template v-if="task.sub_label"> - {{ task.sub_label }} </template>
            </div>
          </div>
          <div class="col-1 text-center">
            <strong v-text="convertMinutesToTime(task.time.budgeted)" />
          </div>
          <div class="col-1">
            <FontIcon v-if="task.done_at" class="text-success-500 text-2 ml-3" name="check" />
          </div>
          <div class="col-6 d-flex align-items-center">
            <div class="flex-grow-1">
              <PlanningProgressMultiBar
                :green="task.time.finished"
                :orange="task.time.reported"
                :gray="Math.max(task.time.remaining, 0)"
              />
            </div>
            <div v-if="!task.done_at" class="flex-shrink-0 pl-2">
              <AppButton
                color="success"
                circle
                light
                size="small"
                @click.prevent="emit('taskDone', task.id)"
                :loading="loadingTasksToDone.includes(task.id)"
              >
                <FontIcon name="check" />
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </AppBoxBody>
  </AppBox>
</template>
