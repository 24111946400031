import route from 'ziggy-js';
import { SearchParamsOption } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  IInvoiceCreateRequestBody,
  IInvoicePreviewResource,
  IInvoiceRequestBody,
  IInvoiceResource,
} from '@/types/Invoice';

export default {
  async index(
    searchParams?: SearchParamsOption,
  ): Promise<IResponse<IInvoicePreviewResource[]> & { total_sum: string }> {
    return await httpClient.get(route('invoices.index'), { searchParams }).json();
  },
  async get(invoice: number): Promise<IResponse<IInvoiceResource>> {
    return await httpClient.get(route('invoices.get', { invoice })).json();
  },
  async store(body: IInvoiceCreateRequestBody): Promise<IResponse<IInvoiceResource>> {
    return await httpClient.post(route('invoices.store'), { json: body }).json();
  },
  async update(body: IInvoiceRequestBody, invoice: number): Promise<IResponse<IInvoiceResource>> {
    return await httpClient.put(route('invoices.update', { invoice }), { json: body }).json();
  },
  async destroy(invoice: number): Promise<void> {
    return await httpClient.delete(route('invoices.delete', { invoice })).json();
  },
  async approve(body: IInvoiceRequestBody, invoice: number): Promise<IResponse<IInvoiceResource>> {
    return await httpClient.post(route('invoices.approve', { invoice }), { json: body }).json();
  },
  async generate(timePeriod: number): Promise<IResponse<never>> {
    return await httpClient.post(route('invoices.generate', { timePeriod })).json();
  },
};
