import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import Projects from '@/views/Projects/Projects.vue';
import Project from '@/views/Projects/Project.vue';
import ProjectView from '@/views/Projects/ProjectView.vue';

const prefix = 'projects';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Projects,
    props: true,
  },
  {
    path: `/${prefix}/:uuid/:id`,
    name: `${prefix}.view`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ProjectView,
    props: (route) => ({
      ...route.params,
      id: Number(route.params.id),
    }),
  },
  {
    path: `/${prefix}/:uuid/create`,
    name: `${prefix}.create`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Project,
    props: true,
  },
  {
    path: `/${prefix}/:uuid/:id/edit`,
    name: `${prefix}.edit`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Project,
    props: (route) => ({
      ...route.params,
      id: Number(route.params.id),
    }),
  },
];

export default routes;
