import route from 'ziggy-js';
import { Options } from 'ky';

import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { ICreateActivityRequest, IActivityResource } from '@/types/Activity';

export default {
  async store(json: ICreateActivityRequest, options: Options = {}): Promise<IResponse<IActivityResource>> {
    return await httpClient.post(route('activities.store'), { ...options, json }).json();
  },
  async destroy(activity: number): Promise<IResponse<never>> {
    return await httpClient.delete(route('activities.destroy', { activity })).json();
  },
};
