<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import { useRefHistory, syncRef } from '@vueuse/core';
import { klona } from 'klona';
import { DateTime } from 'luxon';

import { AppButton, FontIcon } from '@/components';
import useHelpers from '@/composables/useHelpers';
import { IBillingCell, IProjectPlannedBillingPlan } from '@/types/Project';

const emit = defineEmits<{
  change: [{ total: number }];
  added: [plan: IProjectPlannedBillingPlan];
}>();

type Props = {
  title: string;
  cells: IBillingCell[];
  disabled?: boolean;
};

const props = defineProps<Props>();

const modelValue = defineModel<IProjectPlannedBillingPlan[]>({
  required: true,
});

const plans = ref<IProjectPlannedBillingPlan[]>(klona(modelValue.value));

syncRef(modelValue, plans);

const { undo, canUndo, redo, canRedo } = useRefHistory(plans, { deep: true, clone: klona });

const billingPlansElement = ref<null | HTMLUListElement>(null);

const { t, d } = useI18n({ useScope: 'global' });
const { parseAmount } = useHelpers();

const cellWithPlans = computed<(IBillingCell & { plans: IProjectPlannedBillingPlan[] })[]>(() =>
  props.cells.map((cell) => {
    const plans = modelValue.value.filter((plan) => cell.month === plan.month && cell.year === plan.year);
    return { ...cell, plans };
  }),
);

const total = computed(() => modelValue.value.reduce((total, current) => total + parseAmount(current.price), 0));

function onDragPlanStart(event: DragEvent, plan: IProjectPlannedBillingPlan) {
  if (!event.dataTransfer) return;
  event.stopPropagation();
  const target = event.target as HTMLDivElement;
  target.classList.add('dragging');
  event.dataTransfer.dropEffect = 'move';
  event.dataTransfer.effectAllowed = 'move';
  event.dataTransfer.setData('plan_uid', plan.uid);
}

function onDragPlanEnd(event: DragEvent) {
  (event.target as HTMLDivElement).classList.remove('dragging');
}

function dropPlan(event: DragEvent, cell: IBillingCell) {
  if (!event.dataTransfer) return;
  const uid = event.dataTransfer.getData('plan_uid');
  event.preventDefault();
  event.stopPropagation();
  const plan = modelValue.value.find((plan) => plan.uid === uid);
  if (!plan) return;
  plan.month = cell.month;
  plan.year = cell.year;
  const element = (event.target as HTMLElement).closest('.billing-plan-cell.droppable');
  if (element) element.classList.remove('over');
}

function onDragOver(event: DragEvent) {
  if (!billingPlansElement.value) return;
  const containerRect = billingPlansElement.value.getBoundingClientRect();
  const scrollThreshold = 50;
  if (event.clientX < containerRect.left + scrollThreshold) {
    billingPlansElement.value.scrollLeft -= 10;
  } else if (event.clientX > containerRect.right - scrollThreshold) {
    billingPlansElement.value.scrollLeft += 10;
  }
}

function onCellOver(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.billing-plan-cell.droppable');
  if (element) element.classList.add('over');
}

function onCellLeave(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.billing-plan-cell.droppable');
  if (element) element.classList.remove('over');
}

function onPlansScroll(e: WheelEvent) {
  const element = billingPlansElement.value as HTMLUListElement;
  const race = 50;
  element.scrollLeft = e.deltaY > 0 ? element.scrollLeft + race : element.scrollLeft - race;
  e.preventDefault();
}

function onDeletePlan(event: KeyboardEvent, plan: IProjectPlannedBillingPlan) {
  if (plan.is_invoiced || event.code !== 'Delete') return;
  modelValue.value = modelValue.value.filter(({ uid }) => uid !== plan.uid);
  emit('change', { total: total.value });
}

function onAddPlan(cell: IBillingCell) {
  const plan: IProjectPlannedBillingPlan = {
    uid: nanoid(),
    month: cell.month,
    year: cell.year,
    price: 0,
    is_invoiced: false,
  };
  modelValue.value.push(plan);
  emit('added', plan);
}

function onPlanChange(event: Event, plan: IProjectPlannedBillingPlan) {
  plan.price = parseAmount((event.target as HTMLInputElement).value);
  emit('change', { total: total.value });
}

function throwPlan(event: DragEvent) {
  if (!event.dataTransfer) return;
  const uid = event.dataTransfer.getData('plan_uid');
  event.preventDefault();
  event.stopPropagation();
  const plan = modelValue.value.find((plan) => plan.uid === uid);
  if (!plan) return;
  modelValue.value = modelValue.value.filter(({ uid }) => uid !== plan.uid);
  emit('change', { total: total.value });
  const element = (event.target as HTMLElement).closest('.billing-plan-trash');
  if (element) element.classList.remove('over');
}

function onTrashOver(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.billing-plan-trash');
  if (element) element.classList.add('over');
}

function onTrashLeave(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.billing-plan-trash');
  if (element) element.classList.remove('over');
}
</script>

<template>
  <div>
    <h2 v-text="title" />
    <ul
      v-dragscroll
      class="billing-plans"
      @wheel="onPlansScroll"
      ref="billingPlansElement"
      @dragover.prevent="onDragOver"
    >
      <li class="billing-plan" v-for="cell in cellWithPlans" :key="cell.uid">
        <div class="billing-plan-header">
          {{ d(DateTime.local(cell.year, cell.month, 1).toJSDate(), 'monthyear') }}
        </div>
        <div
          class="billing-plan-cell"
          :class="{ droppable: !disabled }"
          @drop.prevent="dropPlan($event, cell)"
          @dragenter.prevent
          @dragover.prevent="onCellOver"
          @dragleave.prevent="onCellLeave"
        >
          <div
            v-for="plan in cell.plans"
            :key="plan.uid"
            :class="{ draggable: !disabled }"
            :draggable="!(disabled || plan.is_invoiced)"
            @dragstart="onDragPlanStart($event, plan)"
            @dragend="onDragPlanEnd($event)"
          >
            <span
              v-if="disabled || plan.is_invoiced"
              v-text="plan.price"
              :class="{ 'text-success-500': plan.is_invoiced }"
            />
            <input
              v-else
              data-no-dragscroll
              class="form-control pointer"
              type="number"
              :value="plan.price"
              min="1"
              max="9999999"
              @keyup.delete.prevent="onDeletePlan($event, plan)"
              @change="onPlanChange($event, plan)"
            />
          </div>
        </div>
        <div class="billing-plan-footer" v-if="!disabled">
          <div @click.prevent="onAddPlan(cell)" class="billing-plan-add" v-tooltip="t('common.add')">
            <FontIcon name="plus" />
          </div>
        </div>
      </li>
    </ul>
    <div class="mt-3" v-if="!disabled">
      <div
        class="billing-plan-trash"
        @drop.prevent="throwPlan($event)"
        @dragenter.prevent
        @dragover.prevent="onTrashOver"
        @dragleave.prevent="onTrashLeave"
      >
        <FontIcon name="trash" />
      </div>
      <div class="d-none">
        <AppButton :disabled="!canUndo" @click.prevent="undo" light size="small">
          {{ t('common.undo') }}
          <FontIcon name="arrow-back-up" />
        </AppButton>
        <AppButton class="ml-2" :disabled="!canRedo" @click.prevent="redo" light size="small">
          <FontIcon name="arrow-forward-up" />
          {{ t('common.redo') }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.draggable {
  cursor: pointer;
  &.dragging {
    opacity: 0.25;
    background-color: white;
  }
}
.droppable {
  &.over {
    background-color: var(--color-neutral-100-hex);
  }
}
</style>
