<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { onMounted, reactive, ref, watch } from 'vue';
import { useModal } from 'vue-final-modal';
import { Decimal } from 'decimal.js';
import { DateTime } from 'luxon';

import {
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTh,
  AppTableTr,
  AppTableTd,
  FontIcon,
  EmptyInvoiceModal,
  AppCollapse,
  AppAlert,
  ScheduleItem,
  ScheduleItemPlan,
  ScheduleItemPostpone,
} from '@/components';

import api from '@/services/api';
import useLoader from '@/composables/useLoader';

import { ITimePeriodResource } from '@/types/TimePeriod';
import { IClientBillingProject, IClientBillingInvoice, IClientBillingProjectsTotal } from '@/types/Client';
import { ProjectStatusType } from '@/types/Project';

const router = useRouter();
const { n, t, d } = useI18n({ useScope: 'global' });

const loader = useLoader({ useProgress: false });

const props = defineProps<{
  uuid: string;
}>();

const filters = reactive({
  active: false,
  project_responsible: false,
  month: 0,
});

const invoices = ref<IClientBillingInvoice[]>([]);
const projects = ref<IClientBillingProject[]>([]);
const total = reactive<IClientBillingProjectsTotal>({
  invoiced: [],
  postponed: [],
  written_down: [],
  original: [],
});

const timePeriods = ref<ITimePeriodResource[]>([]);
const timePeriod = reactive<{ id: null | number }>({
  id: null,
});

const createEmptyInvoice = useModal({
  component: EmptyInvoiceModal,
  // @ts-ignore
  attrs: {
    async onCreate() {
      try {
        // @ts-ignore
        createEmptyInvoice.patchOptions({ attrs: { loading: true } });
        // @ts-ignore
        const response = await api.invoices.store({ time_period_id: timePeriod.id, client_uuid: props.uuid });
        await createEmptyInvoice.close();
        await router.push({ name: 'invoices.edit', params: { id: response.data.id } });
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        createEmptyInvoice.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      createEmptyInvoice.close();
    },
  },
});

async function getBilling(number?: number) {
  try {
    if (number) filters.month += number;
    loader.start();

    const searchParams = new URLSearchParams();
    searchParams.append('month', filters.month.toString());
    searchParams.append('statuses[]', ProjectStatusType.Active);

    if (filters.active) searchParams.append('statuses[]', ProjectStatusType.Cancelled);
    if (filters.active) searchParams.append('statuses[]', ProjectStatusType.Draft);
    if (filters.active) searchParams.append('statuses[]', ProjectStatusType.Done);
    const response = await api.clients.billing.list(props.uuid, searchParams);

    invoices.value = response.data.invoices;
    projects.value = response.data.projects.data;
    total.invoiced = response.data.projects.total.invoiced;
    total.postponed = response.data.projects.total.postponed;
    total.written_down = response.data.projects.total.written_down;
    total.original = response.data.projects.total.original;
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
}

async function getTimePeriods() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.timePeriods.index({ searchParams });
    timePeriods.value = response.data;

    const matchingTimePeriod = response.data.find((timePeriod) => {
      return timePeriod.year === new Date().getFullYear() && timePeriod.month === new Date().getMonth() + 1;
    });

    timePeriod.id = matchingTimePeriod ? matchingTimePeriod.id : null;
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  await Promise.all([getBilling(), getTimePeriods()]);
});

watch(filters, async () => {
  await getBilling();
});

function isCurrentMonthAndYear(date: string) {
  const currentDate = DateTime.now();
  const dateToCompare = DateTime.fromISO(`${date}-01`);
  return currentDate.year === dateToCompare.year && currentDate.month === dateToCompare.month;
}
</script>

<template>
  <div class="d-flex align-items-center justify-content-between">
    <h1 class="mb-0" v-text="t('client.billing.title')" />
    <div>
      <AppButton @click.prevent="createEmptyInvoice.open" color="secondary">
        {{ t('client.billing.attributes.create_empty_invoice') }}
      </AppButton>
      <AppButton
        class="ml-2"
        @click.prevent="router.push({ name: 'users.create' })"
        disabled="disabled"
        color="secondary"
      >
        {{ t('client.billing.attributes.create_new_periodical_invoice') }}
      </AppButton>
    </div>
  </div>
  <AppCollapse class="my-3" :title="t('common.filters')">
    <div class="d-flex align-items-center mt-3">
      <div class="form-group">
        <input id="show_inactive_projects" type="checkbox" class="form-check" value="1" v-model="filters.active" />
        <label
          for="show_inactive_projects"
          class="form-label"
          v-text="t('client.billing.attributes.show_inactive_projects')"
        />
      </div>
      <div class="form-group ml-4">
        <input
          id="show_project_responsible"
          type="checkbox"
          class="form-check"
          value="1"
          disabled
          v-model="filters.project_responsible"
        />
        <label
          disabled=""
          for="show_project_responsible"
          class="form-label"
          v-text="t('client.billing.attributes.show_my_responsible_only')"
        />
      </div>
    </div>
  </AppCollapse>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <AppTable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh colspan="14" nowrap>
            <h2 class="text-neutral-900 mb-0" v-text="t('client.billing.invoice_overview')" />
          </AppTableTh>
        </AppTableTr>
        <AppTableTr>
          <AppTableTh style="vertical-align: middle; width: 11.6%" nowrap>
            <div class="d-flex align-items-center justify-content-between flex-nowrap">
              <div>
                <AppButton size="small" @click.prevent="getBilling(-3)" light circle v-tooltip.top="'-3'">
                  <FontIcon name="chevrons-left" />
                </AppButton>
                <AppButton class="ml-1" size="small" @click.prevent="getBilling(-1)" light circle v-tooltip.top="'-1'">
                  <FontIcon name="chevron-left" />
                </AppButton>
              </div>
              <span class="px-2">{{ t('common.months') }}</span>
              <div>
                <AppButton size="small" @click.prevent="getBilling(1)" light circle v-tooltip.top="'+1'">
                  <FontIcon name="chevron-right" />
                </AppButton>
                <AppButton class="ml-1" size="small" @click.prevent="getBilling(3)" light circle v-tooltip.top="'+3'">
                  <FontIcon name="chevrons-right" />
                </AppButton>
              </div>
            </div>
          </AppTableTh>
          <AppTableTh
            style="text-align: center; vertical-align: middle; width: calc(88.4% / 13)"
            :class="isCurrentMonthAndYear(item.date) ? 'current-month-accent' : ''"
            :key="item.date"
            v-for="item in invoices"
            nowrap
          >
            {{ d(DateTime.fromISO(String(item.date)).toJSDate(), 'monthyear') }}
          </AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr>
          <AppTableTd style="vertical-align: top" class="font-bold">
            {{ t('client.billing.attributes.scheduled') }}
          </AppTableTd>
          <AppTableTd
            :class="isCurrentMonthAndYear(item.date) ? 'current-month-blink' : ''"
            style="vertical-align: top"
            :key="item.date"
            v-for="item in invoices"
            nowrap
          >
            <ScheduleItem v-for="invoice in item.invoices" :key="invoice.id" :invoice="invoice" :planned="false" />
            <ScheduleItemPlan v-for="billing_plan in item.billing_plans" :key="billing_plan.id" :plan="billing_plan" />
            <ScheduleItemPostpone v-for="postpone in item.postpones" :key="postpone.id" :postpone="postpone" />
            <!--            <div-->
            <!--              class="scheduled-item is-planned"-->
            <!--              v-for="billing_plan in item.billing_plans"-->
            <!--              :key="billing_plan.id"-->
            <!--              :data-type="t(`client.billing.fees.${billing_plan.type}`)"-->
            <!--            >-->
            <!--              {{ n(new Decimal(billing_plan.price ?? 0).toNumber(), 'currency') }}-->
            <!--            </div>-->
            <!--            <div-->
            <!--              class="scheduled-item is-planned"-->
            <!--              v-for="postpone in item.postpones"-->
            <!--              :key="postpone.id"-->
            <!--              :data-type="t('client.billing.postponed')"-->
            <!--            >-->
            <!--              {{ n(new Decimal(postpone.sum ?? 0).toNumber(), 'currency') }}-->
            <!--            </div>-->
          </AppTableTd>
        </AppTableTr>
        <AppTableTr>
          <AppTableTd style="text-align: right" nowrap>
            <h3 class="mb-0">{{ t('client.billing.attributes.total') }}:</h3>
          </AppTableTd>
          <AppTableTd
            :key="item.date"
            v-for="item in invoices"
            style="text-align: center"
            nowrap
            :class="isCurrentMonthAndYear(item.date) ? 'current-month-blink' : ''"
          >
            <strong class="total-item">
              {{ n(new Decimal(item.total_sum ?? 0).toNumber(), 'currency') }}
            </strong>
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh colspan="14" nowrap>
            <h2 class="text-neutral-900 mb-0" v-text="t('client.billing.project_overview')" />
          </AppTableTh>
        </AppTableTr>
        <AppTableTr>
          <AppTableTh style="vertical-align: middle; width: 11.6%" nowrap>
            <div class="d-flex align-items-center justify-content-between flex-nowrap">
              <div>
                <AppButton size="small" @click.prevent="getBilling(-3)" light circle v-tooltip.top="'-3'">
                  <FontIcon name="chevrons-left" />
                </AppButton>
                <AppButton class="ml-1" size="small" @click.prevent="getBilling(-1)" light circle v-tooltip.top="'-1'">
                  <FontIcon name="chevron-left" />
                </AppButton>
              </div>
              <span class="px-2">{{ t('common.months') }}</span>
              <div>
                <AppButton size="small" @click.prevent="getBilling(1)" light circle v-tooltip.top="'+1'">
                  <FontIcon name="chevron-right" />
                </AppButton>
                <AppButton class="ml-1" size="small" @click.prevent="getBilling(3)" light circle v-tooltip.top="'+3'">
                  <FontIcon name="chevrons-right" />
                </AppButton>
              </div>
            </div>
          </AppTableTh>
          <AppTableTh
            style="text-align: center; vertical-align: middle; width: calc(88.4% / 13)"
            :class="isCurrentMonthAndYear(item.date) ? 'current-month-accent' : ''"
            :key="item.date"
            v-for="item in invoices"
            nowrap
          >
            {{ d(DateTime.fromISO(item.date.toString()).toJSDate(), 'monthyear') }}
          </AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody v-if="projects.length === 0">
        <AppTableTr>
          <AppTableTd colspan="15">
            <AppAlert>{{ t('project.empty') }}</AppAlert>
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
      <AppTableBody :key="project.id" v-for="project in projects">
        <AppTableTr style="border-top: 20px solid var(--color-neutral-100-hex)">
          <AppTableTd colspan="14" style="vertical-align: top" nowrap>
            <RouterLink
              target="_blank"
              :to="{ name: 'projects.edit', params: { uuid: props.uuid, id: project.id } }"
              class="pointer"
            >
              <h3 class="mb-0">{{ project.name }}</h3>
            </RouterLink>
          </AppTableTd>
        </AppTableTr>
        <!-- Invoiced -->
        <AppTableTr>
          <AppTableTd class="font-bold" nowrap>{{ t('client.billing.invoiced') }}:</AppTableTd>
          <AppTableTd
            :class="isCurrentMonthAndYear(invoicedItem.date) ? 'current-month-blink' : ''"
            :key="invoicedItem.date"
            v-for="invoicedItem in project.invoiced"
            nowrap
          >
            <span
              class="total-item"
              v-if="invoicedItem.sum"
              v-text="n(new Decimal(invoicedItem.sum).toNumber(), 'currency')"
            />
          </AppTableTd>
        </AppTableTr>
        <!-- Postpone -->
        <AppTableTr>
          <AppTableTd class="font-bold" nowrap>{{ t('client.billing.postponed') }}:</AppTableTd>
          <AppTableTd
            :class="isCurrentMonthAndYear(postponedItem.date) ? 'current-month-blink' : ''"
            :key="postponedItem.date"
            v-for="postponedItem in project.postponed"
            nowrap
          >
            <span
              class="total-item"
              v-if="postponedItem.sum"
              v-text="n(new Decimal(postponedItem.sum).toNumber(), 'currency')"
            />
          </AppTableTd>
        </AppTableTr>
        <!-- Write off -->
        <AppTableTr>
          <AppTableTd class="font-bold" nowrap>{{ t('client.billing.written_down') }}:</AppTableTd>
          <AppTableTd
            :class="isCurrentMonthAndYear(writtenDownItem.date) ? 'current-month-blink' : ''"
            :key="writtenDownItem.date"
            v-for="writtenDownItem in project.written_down"
            nowrap
          >
            <span
              class="total-item"
              v-if="writtenDownItem.sum"
              v-text="n(new Decimal(writtenDownItem.sum).toNumber(), 'currency')"
            />
          </AppTableTd>
        </AppTableTr>
        <!-- Original -->
        <AppTableTr>
          <AppTableTd class="font-bold" nowrap>Original:</AppTableTd>
          <AppTableTd
            :class="isCurrentMonthAndYear(originalItem.date) ? 'current-month-blink' : ''"
            :key="originalItem.date"
            v-for="originalItem in project.original"
            nowrap
          >
            <span
              class="total-item"
              v-if="originalItem.sum"
              v-text="n(new Decimal(originalItem.sum).toNumber(), 'currency')"
            />
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
      <!-- Total -->
      <AppTableBody>
        <AppTableTr style="border-top: 20px solid var(--color-neutral-100-hex)">
          <AppTableTd colspan="14" style="vertical-align: top" nowrap>
            <h3 class="mb-0">{{ t('client.billing.attributes.total') }}:</h3>
          </AppTableTd>
        </AppTableTr>
        <!-- Invoiced -->
        <AppTableTr
          v-if="
            total.invoiced.length === 0 &&
            total.postponed.length === 0 &&
            total.written_down.length === 0 &&
            total.original.length === 0
          "
        >
          <AppTableTd colspan="14">
            <AppAlert>{{ t('common.empty') }}</AppAlert>
          </AppTableTd>
        </AppTableTr>
        <!-- Invoiced -->
        <AppTableTr v-if="total.invoiced.length > 0">
          <AppTableTd class="font-bold" nowrap>{{ t('client.billing.invoiced') }}:</AppTableTd>
          <AppTableTd
            :class="isCurrentMonthAndYear(invoicedItem.date) ? 'current-month-blink' : ''"
            :key="invoicedItem.date"
            v-for="invoicedItem in total.invoiced"
            nowrap
          >
            <span
              class="total-item"
              v-if="invoicedItem.sum"
              v-text="n(new Decimal(invoicedItem.sum).toNumber(), 'currency')"
            />
          </AppTableTd>
        </AppTableTr>
        <!-- Postpone -->
        <AppTableTr v-if="total.postponed.length > 0">
          <AppTableTd class="font-bold" nowrap>{{ t('client.billing.postponed') }}:</AppTableTd>
          <AppTableTd
            :class="isCurrentMonthAndYear(postponedItem.date) ? 'current-month-blink' : ''"
            :key="postponedItem.date"
            v-for="postponedItem in total.postponed"
            nowrap
          >
            <span
              class="total-item"
              v-if="postponedItem.sum"
              v-text="n(new Decimal(postponedItem.sum).toNumber(), 'currency')"
            />
          </AppTableTd>
        </AppTableTr>
        <!-- Write off -->
        <AppTableTr v-if="total.written_down.length > 0">
          <AppTableTd class="font-bold" nowrap>{{ t('client.billing.written_down') }}:</AppTableTd>
          <AppTableTd
            :class="isCurrentMonthAndYear(writtenDownItem.date) ? 'current-month-blink' : ''"
            :key="writtenDownItem.date"
            v-for="writtenDownItem in total.written_down"
            nowrap
          >
            <span
              class="total-item"
              v-if="writtenDownItem.sum"
              v-text="n(new Decimal(writtenDownItem.sum).toNumber(), 'currency')"
            />
          </AppTableTd>
        </AppTableTr>
        <!-- Original -->
        <AppTableTr v-if="total.original.length > 0">
          <AppTableTd class="font-bold" nowrap>Original:</AppTableTd>
          <AppTableTd
            :class="isCurrentMonthAndYear(originalItem.date) ? 'current-month-blink' : ''"
            :key="originalItem.date"
            v-for="originalItem in total.original"
            nowrap
          >
            <span
              class="total-item"
              v-if="originalItem.sum"
              v-text="n(new Decimal(originalItem.sum).toNumber(), 'currency')"
            />
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
  </template>
</template>

<style lang="scss" scoped>
.current-month-accent {
  background-color: var(--color-success-500-hex);
  color: var(--color-success-100-hex);
}

.current-month-blink {
  background-color: var(--color-neutral-50-hex);
}

.total-item {
  font-weight: 600;
  font-size: 0.75rem;
}

.table {
  td,
  th {
    border: 1px solid var(--color-neutral-200-hex);
  }
}
</style>
