import { ref } from 'vue';
import api from '@/services/api';
import { IClientCategory, IClientPreviewResource } from '@/types/Client';
import { debounce } from 'perfect-debounce';

export default function useClients() {
  const clientCategories = ref<IClientCategory[]>([]);

  async function getClientCategories() {
    try {
      const response = await api.clients.categories.list();
      clientCategories.value = response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const clients = ref<IClientPreviewResource[]>([]);

  async function fetchClients(opts?: { search?: string; withoutPagination?: boolean }): Promise<void> {
    try {
      const searchParams = new URLSearchParams();
      if (opts?.search) {
        searchParams.append('search', opts.search);
      }
      if (opts?.withoutPagination) {
        searchParams.append('without_pagination', '1');
      }
      const response = await api.clients.list({ searchParams });
      clients.value = response.data;
    } catch (error) {
      console.error(error);
    }
  }

  const onSearchClients = debounce(async (search: string, loading: (isLoading: boolean) => void) => {
    if (search.length <= 1) return;
    loading(true);
    await fetchClients({ search, withoutPagination: true });
    loading(false);
  }, 300);

  return { clientCategories, getClientCategories, clients, fetchClients, onSearchClients };
}
