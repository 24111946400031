export default {
  attributes: {
    start_date: 'Startdatum',
    end_date: 'Slutdatum',
  },
  modal: {
    create: {
      title: 'Skapa nytt räkenskapsår',
    },
  },
};
