<script setup lang="ts">
import { computed, onMounted, ref, provide, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';

import toast from '@/services/toast';
import { AppLoader, HelpInformation } from '@/components';
import { IClientFullResource, ClientTabs } from '@/types/Client';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import Overview from '@/components/ClientSection/Overview.vue';
import Settings from '@/components/ClientSection/Settings.vue';
import Planning from '@/components/ClientSection/Planning.vue';
import Project from '@/components/ClientSection/Project.vue';
import Invoicing from '@/components/ClientSection/Invoicing.vue';
import ReportedTime from '@/components/ClientSection/ReportedTime.vue';
import Processes from '@/components/ClientSection/Processes/Processes.vue';
import Expenses from '@/components/ClientSection/Expenses/Expenses.vue';

const props = defineProps<{
  uuid: string;
}>();

const client = ref<null | IClientFullResource>(null);

const weFilerToken = ref('');
const weFilerApiBaseUrl = ref('');

const loader = useLoader();
const weFilerSettingsLoader = useLoader({ useProgress: false });
const { t, d, locale } = useI18n({ useScope: 'global' });

const activeTab: Ref<ClientTabs> = useRouteQuery('tab', ClientTabs.Overview);

const processesComponent = ref<InstanceType<typeof Processes>>();

const tabs = computed(() => [
  { name: ClientTabs.Overview, title: t('client.tabs.dashboard'), icon: 'gauge' },
  { name: ClientTabs.Files, title: t('client.tabs.customer_folders'), icon: 'files' },
  { name: ClientTabs.Planning, title: t('client.tabs.planning'), icon: 'calendar-event' },
  { name: ClientTabs.Billing, title: t('client.tabs.billing'), icon: 'receipt' },
  { name: ClientTabs.Projects, title: t('client.tabs.project'), icon: 'briefcase' },
  { name: ClientTabs.ReportedTime, title: t('client.tabs.reported_time'), icon: 'report' },
  { name: ClientTabs.Purchases, title: t('client.tabs.purchases'), icon: 'list' },
  { name: ClientTabs.Processes, title: t('client.tabs.processes'), icon: 'list' },
  { name: ClientTabs.Settings, title: t('client.tabs.settings'), icon: 'settings' },
]);

async function getClient() {
  try {
    const response = await api.clients.get(props.uuid);
    client.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getWeFilerSettings() {
  try {
    weFilerSettingsLoader.start();
    const response = await api.weFile.settings.get();
    weFilerToken.value = response.token;
    weFilerApiBaseUrl.value = response.api_base_url;
  } catch (error) {
    console.error(error);
  } finally {
    weFilerSettingsLoader.finish();
  }
}

function setTab(tab: ClientTabs) {
  if (activeTab.value === ClientTabs.Processes && processesComponent.value) {
    processesComponent.value.tryToChangeTab(() => {
      activeTab.value = tab;
    });
    return;
  }
  activeTab.value = tab;
}

onMounted(async () => {
  loader.start();
  await Promise.all([getWeFilerSettings(), getClient()]);
  loader.finish();
});

provide('clientUuid', props.uuid);
</script>
<template>
  <div class="container-fluid px-md-5">
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <div v-else class="mb-4">
      <template v-if="client">
        <div class="d-flex align-items-end mb-2">
          <h1 class="mb-0" v-text="client.name" />
          <HelpInformation class="ml-1" translation="client.edit.help" />
        </div>
        <p>{{ t('client.attributes.responsible_user') }}: <strong v-text="client.responsible_user.name" /></p>
        <p>
          {{ client.org_number }}
          <template v-if="client?.current_financial_year.start_date && client?.current_financial_year.end_date">
            | {{ d(client?.current_financial_year.start_date) }} -
            {{ d(client?.current_financial_year.end_date) }}
          </template>
        </p>
      </template>
    </div>
    <ul class="tabs mb-3">
      <li
        :class="{ active: tab.name === activeTab, disabled: loader.isLoading.value }"
        v-for="tab in tabs"
        :key="tab.name"
        class="tab-item"
      >
        <a class="tab-link" @click.prevent="setTab(tab.name)" href="#">
          <i class="ti" :class="`ti-${tab.icon}`" />
          {{ tab.title }}
        </a>
      </li>
    </ul>
    <Overview v-if="activeTab === ClientTabs.Overview" />
    <Planning v-else-if="activeTab === ClientTabs.Planning" :uuid="props.uuid" />
    <Invoicing v-else-if="activeTab === ClientTabs.Billing" :uuid="props.uuid" />
    <Project v-else-if="activeTab === ClientTabs.Projects" :uuid="props.uuid" />
    <ReportedTime v-else-if="activeTab === ClientTabs.ReportedTime" :uuid="props.uuid" />
    <Settings v-else-if="activeTab === ClientTabs.Settings" :uuid="props.uuid" />
    <Expenses v-else-if="activeTab === ClientTabs.Purchases" :uuid="props.uuid" />
    <Processes ref="processesComponent" v-else-if="activeTab === ClientTabs.Processes" :uuid="props.uuid" />
    <div :class="{ 'lazy-hidden': activeTab !== ClientTabs.Files }">
      <div v-if="weFilerSettingsLoader.isLoading.value" class="text-center">
        <AppLoader size="large" />
      </div>
      <template v-if="!weFilerSettingsLoader.isLoading.value && weFilerToken && weFilerApiBaseUrl">
        <div class="box">
          <div class="box-body">
            <WeTemplates
              :api-base-url="weFilerApiBaseUrl"
              :token="weFilerToken"
              :company-uuid="props.uuid"
              :locale="locale"
              @notify-success="toast.success($event)"
              @notify-failure="toast.error($event)"
            />
          </div>
        </div>
        <div class="box mt-3">
          <div class="box-body">
            <WeFiler
              :api-base-url="weFilerApiBaseUrl"
              :token="weFilerToken"
              :company-uuid="props.uuid"
              :locale="locale"
              @notify-success="toast.success($event)"
              @notify-failure="toast.error($event)"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.lazy-hidden {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
</style>
