<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import useLoader from '@/composables/useLoader';
import {
  AppAlert,
  AppButton,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTh,
  AppTableTr,
  AppTableTd,
  FontIcon,
  AppLoader,
  ConfirmModal,
  LinkServiceModal,
  ServiceNoteModal,
  HelpInformation,
} from '@/components';
import { useModal } from 'vue-final-modal';
import api from '@/services/api';
import {
  ICrmPlannerServiceMapListResource,
  ICrmPlannerServiceMapRequestBody,
  ICrmServiceListResource,
} from '@/types/Crm';
import { IServicePreviewResource } from '@/types/Service';

const { t } = useI18n({ useScope: 'global' });

const loader = useLoader();

const mappedServicesLoader = useLoader({ useProgress: false });
const mappedServices = ref<ICrmPlannerServiceMapListResource[]>([]);

const crmServicesLoader = useLoader({ useProgress: false });
const crmServices = ref<ICrmServiceListResource[]>([]);

const servicesLoader = useLoader({ useProgress: false });
const services = ref<IServicePreviewResource[]>([]);

const deleteModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('service-mapping.confirm.destroy.title'),
    message: t('service-mapping.confirm.destroy.text'),
    async onConfirm() {
      try {
        deleteModal.patchOptions({ attrs: { loading: true } });
        const { uuid } = deleteModal.options.attrs!.params;
        await api.crm.mappedServices.destroy(uuid);
        await deleteModal.close();
        await getData();
      } catch (error) {
        console.error(error);
      } finally {
        deleteModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      deleteModal.close();
    },
    onClosed() {
      deleteModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

const linkServiceModal = useModal({
  component: LinkServiceModal,
  attrs: {
    async onCreate(form: ICrmPlannerServiceMapRequestBody) {
      try {
        linkServiceModal.patchOptions({ attrs: { loading: true } });
        await api.crm.mappedServices.store(form);
        await linkServiceModal.close();
        await getData();
      } catch (error) {
        console.error(error);
      } finally {
        linkServiceModal.patchOptions({ attrs: { loading: false } });
      }
    },
    async onUpdate(uuid: string, form: ICrmPlannerServiceMapRequestBody) {
      try {
        linkServiceModal.patchOptions({ attrs: { loading: true } });
        await api.crm.mappedServices.update(uuid, form);
        await linkServiceModal.close();
        await getData();
      } catch (error) {
        console.error(error);
      } finally {
        linkServiceModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      linkServiceModal.close();
    },
    onClosed() {
      linkServiceModal.patchOptions({ attrs: { loading: false, uuid: null, body: null } });
    },
  },
});

const serviceNoteModal = useModal({
  component: ServiceNoteModal,
  attrs: {
    async onCrmServiceUpdate(uuid: string, notes: string) {
      try {
        serviceNoteModal.patchOptions({ attrs: { loading: true } });
        await api.crm.services.update(uuid, { notes });
        await serviceNoteModal.close();
        await getCrmServices();
      } catch (error) {
        console.error(error);
      } finally {
        serviceNoteModal.patchOptions({ attrs: { loading: false } });
      }
    },
    async onPlannerServiceUpdate(uuid: string, notes: string) {
      try {
        serviceNoteModal.patchOptions({ attrs: { loading: true } });
        await api.services.update(uuid, { notes });
        await serviceNoteModal.close();
        await getServices();
      } catch (error) {
        console.error(error);
      } finally {
        serviceNoteModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      serviceNoteModal.close();
    },
    onClosed() {
      serviceNoteModal.patchOptions({ attrs: { loading: false, uuid: '', note: '' } });
    },
  },
});

function onDeleteMappedService(uuid: string) {
  deleteModal.patchOptions({ attrs: { params: { uuid } } });
  deleteModal.open();
}

function onEditMappedService(mappedService: ICrmPlannerServiceMapListResource) {
  linkServiceModal.patchOptions({
    attrs: {
      uuid: mappedService.uuid,
      body: {
        planner_service: mappedService.service.uuid,
        crm_services: mappedService.crm_services.map(({ uuid }) => uuid),
        internal_note: mappedService.note,
      },
    },
  });
  linkServiceModal.open();
}

function onEditPlannerService(plannerService: IServicePreviewResource) {
  serviceNoteModal.patchOptions({
    attrs: { type: 'planner', uuid: plannerService.uuid, note: plannerService.note },
  });
  serviceNoteModal.open();
}

function onEditCrmService(crmService: ICrmServiceListResource) {
  serviceNoteModal.patchOptions({ attrs: { type: 'crm', uuid: crmService.uuid, note: crmService.note } });
  serviceNoteModal.open();
}

async function getMappedServices() {
  try {
    mappedServicesLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.crm.mappedServices.list(searchParams);
    mappedServices.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    mappedServicesLoader.finish();
  }
}

async function getServices() {
  try {
    servicesLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('without_mapping', '1');
    const response = await api.services.list({ searchParams });
    services.value = response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    console.error(error);
  } finally {
    servicesLoader.finish();
  }
}

async function getCrmServices() {
  try {
    crmServicesLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('without_mapping', '1');
    const response = await api.crm.services.list(searchParams);
    crmServices.value = response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    console.error(error);
  } finally {
    crmServicesLoader.finish();
  }
}

async function getData() {
  loader.start();
  await Promise.all([getMappedServices(), getServices(), getCrmServices()]);
  loader.finish();
}

onMounted(getData);
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-end justify-content-between mb-2">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="t('service-mapping.index.mapped_services_title')" />
        <HelpInformation class="ml-1" translation="service-mapping.index.mapped_services_help" />
      </div>
      <AppButton
        @click="linkServiceModal.open"
        class="ml-auto"
        color="secondary"
        :disabled="mappedServicesLoader.isLoading.value"
      >
        {{ t('service-mapping.index.link_services') }}
      </AppButton>
    </div>
    <div v-if="mappedServicesLoader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="mappedServices.length === 0">{{ t('common.empty') }}</AppAlert>
      <AppTable v-else hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap style="width: 30%">
              {{ t('service-mapping.index.table.services_in_planner') }}
            </AppTableTh>
            <AppTableTh nowrap>{{ t('service-mapping.index.table.services_in_crm') }}</AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="mappedService in mappedServices" :key="mappedService.uuid">
            <AppTableTd nowrap>{{ mappedService.service.name }}</AppTableTd>
            <AppTableTd>
              {{ mappedService.crm_services.map((service) => service.name).join(', ') }}
            </AppTableTd>
            <AppTableTd nowrap class="text-right">
              <AppButton
                @click="onEditMappedService(mappedService)"
                v-tooltip="t('common.edit')"
                size="small"
                light
                circle
              >
                <FontIcon name="pencil" />
              </AppButton>
              <AppButton
                @click="onDeleteMappedService(mappedService.uuid)"
                class="ml-2"
                v-tooltip="t('common.delete')"
                size="small"
                light
                circle
                color="danger"
              >
                <FontIcon name="trash" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
    </template>

    <div class="d-flex align-items-end mt-4 mb-2">
      <h1 class="mb-0" v-text="t('service-mapping.index.not_mapped_services_title')" />
      <HelpInformation class="ml-1" translation="service-mapping.index.not_mapped_services_help" />
    </div>
    <div v-if="servicesLoader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <AppTable v-else hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh nowrap style="width: 30%">
            {{ t('service-mapping.index.table.services_in_planner') }}
          </AppTableTh>
          <AppTableTh nowrap>{{ t('service-mapping.index.table.comment') }}</AppTableTh>
          <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr v-for="service in services" :key="service.uuid">
          <AppTableTd nowrap>{{ service.name }}</AppTableTd>
          <AppTableTd>
            <span v-if="service.note" v-text="service.note" />
            <i v-else class="text-neutral-300" v-text="t('common.empty')" />
          </AppTableTd>
          <AppTableTd nowrap class="text-right">
            <AppButton
              v-tooltip="t('common.edit')"
              @click="onEditPlannerService(service)"
              @click.stop=""
              size="small"
              light
              circle
            >
              <FontIcon name="pencil" />
            </AppButton>
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
    <div v-if="crmServicesLoader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <AppTable v-else class="mt-3" hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh nowrap style="width: 30%">
            {{ t('service-mapping.index.table.services_in_crm') }}
          </AppTableTh>
          <AppTableTh nowrap>{{ t('service-mapping.index.table.comment') }}</AppTableTh>
          <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr v-for="crmService in crmServices" :key="crmService.uuid">
          <AppTableTd nowrap>{{ crmService.name }}</AppTableTd>
          <AppTableTd>
            <span v-if="crmService.note" v-text="crmService.note" />
            <i v-else class="text-neutral-300" v-text="t('common.empty')" />
          </AppTableTd>
          <AppTableTd nowrap class="text-right">
            <AppButton v-tooltip="t('common.edit')" @click="onEditCrmService(crmService)" size="small" light circle>
              <FontIcon name="pencil" />
            </AppButton>
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
  </div>
</template>
