<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import VueSelect from 'vue-select';
import { useModal } from 'vue-final-modal';
import { nanoid } from 'nanoid';
import draggable from 'vuedraggable';

import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppTable,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  FontIcon,
  FormLabel,
  FormInput,
  ConfirmModal,
  ServiceTaskModal,
  HelpInformation,
  PriceMatrixModal,
} from '@/components';

import useTime from '@/composables/useTime';
import useServices from '@/composables/useServices';

import {
  ServiceCheckpointFrequency,
  IServiceTask,
  ServiceTaskForm,
  IServiceTaskRequestBody,
  IServicePriceAdjustmentListResource,
} from '@/types/Service';
import { BillingFrequency, ProjectPriceType } from '@/types/Common';
import { ISalaryCodeResource } from '@/types/Salary';

const props = defineProps<{ uuid?: string }>();

const name = ref('');
const defaultPriceType = ref<null | ProjectPriceType>(null);
const checkpointFrequency = ref<null | ServiceCheckpointFrequency>(null);
const defaultInvoiceFrequency = ref<null | BillingFrequency>(null);
const defaultFixedPrice = ref<number>(0);
const defaultDeadline = ref<null | string>(null);
const defaultHourlyPriceAdjustment = ref<number>(0);
const defaultHourlyPriceAdjustmentList = ref<IServicePriceAdjustmentListResource[]>([]);
const notes = ref('');
const tasks = ref<IServiceTask[]>([]);

const loader = useLoader();
const submitLoader = useLoader();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();
const { convertMinutesToTime } = useTime();
const { invoiceFrequencyOptions, priceOptions, checkpointFrequencyOptions, priceDeadlineOptions } = useServices();

const editMode = computed(() => !!props.uuid);

const title = computed(() =>
  editMode.value ? t('service.edit.title', { name: name.value }) : t('service.create.title'),
);

async function getService() {
  try {
    const response = await api.services.get(props.uuid!);
    name.value = response.data.name;
    checkpointFrequency.value = response.data.checkpoint_frequency;
    defaultPriceType.value = response.data.default_price_type;
    defaultInvoiceFrequency.value = response.data.default_invoice_frequency;
    defaultFixedPrice.value = response.data.default_fixed_price;
    defaultHourlyPriceAdjustment.value = parseFloat(response.data.default_hourly_price_adjustment);
    defaultDeadline.value = response.data.default_dead_line;
    tasks.value = response.data.tasks.map((task) => ({
      ...task,
      uid: nanoid(),
      salary_code_id: task.salary_code?.id ?? null,
    }));
    defaultHourlyPriceAdjustmentList.value = response.data.default_hourly_price_adjustment_list;
  } catch (error) {
    console.error(error);
  }
}

function taskToRequest(task: IServiceTask): IServiceTaskRequestBody {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { uid: _, ...rest } = task;
  return rest;
}

const salaryCodes = ref<ISalaryCodeResource[]>([]);
const salaryCodesLoading = ref(false);

async function getSalaryCodes() {
  try {
    salaryCodesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.salary.codes.index({ searchParams });
    salaryCodes.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    salaryCodesLoading.value = false;
  }
}

async function submit() {
  try {
    submitLoader.start();
    if (editMode.value) {
      await api.services.update(props.uuid!, {
        name: name.value,
        checkpoint_frequency: checkpointFrequency.value,
        default_price_type: defaultPriceType.value,
        default_fixed_price: defaultFixedPrice.value,
        notes: notes.value,
        default_dead_line: defaultDeadline.value,
        default_invoice_frequency: defaultInvoiceFrequency.value,
        tasks: tasks.value.map(taskToRequest),
      });
    } else {
      const response = await api.services.store({
        name: name.value,
        checkpoint_frequency: checkpointFrequency.value,
        default_price_type: defaultPriceType.value,
        default_fixed_price: defaultFixedPrice.value,
        default_hourly_price_adjustment: defaultHourlyPriceAdjustment.value,
        default_dead_line: defaultDeadline.value,
        default_invoice_frequency: defaultInvoiceFrequency.value,
        tasks: tasks.value.map(taskToRequest),
      });
      await router.push({ name: 'services.edit', params: { uuid: response.data.uuid } });
    }
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

function onDeleteTask(task: IServiceTask) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('task.confirm.destroy.title'),
      message: t('task.confirm.destroy.text', {
        name: task.name,
      }),
      onConfirm() {
        tasks.value = tasks.value.filter(({ uid }) => task.uid !== uid);
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onCreateTask() {
  const { open, close, destroy } = useModal({
    component: ServiceTaskModal,
    attrs: {
      task: null,
      salaryCodes: salaryCodes.value,
      onCreated(taskToAdd: ServiceTaskForm) {
        tasks.value.push({
          ...taskToAdd,
          uid: nanoid(),
          order: Math.max(...tasks.value.map((task) => task.order)) + 1,
        });
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onEditTask(task: IServiceTask) {
  const { open, close, destroy } = useModal({
    component: ServiceTaskModal,
    attrs: {
      task,
      salaryCodes: salaryCodes.value,
      onUpdated(taskToSave: ServiceTaskForm) {
        const { uid } = task;
        tasks.value = tasks.value.map((task) => {
          if (task.uid === uid) {
            return { ...task, ...taskToSave };
          }
          return task;
        });
        close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onSort() {
  tasks.value = tasks.value.map((task, index) => ({ ...task, order: index + 1 }));
}

const schedulePriceModal = useModal({
  component: PriceMatrixModal,
  attrs: {
    onUpdate() {
      getService();
    },
    onCancel() {
      schedulePriceModal.close();
    },
    // @ts-ignore
    onClosed() {
      // @ts-ignore
      schedulePriceModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

async function updatePriceAdjustment(event: PointerEvent) {
  if (!props.uuid) return;
  if (event.target) {
    const target = event.target as HTMLElement;
    const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
    button.classList.add('is-loading');

    try {
      schedulePriceModal.patchOptions({
        // @ts-ignore
        attrs: {
          title: t('price-matrix.modal.title'),
          prices: defaultHourlyPriceAdjustmentList.value,
          adjustment: true,
          userUuid: null,
          serviceUuid: props.uuid,
        },
      });
      await schedulePriceModal.open();
    } catch (error) {
      console.error(error);
    } finally {
      button.classList.remove('is-loading');
    }
  }
}

onMounted(async () => {
  loader.start();
  await getSalaryCodes();
  if (props.uuid) {
    await getService();
  }
  loader.finish();
});
</script>
<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <form v-else @submit.prevent="submit" class="container-wide">
    <div class="d-flex align-items-center mb-4">
      <AppButton @click.prevent="router.back" color="secondary" circle light v-tooltip="t('common.back')">
        <FontIcon name="arrow-back-up" />
      </AppButton>
      <div class="d-flex align-items-end ml-3">
        <h1 class="mb-0" v-text="title" />
        <HelpInformation class="ml-1" :translation="editMode ? 'service.edit.help' : 'service.create.help'" />
      </div>
    </div>
    <AppBox shadow>
      <AppBoxBody>
        <div class="row">
          <h2 class="col-12" v-text="t('service.form.general_info')" />
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="name" required>{{ t('service.attributes.name') }}</FormLabel>
            <FormInput id="name" v-model="name" required />
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="checkpoint_frequency" required>
              {{ t('service.attributes.checkpoint_frequency') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              v-model="checkpointFrequency"
              :reduce="(option: any) => option.value"
              label="label"
              :options="checkpointFrequencyOptions"
              input-id="checkpoint_frequency"
              :placeholder="t('common.select')"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!checkpointFrequencyOptions" v-bind="attributes" v-on="events" />
              </template>
            </VueSelect>
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="default_price_type" required>
              {{ t('service.attributes.default_price_type') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              v-model="defaultPriceType"
              :reduce="(option:any) => option.value"
              label="label"
              :options="priceOptions"
              input-id="default_price_type"
              :placeholder="t('common.select')"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!defaultPriceType" v-bind="attributes" v-on="events" />
              </template>
            </VueSelect>
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="default_invoice_frequency" required>
              {{ t('service.attributes.default_invoice_frequency') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              v-model="defaultInvoiceFrequency"
              :reduce="(option: any) => option.value"
              label="label"
              :options="invoiceFrequencyOptions"
              input-id="default_invoice_frequency"
              :placeholder="t('common.select')"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!defaultInvoiceFrequency" v-bind="attributes" v-on="events" />
              </template>
            </VueSelect>
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="default_fixed_price" required>
              {{ t('service.attributes.default_fixed_price') }}
            </FormLabel>
            <FormInput
              min="1"
              step="1"
              type="number"
              id="default_fixed_price"
              v-model.number="defaultFixedPrice"
              required
            />
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="default_hourly_price_adjustment" required>
              {{ t('service.attributes.default_hourly_price_adjustment') }}
            </FormLabel>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1">
                <FormInput
                  min="-300"
                  step="1"
                  type="number"
                  id="default_hourly_price_adjustment"
                  v-model.number="defaultHourlyPriceAdjustment"
                  required
                  :disabled="editMode"
                />
              </div>
              <AppButton
                class="flex-shrink-0 ml-1"
                v-if="editMode"
                @click.prevent="updatePriceAdjustment($event)"
                v-tooltip="t('common.update')"
                light
                circle
              >
                <FontIcon name="pencil" />
              </AppButton>
            </div>
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <FormLabel html-for="default_dead_line" required>
              {{ t('service.attributes.default_dead_line') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              v-model="defaultDeadline"
              :reduce="(option: any) => option.value"
              label="label"
              :options="priceDeadlineOptions"
              input-id="default_dead_line"
              :placeholder="t('common.select')"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!defaultDeadline" v-bind="attributes" v-on="events" />
              </template>
            </VueSelect>
          </div>
        </div>
      </AppBoxBody>
      <AppBoxBody>
        <h2 v-text="t('service.tasks.title')" />
        <AppAlert class="mt-3" v-if="tasks.length === 0">{{ t('service.tasks.empty') }}</AppAlert>
      </AppBoxBody>
      <AppBoxBody>
        <AppTable v-if="tasks.length > 0" hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('service.tasks.attributes.name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('service.tasks.attributes.default_role') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('service.tasks.attributes.default_time_budget') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('service.tasks.attributes.default_frequency') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('service.tasks.attributes.requires_visit') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('service.tasks.attributes.auto_include_in_planning') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <draggable v-model="tasks" item-key="uid" tag="tbody" ghost-class="draggable-ghost" @change="onSort">
            <template #item="{ element: task }">
              <AppTableTr class="grab">
                <AppTableTd>
                  <div class="d-flex align-items-center">
                    <i class="ti ti-grip-vertical text-3 text-neutral-400" />
                    <strong class="pl-2" v-text="task.name" />
                  </div>
                </AppTableTd>
                <AppTableTd nowrap>{{ t(`task.role.${task.default_role}`) }}</AppTableTd>
                <AppTableTd nowrap>{{ convertMinutesToTime(task.default_time_budget) }}</AppTableTd>
                <AppTableTd nowrap>{{ t(`task.frequency.${task.default_frequency || 'once'}`) }}</AppTableTd>
                <AppTableTd nowrap>
                  <i class="ti ti-check text-success-500 text-3" v-if="task.requires_visit" />
                </AppTableTd>
                <AppTableTd nowrap>
                  <i class="ti ti-check text-success-500 text-3" v-if="task.auto_include_in_planning" />
                </AppTableTd>
                <AppTableTd nowrap class="text-right">
                  <AppButton
                    v-tooltip.left="t('task.tooltip.edit', { name: task.name })"
                    @click.prevent="onEditTask(task)"
                    size="small"
                    light
                    circle
                  >
                    <FontIcon name="pencil" />
                  </AppButton>
                  <AppButton
                    v-if="!task.is_in_use"
                    v-tooltip.left="t('task.tooltip.destroy', { name: task.name })"
                    @click.prevent="onDeleteTask(task)"
                    class="ml-2"
                    color="danger"
                    size="small"
                    light
                    circle
                  >
                    <FontIcon name="trash" />
                  </AppButton>
                </AppTableTd>
              </AppTableTr>
            </template>
          </draggable>
        </AppTable>
        <AppButton @click.prevent="onCreateTask" color="success" circle v-tooltip.right="t('service.tasks.add')" light>
          <FontIcon name="plus" />
        </AppButton>
      </AppBoxBody>
    </AppBox>
    <div class="text-right mt-4">
      <AppButton :color="editMode ? 'success' : 'secondary'" :loading="submitLoader.isLoading.value">
        {{ editMode ? t('common.update') : t('common.create') }}
      </AppButton>
    </div>
  </form>
</template>
