export default {
  index: {
    title: 'Kontakter',
    help: '',
    export_contacts: 'Exportera alla',
  },
  attributes: {
    name: 'Namn',
    company_links: 'Koppling till företag',
    service_company_link: 'Koppling till samarbetspartners',
    service_company_type: 'Samarbetspartner-typ',
    service_company_title: 'Titel hos samarbetspartner',
    comment: 'Kommentar',
  },
  filters: {
    search: 'Sök',
  },
  tooltip: {
    crm: 'Öpnna "{name}" i CRM',
    destroy: 'Radera kontakt "{name}"',
  },
  empty: 'Inga kontakter',
  // Confirms
  confirm: {
    destroy: {
      title: 'Ta bort kontakt',
      text: 'Är du säker på att att du vill ta bort <strong>{name}</strong>?',
    },
  },
  role: {
    create: {
      title: 'Skapa ny kontaktroll',
    },
    attributes: {
      name_sv: 'Namn (Swedish)',
      name_en: 'Namn (English)',
    },
    tooltip: {
      create: 'Skapa ny roll',
    },
  },
};
