<script setup lang="ts">
/* eslint-disable */
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';

import api from '@/services/api';
import {
  AppAlert,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  FormInput,
  FormLabel,
  AppPagination,
  AppLoader,
  AppCollapse,
  ColumnSettings,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import VueSelect from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import { IServicePreviewResource } from '@/types/Service';
import { IUserListResource } from '@/types/User';

import useColumnSettings from '@/composables/useColumnSettings';
import { IDeadlineEventResource } from '@/types/Event';

const router = useRouter();
const { t, d, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();

const events = ref<number[]>([]);

// Pagination
const page = useRouteQuery<number>('page', 1, { transform: Number });
const perPage = ref(20);
const total = ref(0);

// Columns
const { columns, isColumnEnable, enabledColumns } = useColumnSettings('checkpoints_columns', [
  'employee',
  'project_responsible',
  'end_financial_year_date',
  'deadline',
  'client',
  'project',
]);
// Filters
const filterLoader = useLoader();
const filterEmployee = useRouteQuery<string>('employee', '');
const filterProjectResponsible = useRouteQuery<string>('project_responsible', '');
const filterFrom = useRouteQuery<string>('from', '');
const filterTo = useRouteQuery<string>('to', '');
const filterClientName = useRouteQuery<string>('client_name', '');
const filterProjectName = useRouteQuery<string>('project_name', '');
const filterCheckpointStatus = useRouteQuery<string>('checkpoint_status', '');
const filterService = useRouteQuery<string>('service', '');

async function resetFilters() {
  filterEmployee.value = '';
  filterProjectResponsible.value = '';
  filterFrom.value = '';
  filterTo.value = '';
  filterClientName.value = '';
  filterProjectName.value = '';
  filterCheckpointStatus.value = '';
  filterService.value = '';
  await onFilter();
}

async function getDeadlines() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    searchParams.append('per_page', perPage.value.toString());
    searchParams.append('sort_by', 'name');
    searchParams.append('sort_dir', 'asc');
    // Set filters
    if (filterEmployee.value) searchParams.append('employee', filterEmployee.value);
    if (filterProjectResponsible.value) searchParams.append('project_responsible', filterProjectResponsible.value);
    if (filterFrom.value) searchParams.append('from', filterFrom.value);
    if (filterTo.value) searchParams.append('to', filterTo.value);
    if (filterClientName.value) searchParams.append('client_name', filterClientName.value);
    if (filterProjectName.value) searchParams.append('project_name', filterProjectName.value);
    if (filterCheckpointStatus.value) searchParams.append('checkpoint_status', filterCheckpointStatus.value);
    if (filterService.value) searchParams.append('service', filterService.value);

    const response = await api.events.deadlines.index({ searchParams });
    events.value = [];
    if (response.meta) {
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  }
}

async function onFilter() {
  filterLoader.start();
  page.value = 1;
  await getDeadlines();
  filterLoader.finish();
}

// Services
const services = ref<IServicePreviewResource[]>([]);
const servicesLoading = ref(false);

async function getServices() {
  try {
    servicesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.services.list({ searchParams });
    services.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    servicesLoading.value = false;
  }
}

// Users
const users = ref<IUserListResource[]>([]);
const usersLoading = ref(false);

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  loader.start();
  //await Promise.all([getDeadlines(), getServices(), getUsers()]);
  loader.finish();
});

watch(page, async () => {
  loader.start();
  //await getDeadlines();
  loader.finish();
});
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="t('deadline.index.title')" />
        <HelpInformation class="ml-1" translation="deadline.index.help" />
      </div>
      <Dropdown class="ml-auto" placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`deadline.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <AppCollapse class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-2xl-4 row-cols-3xl-8 align-items-end">
          <!-- Employee -->
          <div class="form-group col">
            <FormLabel html-for="filter_employee">
              {{ t('deadline.index.filters.employee') }}
            </FormLabel>
            <VueSelect
              v-model="filterEmployee"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="filter_employee"
              :loading="usersLoading"
              disabled
            />
          </div>
          <!-- Project responsible -->
          <div class="form-group col">
            <FormLabel html-for="filter_project_responsible">
              {{ t('deadline.index.filters.project_responsible') }}
            </FormLabel>
            <VueSelect
              v-model="filterProjectResponsible"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="project_responsible"
              :loading="usersLoading"
              disabled
            />
          </div>
          <!-- From -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter_from">
              {{ t('deadline.index.filters.from') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter_from"
                input-class-name="form-control"
                v-model="filterFrom"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                disabled
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- To -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter_to">
              {{ t('deadline.index.filters.to') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter_to"
                input-class-name="form-control"
                v-model="filterTo"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                disabled
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- Client name -->
          <div class="form-group col">
            <FormLabel html-for="filter_client_name">
              {{ t('deadline.index.filters.client_name') }}
            </FormLabel>
            <FormInput type="search" v-model.trim="filterProjectName" id="filter_client_name" icon="search" disabled />
          </div>
          <!-- Project name -->
          <div class="form-group col">
            <FormLabel html-for="filter_project_name">
              {{ t('deadline.index.filters.project_name') }}
            </FormLabel>
            <FormInput type="search" v-model.trim="filterProjectName" id="filter_project_name" icon="search" disabled />
          </div>
          <!-- Checkpoint status -->
          <div class="form-group col">
            <FormLabel html-for="filter_checkpoint_status">
              {{ t('deadline.index.filters.checkpoint_status') }}
            </FormLabel>
            <VueSelect
              v-model="filterCheckpointStatus"
              :options="[]"
              label="name"
              input-id="filter_checkpoint_status"
              disabled
            />
          </div>
          <!-- Service -->
          <div class="form-group col">
            <FormLabel html-for="filter_service">
              {{ t('deadline.index.filters.service') }}
            </FormLabel>
            <VueSelect
              v-model="filterService"
              :options="services"
              :reduce="(option) => option.uuid"
              label="name"
              input-id="filter_service"
              :loading="servicesLoading"
              disabled
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="true || filterLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="true || filterLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="events.length === 0">
        {{ t('deadline.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap v-if="isColumnEnable('employee')">
                {{ t('deadline.attributes.employee') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('project_responsible')">
                {{ t('deadline.attributes.project_responsible') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('end_financial_year_date')">
                {{ t('deadline.attributes.end_financial_year_date') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('deadline')">
                {{ t('deadline.attributes.deadline') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('client')">
                {{ t('deadline.attributes.client') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('project')">
                {{ t('deadline.attributes.project') }}
              </AppTableTh>
              <AppTableTh nowrap>
                {{ t('deadline.attributes.checkpoint_status') }}
              </AppTableTh>
              <AppTableTh nowrap>
                {{ t('deadline.attributes.checkpoints') }}
              </AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="event in events" :key="event">
              <AppTableTd nowrap v-if="isColumnEnable('employee')">employee</AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('project_responsible')">project_responsible</AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('end_financial_year_date')">end_financial_year_date</AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('deadline')">deadline</AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('client')">client</AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('project')">project</AppTableTd>
              <AppTableTd nowrap>checkpoint_status</AppTableTd>
              <AppTableTd nowrap>checkpoints</AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton size="small" light circle>
                  <FontIcon name="info-circle" />
                </AppButton>
                <AppButton class="ml-2" size="small" light circle>
                  <FontIcon name="chevron-down" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="page" />
      </template>
    </template>
  </div>
</template>
