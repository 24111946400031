<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { AppBox, AppBoxBody, AppButton, FontIcon, PlanningProgressMultiBar } from '@/components';
import useTime from '@/composables/useTime';
import useDate from '@/composables/useDate';
import { IEventTimelineWeek } from '@/types/Event';

defineProps<{
  expanded: boolean;
  weekNumber: string;
  data: IEventTimelineWeek;
  isCurrent?: boolean;
  isCompleted?: boolean;
  isForwardWeekLoading: boolean;
}>();

const emit = defineEmits<{
  (e: 'toggle', weekNumber: string): void;
  (e: 'moveWeekForward', weekNumber: string): void;
}>();

const { t, d } = useI18n({ useScope: 'global' });
const { convertMinutesToTime } = useTime();
const { convertWeekNumberToDates, getFirstDateOfWeek, isWeekLessThanCurrent, isWeekGreaterThanCurrent } = useDate();
</script>

<template>
  <AppBox :shadow="isCurrent" class="timeline-week" :class="{ current: isCurrent, expanded }">
    <AppBoxBody>
      <div class="row d-flex align-items-center">
        <!-- Info -->
        <div class="col-3">
          <div @click.prevent="emit('toggle', weekNumber)" class="d-flex align-items-center pointer">
            <h4
              :class="{
                'text-success-500': isCurrent,
                'text-danger-500': !isCurrent && !isCompleted && isWeekLessThanCurrent(+weekNumber),
              }"
              class="mb-0"
            >
              {{ t('common.week_short') }}.{{ getFirstDateOfWeek(weekNumber).toFormat('W') }}
            </h4>
            <span class="ml-2 text-6 text-neutral-500">
              ({{ d(convertWeekNumberToDates(+weekNumber)[0], 'short') }}
              -
              {{ d(convertWeekNumberToDates(+weekNumber)[1], 'short') }})
            </span>
          </div>
        </div>
        <!-- Actions -->
        <div class="col-1 text-right">
          <AppButton size="small" @click.prevent="emit('toggle', weekNumber)" light circle>
            <FontIcon v-if="expanded" name="chevron-up" />
            <FontIcon v-else name="chevron-down" />
          </AppButton>
        </div>
        <!-- Time -->
        <div class="col-1 text-center">
          <strong
            :class="{
              'text-danger-500': data.time.budgeted > data.user_working_time_minutes,
            }"
            v-text="convertMinutesToTime(data.time.budgeted)"
          />
        </div>
        <div class="col-1 d-flex justify-content-end">
          <AppButton
            v-if="!isCompleted && !isWeekGreaterThanCurrent(+weekNumber)"
            v-tooltip.left="t('common.move_week_forward')"
            size="small"
            @click.prevent="emit('moveWeekForward', weekNumber)"
            light
            circle
            :loading="isForwardWeekLoading"
          >
            <FontIcon name="corner-left-down" />
          </AppButton>
        </div>
        <!-- Bar -->
        <div class="col-6">
          <PlanningProgressMultiBar
            :green="data.time.finished"
            :orange="data.time.reported"
            :gray="Math.max(data.time.remaining, 0)"
          />
        </div>
      </div>
    </AppBoxBody>
    <slot />
  </AppBox>
</template>

<style scoped lang="scss">
.timeline-week {
  &.current {
    border: 1px solid var(--color-neutral-300-hex);
  }
  &:not(.current) {
    background-color: var(--color-neutral-50-hex);
  }
  &.expanded {
    background-color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
}
</style>
