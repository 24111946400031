<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { useModal } from 'vue-final-modal';
import { useRouteQuery } from '@vueuse/router';

import {
  AppButton,
  FontIcon,
  FormLabel,
  AppLoader,
  AppTableTr,
  AppTableHead,
  AppTableBody,
  AppTableTh,
  AppTableTd,
  TimeEntryActivityModal,
  TimeEntryProjectTaskModal,
  AppCollapse,
  AppTable,
  AppAlert,
} from '@/components';

import useDate from '@/composables/useDate';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import { EventType } from '@/types/Event';
import useTime from '@/composables/useTime';
import {
  IClientPlanningPeriodMonth,
  IClientPlanningPeriodWeek,
  IClientPlanningProject,
  IClientPlanningProjectEvent,
} from '@/types/Client';
import { ProjectStatusType } from '@/types/Project';
import progress from '@/services/progress';

const props = defineProps<{
  uuid: string;
}>();

type StatusOption = { label: string; value: string };

const { convertMinutesToTime } = useTime();
const router = useRouter();
const { t } = useI18n({ useScope: 'global' });
const { getCurrentWeek, getCurrentYearAndWeek, isWeekGreaterThanCurrent } = useDate();
const loader = useLoader({ useProgress: true });

const weekOffset = useRouteQuery('week-offset', 0, { transform: Number });
const filterStatus = useRouteQuery<
  'all' | ProjectStatusType.Active | ProjectStatusType.Done | ProjectStatusType.Cancelled
>('status', ProjectStatusType.Active);

const expandedProjects = ref<number[]>([]);
const tableElement = ref<null | HTMLDivElement>(null);
const projects = ref<IClientPlanningProject[]>([]);
const periodWeeks = ref<IClientPlanningPeriodWeek[]>([]);
const periodMonths = ref<IClientPlanningPeriodMonth[]>([]);

function isProjectExpanded(id: number) {
  return expandedProjects.value.includes(id);
}

function expandProject(id: number) {
  expandedProjects.value.push(id);
}

function collapseProject(id: number) {
  expandedProjects.value = expandedProjects.value.filter((expandedId) => expandedId !== id);
}

async function getProjects(loadable = true) {
  if (loadable) loader.start();
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('week', weekOffset.value.toString());
    if (filterStatus.value === 'all') {
      for (const status of [ProjectStatusType.Active, ProjectStatusType.Done, ProjectStatusType.Cancelled]) {
        searchParams.append('statuses[]', status);
      }
    } else {
      searchParams.append('statuses[]', filterStatus.value);
    }

    const response = await api.projects.listForPlanning(props.uuid, searchParams);

    projects.value = response.data.projects;
    periodWeeks.value = response.data.periods.weeks;
    periodMonths.value = response.data.periods.months;
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
}

const timeEntryActivityModal = useModal({
  component: TimeEntryActivityModal,
  attrs: {
    onClose() {
      timeEntryActivityModal.close();
    },
    // @ts-ignore
    onClosed() {
      timeEntryActivityModal.patchOptions({ attrs: { id: null } });
    },
    onCancel() {
      timeEntryActivityModal.close();
    },
    onSplit() {
      getProjects(false);
      timeEntryActivityModal.close();
    },
    onCreated() {
      getProjects(false);
      timeEntryActivityModal.close();
    },
    onUpdated() {
      getProjects(false);
      timeEntryActivityModal.close();
    },
    onDeleted() {
      getProjects(false);
      timeEntryActivityModal.close();
    },
  },
});

const timeEntryProjectTaskModal = useModal({
  component: TimeEntryProjectTaskModal,
  attrs: {
    onClose() {
      timeEntryProjectTaskModal.close();
    },
    // @ts-ignore
    onClosed() {
      // @ts-ignore
      timeEntryProjectTaskModal.patchOptions({ attrs: { id: null } });
    },
    onCancel() {
      timeEntryProjectTaskModal.close();
    },
    onSplit() {
      getProjects(false);
      timeEntryProjectTaskModal.close();
    },
    onCreated() {
      getProjects(false);
      timeEntryProjectTaskModal.close();
    },
    onUpdated() {
      getProjects(false);
      timeEntryProjectTaskModal.close();
    },
    onDeleted() {
      getProjects(false);
      timeEntryProjectTaskModal.close();
    },
  },
});

function onEditEvent(id: number, mode: EventType) {
  if (mode === EventType.Activity) {
    timeEntryActivityModal.patchOptions({ attrs: { id } });
    timeEntryActivityModal.open();
  } else if (mode === EventType.ProjectTask) {
    timeEntryProjectTaskModal.patchOptions({ attrs: { id } });
    timeEntryProjectTaskModal.open();
  }
}

function getEvents(events: IClientPlanningProjectEvent[], week: number) {
  return events.filter((event) => event.week == week);
}

function getEventsOfProject(project: IClientPlanningProject, week: number) {
  return project.tasks.reduce((acc, task) => {
    return [...acc, ...getEvents(task.events, week)];
  }, [] as IClientPlanningProjectEvent[]);
}

function getEventsPriceOfProject(project: IClientPlanningProject, week: number) {
  let trackedTime = 0;
  let eventsSum = 0;

  for (const event of getEventsOfProject(project, week)) {
    trackedTime += event.total_tracked_time;
    eventsSum += event.scheduled_time;
  }

  return {
    trackedTime,
    eventsSum,
  };
}

function startEventDrag(dragEvent: DragEvent, event: IClientPlanningProjectEvent, projectId: number) {
  if (!dragEvent.dataTransfer) return;
  dragEvent.stopPropagation();
  const target = dragEvent.target as HTMLDivElement;
  target.classList.add('dragging');
  dragEvent.dataTransfer.dropEffect = 'move';
  dragEvent.dataTransfer.effectAllowed = 'move';
  dragEvent.dataTransfer.setData('project_id', projectId.toString());
  dragEvent.dataTransfer.setData('event_week', event.week.toString());
  dragEvent.dataTransfer.setData('event_id', event.id.toString());
}

function isDraggable(event: IClientPlanningProjectEvent) {
  return !(event.time_sheet_locked || event.total_tracked_time !== 0 || event.is_done);
}

async function dropEvent(dragEvent: DragEvent, projectId: number, week: number, isTimePeriodLocked: boolean) {
  dragEvent.preventDefault();
  dragEvent.stopPropagation();
  if (
    isTimePeriodLocked ||
    !dragEvent.dataTransfer ||
    !dragEvent.dataTransfer.getData('project_id') ||
    Number(dragEvent.dataTransfer.getData('project_id')) !== projectId ||
    Number(dragEvent.dataTransfer.getData('event_week')) === week ||
    week < getCurrentYearAndWeek()
  )
    return;
  const id = dragEvent.dataTransfer.getData('event_id');
  const element = (dragEvent.target as HTMLElement).closest('.week-cell.droppable');
  if (element) element.classList.remove('over');
  progress.start();
  document.getElementById(`event-element-${id}`)?.remove();
  await api.events.updateWeek(Number(id), { week, projectId });
  await getProjects(false);
  progress.done();
}

async function onFilter() {
  await getProjects(false);
}

async function resetFilters() {
  filterStatus.value = ProjectStatusType.Active;
  await onFilter();
}

function scheduleScroll(e: WheelEvent) {
  const element = tableElement.value as HTMLDivElement;
  const target = e.target as HTMLElement;
  if (target.closest('.scroll-ignore')) return;
  const race = 80;
  element.scrollLeft = e.deltaY > 0 ? element.scrollLeft + race : element.scrollLeft - race;
  e.preventDefault();
}

function getEventStatus(event: IClientPlanningProjectEvent) {
  return event.is_done ? 'is-done' : event.total_tracked_time ? 'is-progress' : '';
}

function endEventDrag(event: DragEvent) {
  (event.target as HTMLDivElement).classList.remove('dragging');
}

function onWeekOver(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.week-cell.droppable');
  if (element) element.classList.add('over');
}

function onWeekLeave(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.week-cell.droppable');
  if (element) element.classList.remove('over');
}

onMounted(getProjects);
watch(weekOffset, () => {
  getProjects();
});
</script>

<template>
  <div class="d-flex align-items-center justify-content-between">
    <h1 v-text="t('client.planning.weekly_overview')" />
    <AppButton
      @click.prevent="router.push({ name: 'projects.create', params: { uuid: props.uuid } })"
      color="secondary"
      class="float-right"
      v-if="uuid"
    >
      {{ t('client.projects.index.create') }}
    </AppButton>
  </div>
  <AppCollapse class="my-3" :title="t('common.filters')">
    <form @submit.prevent="onFilter">
      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 align-items-end">
        <!-- Customer name or number -->
        <div class="form-group col">
          <FormLabel html-for="filter_name">
            {{ t('client.planning.status') }}
          </FormLabel>
          <VueSelect
            v-model="filterStatus"
            :reduce="(option: StatusOption) => option.value"
            :options="[
              { label: t('common.all'), value: 'all' },
              { label: t('project.statuses.active'), value: ProjectStatusType.Active },
              { label: t('project.statuses.done'), value: ProjectStatusType.Done },
              { label: t('project.statuses.cancelled'), value: ProjectStatusType.Cancelled },
            ]"
            label="label"
            input-id="filter_status"
            :placeholder="t('common.all')"
            :disabled="loader.isLoading.value"
            :clearable="false"
          />
        </div>
      </div>
      <div>
        <AppButton :disabled="loader.isLoading.value">
          {{ t('common.apply_filters') }}
        </AppButton>
        <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="loader.isLoading.value">
          {{ t('common.reset_filters') }}
        </AppButton>
      </div>
    </form>
  </AppCollapse>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <template v-if="projects.length > 0">
      <AppTable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>
              <h2 class="text-neutral-900 mb-0" v-text="t('client.planning.planned_elements_per_week')" />
            </AppTableTh>
          </AppTableTr>
        </AppTableHead>
      </AppTable>
      <div @wheel="scheduleScroll" ref="tableElement" class="table-responsive" style="padding-left: 1px">
        <table class="table">
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap class="scroll-ignore">
                <div class="d-flex align-items-center justify-content-between flex-nowrap">
                  <div>
                    <AppButton size="small" @click.prevent="weekOffset -= 14" light circle v-tooltip.top="'-14'">
                      <FontIcon name="chevrons-left" />
                    </AppButton>
                    <AppButton
                      class="ml-1"
                      size="small"
                      @click.prevent="weekOffset -= 5"
                      light
                      circle
                      v-tooltip.top="'-5'"
                    >
                      <FontIcon name="chevron-left" />
                    </AppButton>
                  </div>
                  <span class="px-4">{{ t('common.months') }}</span>
                  <div>
                    <AppButton size="small" @click.prevent="weekOffset += 5" light circle v-tooltip.top="'+5'">
                      <FontIcon name="chevron-right" />
                    </AppButton>
                    <AppButton
                      class="ml-1"
                      size="small"
                      @click.prevent="weekOffset += 14"
                      light
                      circle
                      v-tooltip.top="'+14'"
                    >
                      <FontIcon name="chevrons-right" />
                    </AppButton>
                  </div>
                </div>
              </AppTableTh>
              <AppTableTh
                v-for="(period_week, index) in periodWeeks"
                :class="getCurrentWeek() === period_week.week ? 'current-month-accent' : ''"
                nowrap
                class="text-center"
                style="min-width: 10rem"
                :key="index"
                :colspan="period_week.count_months"
              >
                <span v-text="t('client.planning.week')" /> {{ period_week.week }}
              </AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody
            style="border-bottom: 20px solid var(--color-neutral-100-hex)"
            v-for="project in projects"
            :key="project.id"
          >
            <AppTableTr>
              <AppTableTd class="scroll-ignore" style="vertical-align: top">
                <div class="d-flex align-items-center justify-content-between">
                  <RouterLink
                    target="_blank"
                    :to="{ name: 'projects.edit', params: { uuid: props.uuid, id: project.id } }"
                    class="pointer mr-2 flex-grow-1"
                  >
                    <h3 class="mb-0 ml-1">{{ project.name }}</h3>
                  </RouterLink>
                  <div class="flex-shrink-0">
                    <AppButton
                      v-if="!isProjectExpanded(project.id) && project.tasks.length > 0"
                      size="small"
                      light
                      circle
                      @click.prevent="expandProject(project.id)"
                      class="float-right"
                    >
                      <FontIcon name="chevron-right" />
                    </AppButton>
                    <AppButton
                      class="float-right"
                      v-if="isProjectExpanded(project.id)"
                      size="small"
                      light
                      circle
                      @click.prevent="collapseProject(project.id)"
                    >
                      <FontIcon name="chevron-down" />
                    </AppButton>
                  </div>
                </div>
              </AppTableTd>
              <AppTableTd
                :colspan="period_week.count_months"
                :key="key"
                nowrap
                v-for="(period_week, key) in periodWeeks"
                style="vertical-align: top"
                :class="getCurrentWeek() === period_week.week ? 'current-month-blink' : ''"
              >
                <template v-if="!isProjectExpanded(project.id)">
                  <div
                    class="event-item"
                    v-for="(event, index) in getEventsOfProject(project, period_week.week_with_year)"
                    @click.prevent="onEditEvent(event.id, EventType.ProjectTask)"
                    :key="index"
                    :class="getEventStatus(event)"
                    v-tooltip="event.user ? event.user.name : null"
                  >
                    <div v-if="event.user" class="avatar initials is-small mr-2" v-text="event.user.name.charAt(0)" />
                    <span>
                      {{ convertMinutesToTime(event.total_tracked_time) }} /
                      {{ convertMinutesToTime(event.scheduled_time) }}
                    </span>
                  </div>
                </template>
              </AppTableTd>
            </AppTableTr>
            <template v-if="isProjectExpanded(project.id)">
              <AppTableTr v-for="task in project.tasks" :key="task.id">
                <AppTableTd style="vertical-align: top" class="font-bold scroll-ignore" nowrap>
                  {{ task.name }}
                </AppTableTd>
                <AppTableTd
                  class="week-cell"
                  :colspan="period_week.count_months"
                  :key="key"
                  v-for="(period_week, key) in periodWeeks"
                  nowrap
                  style="vertical-align: top"
                  @drop.prevent="
                    dropEvent($event, project.id, period_week.week_with_year, period_week.is_time_period_locked)
                  "
                  :class="[
                    { droppable: isWeekGreaterThanCurrent(period_week.week_with_year, true) },
                    getCurrentWeek() === period_week.week ? 'current-month-blink' : '',
                  ]"
                  @dragenter.prevent
                  @dragover.prevent="onWeekOver"
                  @dragleave.prevent="onWeekLeave"
                >
                  <div
                    :draggable="isDraggable(event)"
                    @dragstart="startEventDrag($event, event, project.id)"
                    @dragend="endEventDrag($event)"
                    :key="key2"
                    class="event-item"
                    :id="`event-element-${event.id}`"
                    v-for="(event, key2) in getEvents(task.events, period_week.week_with_year)"
                    @click.prevent="onEditEvent(event.id, EventType.ProjectTask)"
                    :class="[getEventStatus(event), { draggable: isDraggable(event) }]"
                    v-tooltip="event.user ? event.user.name : null"
                  >
                    <div v-if="event.user" class="avatar initials is-small mr-2" v-text="event.user.name.charAt(0)" />
                    <span>
                      {{ convertMinutesToTime(event.total_tracked_time) }} /
                      {{ convertMinutesToTime(event.scheduled_time) }}
                    </span>
                  </div>
                </AppTableTd>
              </AppTableTr>
            </template>
            <AppTableTr>
              <AppTableTd class="font-bold scroll-ignore">
                {{ t('client.planning.sum') }} {{ project.name }}
              </AppTableTd>
              <AppTableTd
                :key="key"
                v-for="(period_week, key) in periodWeeks"
                nowrap
                :colspan="period_week.count_months"
                class="total-item"
                :class="getCurrentWeek() === period_week.week ? 'current-month-blink' : ''"
              >
                {{ convertMinutesToTime(getEventsPriceOfProject(project, period_week.week_with_year)['trackedTime']) }}
                /
                {{ convertMinutesToTime(getEventsPriceOfProject(project, period_week.week_with_year)['eventsSum']) }}
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </table>
      </div>
    </template>
    <AppAlert v-else type="info">{{ t('common.no_results') }}</AppAlert>
  </template>
</template>

<style lang="scss" scoped>
.current-week {
  color: white;
  background-color: var(--color-success-500-hex);
}

.table tr td:first-child,
.table tr th:first-child {
  position: sticky;
  left: 0;
  z-index: 100;
  background-color: white;
  outline: 1px solid var(--color-neutral-100-hex);
}

.event-item {
  border-width: 1px;
  border-style: solid;
  padding: 0.25rem;
  border-radius: var(--radius-base);
  display: flex;
  align-items: center;
  font-size: 85%;
  font-weight: 600;
  cursor: pointer;
  border-color: transparent;
  background-color: var(--color-neutral-100-hex);
  color: var(--color-neutral-900-hex);
  user-select: none;
  &.is-done {
    border-color: transparent;
    background-color: var(--color-success-100-hex);
    color: var(--color-success-900-hex);
  }
  &.is-progress {
    border-color: transparent;
    background-color: var(--color-warning-100-hex);
    color: var(--color-warning-900-hex);
  }

  + .event-item {
    margin-top: 0.5rem;
  }
}

.table {
  border-spacing: 0;
  border-collapse: collapse;
  border: none;
  td,
  th {
    border: 1px solid var(--color-neutral-200-hex);
  }
}

.current-month-accent {
  background-color: var(--color-success-500-hex);
  color: var(--color-success-100-hex);
}

.current-month-blink {
  background-color: var(--color-neutral-50-hex);
}

.total-item {
  font-weight: 600;
  font-size: 0.75rem;
  text-align: center;
}

.draggable {
  cursor: pointer;
  &.dragging {
    opacity: 0.25;
    background-color: white;
  }
}
.droppable {
  &.over {
    background-color: var(--color-neutral-100-hex);
  }
}
</style>
