<script setup lang="ts">
import { watch, ref, onMounted, reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { useRouter } from 'vue-router';
import VueSelect, { VueSelectInstance } from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';

import api from '@/services/api';
import {
  AppBox,
  AppBoxBody,
  AppLoader,
  AppButton,
  AppAlert,
  FontIcon,
  AppTable,
  AppTableHead,
  AppTableBody,
  AppTableTr,
  AppTableTd,
  AppTableTh,
  AppPagination,
  TimeSheetStatus,
  TimeSheetReviewStatus,
  FormLabel,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useAuthStore from '@/store/AuthStore';
import useTime from '@/composables/useTime';
import useTimeSheet from '@/composables/useTimeSheet';
import { IUserListResource } from '@/types/User';
import { ITimeSheetPreviewResource, TimeSheetStatusType, TimeSheetReviewStatusType } from '@/types/TimeSheet';

const currentPage = useRouteQuery('page', '1', { transform: Number });

const filterStatusDropdown = ref<VueSelectInstance | null>(null);
const filterReviewStatusDropdown = ref<VueSelectInstance | null>(null);
const filterUserDropdown = ref<VueSelectInstance | null>(null);

const { t, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { isCustomerAdminRole, isSuperAdminRole, isManagerRole, isUserRole } = useAuthStore();
const { convertMinutesToTime } = useTime();
const router = useRouter();
const { statusOptions, reviewStatusOptions } = useTimeSheet();

const perPage = ref(20);
const total = ref(0);

const timeSheets = ref<ITimeSheetPreviewResource[]>([]);

const editable = computed(() => isCustomerAdminRole || isSuperAdminRole || (isManagerRole && !isUserRole));

const users = ref<IUserListResource[]>([]);
const usersLoading = ref(false);

const filters = reactive<{
  dirty: boolean;
  period: null | string;
  user_uuid: string[];
  status: TimeSheetStatusType[];
  review_status: TimeSheetReviewStatusType[];
}>({
  dirty: false,
  period: null,
  user_uuid: [],
  status: [],
  review_status: [],
});

const filterDisabled = computed(() => loader.isLoading.value);
const filterApplyDisabled = computed(
  () =>
    filters.period === null &&
    filters.user_uuid.length === 0 &&
    filters.status.length === 0 &&
    filters.review_status.length === 0,
);

async function getTimeSheets() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    if (filters.period) {
      const [month, year] = filters.period.split('-');
      searchParams.append('month', month);
      searchParams.append('year', year);
    }
    filters.user_uuid.forEach((uuid) => searchParams.append('users_uuid[]', uuid));
    filters.status.forEach((status) => searchParams.append('statuses[]', status));
    filters.review_status.forEach((status) => searchParams.append('review_statuses[]', status));
    const response = await api.timeSheets.index(searchParams);
    timeSheets.value = response.data;
    perPage.value = response.meta!.per_page;
    total.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  }
}

async function getUsers() {
  try {
    usersLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    usersLoading.value = false;
  }
}

async function onApprove(timeSheet: ITimeSheetPreviewResource, event: PointerEvent) {
  if (event.target) {
    const target = event.target as HTMLElement;
    const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
    button.classList.add('is-loading');
    try {
      await api.timeSheets.update(timeSheet.id, { review_status: 'approved' });
      await getTimeSheets();
    } catch (error) {
      console.error(error);
    } finally {
      button.classList.remove('is-loading');
    }
  }
}

async function onNotApprove(timeSheet: ITimeSheetPreviewResource, event: PointerEvent) {
  if (event.target) {
    const target = event.target as HTMLElement;
    const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
    button.classList.add('is-loading');
    try {
      await api.timeSheets.update(timeSheet.id, { review_status: 'not_approved' });
      await getTimeSheets();
    } catch (error) {
      console.error(error);
    } finally {
      button.classList.remove('is-loading');
    }
  }
}

async function onFilter() {
  loader.start();
  currentPage.value = 1;
  await getTimeSheets();
  loader.finish();
  filters.dirty = true;
}

async function resetFilters() {
  filters.status = [];
  filterStatusDropdown.value!.search = '';
  filters.review_status = [];
  filterReviewStatusDropdown.value!.search = '';
  filters.user_uuid = [];
  filterUserDropdown.value!.search = '';
  filters.period = null;
  await onFilter();
  filters.dirty = false;
}

onMounted(async () => {
  loader.start();
  await Promise.all([getTimeSheets(), getUsers()]);
  loader.finish();
});

watch(currentPage, async () => {
  loader.start();
  await getTimeSheets();
  loader.finish();
});
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-end">
      <h1 class="mb-0" v-text="t('time-sheet.index.title')" />
      <HelpInformation class="ml-1" translation="time-sheet.index.help" />
    </div>
    <form @submit.prevent="onFilter" class="my-3">
      <AppBox>
        <AppBoxBody>
          <div class="row">
            <div class="form-group col-md-2">
              <FormLabel html-for="filters_time_period_month">
                {{ t('time-sheet.attributes.time_period') }}
              </FormLabel>
              <div class="form-wrapper has-icon">
                <VueDatePicker
                  input-class-name="form-control"
                  :placeholder="t('common.select')"
                  v-model="filters.period"
                  format="MM-yyyy"
                  model-type="format"
                  :enable-time-picker="false"
                  :month-change-on-scroll="false"
                  auto-apply
                  month-picker
                  :disabled="filterDisabled"
                  position="left"
                  :locale="locale"
                  :week-num-name="t('common.week_short')"
                >
                  <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                </VueDatePicker>
              </div>
            </div>
            <div class="form-group col-md-3">
              <FormLabel html-for="filters_time_period_status">
                {{ t('time-sheet.attributes.status') }}
              </FormLabel>
              <VueSelect
                v-model="filters.status"
                :options="statusOptions"
                label="label"
                :reduce="(option) => option.value"
                input-id="filters_time_period_status"
                :placeholder="t('common.select')"
                multiple
                :disabled="filterDisabled"
                ref="filterStatusDropdown"
              />
            </div>
            <div class="form-group col-md-3">
              <FormLabel html-for="filters_time_period_review_status">
                {{ t('time-sheet.attributes.review_status') }}
              </FormLabel>
              <VueSelect
                v-model="filters.review_status"
                :options="reviewStatusOptions"
                label="label"
                :reduce="(option) => option.value"
                input-id="filters_time_period_review_status"
                :placeholder="t('common.select')"
                multiple
                :disabled="filterDisabled"
                ref="filterReviewStatusDropdown"
              />
            </div>
            <div class="form-group col-md-4">
              <FormLabel html-for="filters_time_period_user">
                {{ t('time-sheet.attributes.user') }}
              </FormLabel>
              <VueSelect
                v-model="filters.user_uuid"
                :options="users"
                :reduce="(option) => option.uuid"
                label="name"
                input-id="filters_time_period_user"
                :placeholder="t('common.search')"
                multiple
                :disabled="usersLoading || filterDisabled"
                :loading="usersLoading"
                ref="filterUserDropdown"
              />
            </div>
            <div class="col-12">
              <AppButton :disabled="filterDisabled || filterApplyDisabled">
                {{ t('common.apply_filters') }}
              </AppButton>
              <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filterDisabled || !filters.dirty">
                {{ t('common.reset_filters') }}
              </AppButton>
            </div>
          </div>
        </AppBoxBody>
      </AppBox>
    </form>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="timeSheets.length === 0">
        {{ filters.dirty ? t('common.no_search_results') : t('time-sheet.empty') }}
      </AppAlert>
      <AppTable v-else hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.time_period') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.status') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.review_status') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.total_tracked_time') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('time-sheet.attributes.user') }}</AppTableTh>
            <AppTableTh nowrap class="text-right" v-if="editable">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr
            @click="router.push({ name: 'time-sheets.show', params: { id: timeSheet.id } })"
            class="pointer"
            v-for="timeSheet in timeSheets"
            :key="timeSheet.id"
          >
            <AppTableTd nowrap>
              {{ timeSheet.time_period.month.toString().padStart(2, '0') }}-{{ timeSheet.time_period.year }}
            </AppTableTd>
            <AppTableTd>
              <TimeSheetStatus :status="timeSheet.status" />
            </AppTableTd>
            <AppTableTd>
              <TimeSheetReviewStatus :status="timeSheet.review_status" />
            </AppTableTd>
            <AppTableTd nowrap>{{ convertMinutesToTime(timeSheet.total_tracked_time) }}</AppTableTd>
            <AppTableTd nowrap>
              <span v-if="timeSheet.user" v-text="timeSheet.user.name" />
              <i v-else class="text-neutral-300" v-text="t('common.empty')" />
            </AppTableTd>
            <AppTableTd v-if="editable" nowrap class="text-right">
              <AppButton
                v-if="timeSheet.review_status !== 'approved'"
                v-tooltip="t('time-sheet.tooltip.approve')"
                @click.stop.prevent="onApprove(timeSheet, $event)"
                size="small"
                light
                circle
                class="ml-2"
                color="success"
              >
                <FontIcon name="check" />
              </AppButton>
              <AppButton
                v-if="timeSheet.review_status !== 'not_approved'"
                v-tooltip="t('time-sheet.tooltip.not_approve')"
                @click.stop.prevent="onNotApprove(timeSheet, $event)"
                size="small"
                light
                circle
                class="ml-2"
                color="danger"
              >
                <FontIcon name="ban" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
    </template>
  </div>
</template>
