export default {
  // Settings
  settings: {
    title: 'Löneinställningar',
    help: '',
    attributes: {
      system: 'Lönesystem',
      mileage_allowance_amount: 'Milersättning belopp',
    },
  },

  // Codes
  codes: {
    title: 'Lönekoder',
    help: '',
    add_new: 'Lägg till',
    empty: 'Inga lönekoder',
    create: {
      title: 'Skapa lönekod',
    },
    edit: {
      title: 'Redigera lönekod',
    },
    destroy: {
      title: 'Radera lönekod',
      text: 'Är du säker på att du vill radera <strong>{name}</strong> lönekoder?',
    },
    attributes: {
      name: 'Namn',
      system_id: 'ID',
      type: 'Typ',
      absence_type: 'Frånvarotyp',
      is_default: 'Standard',
      client_work_time: 'Klientarbetstid',
    },
  },

  // Messages
  message: {
    system_is_not_set: 'Välj lönesystem',
  },
};
