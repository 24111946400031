export default {
  index: {
    title: 'Tjänster',
    create: 'Skapa tjänster',
    help: '',
    filters: {
      name: 'Namn',
    },
  },
  create: {
    title: 'Skapa tjänst',
    help: '',
  },
  edit: {
    title: 'Redigera: {name}',
    help: '',
  },
  form: {
    general_info: 'Allmän info',
  },

  checkpoint_frequency_options: {
    calendar_year_quarter: 'Kalenderkvartal',
    calendar_year: 'Kalenderår',
    financial_year_quarter: 'Räkenskapsårskvartal',
    financial_year: 'Räkenskapsår',
    monthly: 'Månad',
  },

  price_type: {
    fixed: 'Fastpris',
    hourly: 'Timarvode',
  },

  invoice_frequency: {
    once: 'Engång',
    monthly: 'Månad',
    quarterly: 'Kvartal',
    'semi-annually': 'Halvår',
    annually: 'År',
  },

  price_deadline: {
    op1: 'Kalenderårets slut + 160 dagar',
    op2: 'Räkenskapsårets slut + 6 månader',
    op3: 'Kalenderkvatalets slut + 40 dagar',
    op4: 'Månadsslut + 26 dagar',
  },

  empty: 'Inga tjänster',

  tasks: {
    title: 'Uppgifter',
    add: 'Lägg till uppgift',
    empty: 'Inga uppgifter',

    // Attributes
    attributes: {
      name: 'Namn',
      default_role: 'Standardroll',
      default_time_budget: 'Uppskattad tid',
      default_frequency: 'Standardfrekvens',
      requires_visit: 'Kräver platsbesök',
      auto_include_in_planning: 'Auto-inkludera i planering',
      order: 'Ordning',
    },

    modal: {
      add: 'Ny uppgift',
    },
  },

  // Attributes
  attributes: {
    name: 'Tjänstens namn',
    checkpoint_frequency: 'Checkpoint fekvens',
    default_price_type: 'Standard pristyo',
    default_invoice_frequency: 'Standard faktureringsfrekvens',
    default_fixed_price: 'Standard årsarvode grund',
    default_price_adjustment: 'Stanadrd prisjustering',
    default_hourly_price_adjustment: 'Standard timprisjustering',
    default_dead_line: 'Standard projekt-deadline',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera "{name}"',
    destroy: 'Radera "{name}"',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Radera service',
      text: 'Är du säker på att du vill radera <strong>{name}</strong>? <br /> Existerande projekt påverka inte.',
    },
  },
};
