<script lang="ts" setup>
const props = defineProps<{
  wrapperCssClass?: string;
  tableCssClass?: string;
  hoverable?: boolean;
}>();
</script>

<template>
  <div class="table-responsive" :class="props.wrapperCssClass ? props.wrapperCssClass : ''">
    <table class="table" :class="[{ hoverable: props.hoverable }, props.tableCssClass ? props.tableCssClass : '']">
      <slot />
    </table>
  </div>
</template>
