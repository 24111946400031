import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default function useTimeSheet() {
  const { t } = useI18n({ useScope: 'global' });

  const statusOptions = computed(() => [
    { value: 'locked', label: t('time-sheet.status.locked') },
    { value: 'unlocked', label: t('time-sheet.status.unlocked') },
  ]);

  const reviewStatusOptions = computed(() => [
    { value: 'not_reviewed', label: t('time-sheet.review_status.none') },
    { value: 'not_approved', label: t('time-sheet.review_status.not_approved') },
    { value: 'approved', label: t('time-sheet.review_status.approved') },
  ]);

  return {
    statusOptions,
    reviewStatusOptions,
  };
}
