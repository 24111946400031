<script setup lang="ts">
import {
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  CancelOrRolloverProjectModal,
  ConfirmDialog,
  FontIcon,
  ProjectNoteModal,
  ProjectStatus,
} from '@/components';
import { inject, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import useLoader from '@/composables/useLoader';
import api from '@/services/api';
import useTime from '@/composables/useTime';
import { IProjectClientTabResource, ProjectStatusType } from '@/types/Project';
import { useModal } from 'vue-final-modal';
import useProject from '@/composables/useProject';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import { ClientTabs } from '@/types/Client';

const loader = useLoader();
const { convertMinutesToTime } = useTime();
const { calculateAverageCost } = useProject();

const router = useRouter();
const { t, d } = useI18n({ useScope: 'global' });

// Projects
const projects = ref<IProjectClientTabResource[]>([]);
const projectsLoader = useLoader({ useProgress: false });

const clientUuid = inject<string>('clientUuid');

const filters = reactive({
  active: true,
});

const editModal = useModal({
  component: ProjectNoteModal,
  attrs: {
    async onUpdate() {
      try {
        // @ts-ignore
        editModal.patchOptions({ attrs: { loading: true } });
        await getProjects();
        await editModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        editModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      editModal.close();
    },
    // @ts-ignore
    onClosed() {
      // @ts-ignore
      editModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

async function onEdit(project: IProjectClientTabResource, event: PointerEvent) {
  if (!clientUuid) {
    return;
  }
  if (event.target) {
    const target = event.target as HTMLElement;
    const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
    button.classList.add('is-loading');
    try {
      editModal.patchOptions({
        attrs: {
          clientUuid: clientUuid,
          project: project,
        },
      });
      await editModal.open();
    } catch (error) {
      console.error(error);
    } finally {
      button.classList.remove('is-loading');
    }
  }
}

async function getProjects() {
  try {
    projectsLoader.start();
    const searchParams = new URLSearchParams();
    if (filters.active) searchParams.append('active', '1');
    const response = await api.projects.listProjectTabResource(clientUuid!, searchParams);
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    projectsLoader.finish();
  }
}

function cancelProject(project: IProjectClientTabResource) {
  if (!clientUuid) {
    return;
  }

  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'cancel',
    clientUuid,
    project: {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProjects();
      if (newProjectId) {
        await router.push({ name: 'projects.edit', params: { uuid: clientUuid, id: newProjectId } });
      }
    },
  });

  dialog.reveal();
}

function rolloverProject(project: IProjectClientTabResource) {
  if (!clientUuid) {
    return;
  }

  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'rollover',
    clientUuid,
    project: {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProjects();
      if (newProjectId) {
        await router.push({ name: 'projects.edit', params: { uuid: clientUuid, id: newProjectId } });
      }
    },
  });

  dialog.reveal();
}

function reactivateProject(project: IProjectClientTabResource) {
  const dialog = createConfirmDialog(ConfirmDialog, {
    title: t('project.reactivate_modal.title', { name: project.name }),
    confirmBtnType: 'success',
    delayTime: 0,
    size: 's',
    confirmCallback: async () => {
      if (clientUuid) {
        await api.projects.reactivate(clientUuid, project.id);
        await getProjects();
      }
    },
  });

  dialog.reveal();
}

async function deleteProject(project: IProjectClientTabResource) {
  const dialog = createConfirmDialog(ConfirmDialog, {
    title: t('project.confirm.destroy.title'),
    message: t('project.confirm.destroy.text', { name: project.name }),
    confirmBtnType: 'danger',
    delayTime: 0,
    size: 'm',
    confirmCallback: async () => {
      if (clientUuid) {
        await api.projects.destroy(clientUuid, project.id);
        await getProjects();
      }
    },
  });

  dialog.reveal();
}

onMounted(async () => {
  await Promise.all([getProjects()]);
});

watch(filters, async () => {
  loader.start();
  await getProjects();
  loader.finish();
});
</script>

<template>
  <AppBox shadow>
    <AppBoxBody>
      <div class="d-flex align-items-center justify-content-between mb-4">
        <h2 v-text="t('client.project.title')" />
        <div class="form-group">
          <input id="show_only_active" type="checkbox" class="form-check" value="1" v-model="filters.active" />
          <label for="show_only_active" class="form-label" v-text="t('client.project.show_only_active')" />
        </div>
      </div>
      <div v-if="projectsLoader.isLoading.value" class="text-center">
        <AppLoader size="large" />
      </div>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('client.project.attributes.name') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.project.attributes.start_date') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.project.attributes.end_date') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.project.attributes.financial_year') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.project.attributes.deadline') }}</AppTableTh>
              <AppTableTh nowrap>
                {{ t('client.project.attributes.status') }} ({{ t('client.project.attributes.status_changed_at') }})
              </AppTableTh>
              <AppTableTh nowrap>
                <span
                  v-tooltip.top="
                    `${t('client.attributes.reported')} | ${t('client.attributes.estimated')}
                    | ${t('client.attributes.planned')}`
                  "
                  nowrap
                >
                  {{ t('client.attributes.r') }} | {{ t('client.attributes.e') }} | {{ t('client.attributes.p') }}
                </span>
              </AppTableTh>
              <AppTableTh>{{ t('client.project.attributes.hourly_avg_revenue') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.project.attributes.margin') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.project.attributes.margin_percent') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('client.project.attributes.notes') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('client.project.attributes.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="project in projects" :key="project.id">
              <AppTableTd nowrap>
                <strong v-text="project.name" />
              </AppTableTd>
              <AppTableTd nowrap>{{ d(project.start_date) }}</AppTableTd>
              <AppTableTd nowrap>{{ d(project.end_date) }}</AppTableTd>
              <AppTableTd>
                <span v-if="project.financial_year">
                  {{ d(project.financial_year.start_date) }} -
                  {{ d(project.financial_year.end_date) }}
                </span>
              </AppTableTd>
              <AppTableTd nowrap>
                <span v-if="project.deadline_date" v-text="d(project.deadline_date)" />
                <span v-else v-text="t('client.project.attributes.none')" />
              </AppTableTd>
              <AppTableTd nowrap>
                <ProjectStatus class="mx-3" :status="project.status" />
                <span v-if="project.status_changed_at">({{ d(project.status_changed_at) }})</span>
              </AppTableTd>
              <AppTableTd nowrap>
                {{ convertMinutesToTime(project.total_reported_time) }}
                | {{ convertMinutesToTime(project.total_estimated_time) }} |
                {{ convertMinutesToTime(project.total_forecasted_time) }}
              </AppTableTd>
              <AppTableTd nowrap
                >{{ calculateAverageCost(project.external_total_price, project.total_minutes) }}
              </AppTableTd>
              <AppTableTd nowrap>{{ project.marginality }}</AppTableTd>
              <AppTableTd nowrap>{{ project.marginality_percent }} %</AppTableTd>
              <AppTableTd nowrap>
                <AppButton @click.stop.prevent="onEdit(project, $event)" size="small" circle light>
                  <FontIcon v-if="project.notes" name="message-circle-2-filled" />
                  <FontIcon v-else name="message-circle-2" />
                </AppButton>
              </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton
                  v-tooltip.left="t('project.tooltip.view', { name: project.name })"
                  @click.stop="
                    router.push({
                      name: 'projects.view',
                      params: { uuid: clientUuid, id: project.id },
                      query: { from: ClientTabs.Projects },
                    })
                  "
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="eye" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('project.tooltip.plan', { name: project.name })"
                  @click.stop="
                    router.push({
                      name: 'projects.edit',
                      params: { uuid: clientUuid, id: project.id },
                      query: { from: ClientTabs.Projects },
                    })
                  "
                  class="ml-2"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="pencil" />
                </AppButton>
                <AppButton
                  v-if="project.status === ProjectStatusType.Active"
                  v-tooltip.left="t('project.tooltip.cancel', { name: project.name })"
                  @click.stop="cancelProject(project)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="ban" />
                </AppButton>
                <AppButton
                  v-if="project.status === ProjectStatusType.Active"
                  v-tooltip.left="t('project.tooltip.rollover', { name: project.name })"
                  @click.stop="rolloverProject(project)"
                  class="ml-2"
                  color="secondary"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="reload" />
                </AppButton>
                <AppButton
                  v-if="project.status === ProjectStatusType.Cancelled"
                  v-tooltip.left="t('project.tooltip.reactivate', { name: project.name })"
                  @click.stop="reactivateProject(project)"
                  class="ml-2"
                  color="success"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="arrow-back-up" />
                </AppButton>
                <AppButton
                  v-tooltip.left="t('project.tooltip.destroy', { name: project.name })"
                  @click.stop="deleteProject(project)"
                  class="ml-2"
                  size="small"
                  color="danger"
                  light
                  circle
                  v-if="project.status === ProjectStatusType.Draft"
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
      </template>
    </AppBoxBody>
  </AppBox>
</template>

<style scoped></style>
