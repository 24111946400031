<script lang="ts" setup>
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    cssClass?: string;
    border?: boolean;
    shadow?: boolean;
  }>(),
  {
    cssClass: '',
    border: false,
    shadow: false,
  },
);

const classes = computed(() => [
  'box',
  props.cssClass,
  { 'is-bordered': props.border },
  { 'is-shadowed': props.shadow },
]);
</script>

<template>
  <div :class="classes">
    <slot />
  </div>
</template>
