<script setup lang="ts">
import { HTTPError } from 'ky';
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

import { AppButton, FormLabel, FormInput } from '@/components';
import { IAuthLoginRequestBody } from '@/types/Auth';
import { isEmail, isLength } from '@/utils/validation';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useAuthStore from '@/store/AuthStore';
import toast from '@/services/toast';

const loader = useLoader();
const { setAuthenticatedUser } = useAuthStore();
const { t } = useI18n({ useScope: 'global' });

const form = reactive<IAuthLoginRequestBody>({
  email: '',
  password: '',
  remember: true,
});

const isLoginFormValid = computed(() => isEmail(form.email) && isLength(form.password, { min: 8 }));

async function login() {
  try {
    loader.start();
    await api.auth.login(form);
    const authenticatedUser = await api.users.currentAuthenticatedUser();
    setAuthenticatedUser(authenticatedUser);
  } catch (error: unknown) {
    const errors = await (error as HTTPError).response.json();
    toast.error(errors.message);
  } finally {
    loader.finish();
  }
}
</script>

<template>
  <form class="p-sm-4" @submit.prevent="login">
    <h1 class="mb-2 text-center" v-text="t('common.login')" />
    <div class="form-group">
      <FormLabel html-for="email" required>{{ t('common.email') }}</FormLabel>
      <FormInput type="email" id="email" v-model="form.email" :placeholder="t('auth.enter_your_email')" required />
    </div>
    <div class="form-group">
      <FormLabel html-for="password" required>{{ t('common.password') }}</FormLabel>
      <FormInput
        type="password"
        id="password"
        v-model="form.password"
        :placeholder="t('auth.enter_your_password')"
        required
      />
    </div>
    <AppButton class="w-100 mt-3" color="primary" :loading="loader.isLoading.value" :disabled="!isLoginFormValid">
      {{ t('common.login') }}
    </AppButton>
  </form>
</template>
