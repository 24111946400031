<script setup lang="ts">
import { ref } from 'vue';
import { VueFinalModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';

import { AppButton } from '@/components';
import { ConfirmDialogConfirmParams, SetLoading } from '@/types/Common';

type Props = {
  title: string;
  message?: string;
  loading?: boolean;
  params?: {
    [key: string]: boolean | number | string;
  };
};

defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  confirm: [params: ConfirmDialogConfirmParams];
  closed: [];
}>();

const { t } = useI18n({ useScope: 'global' });

const manualLoading = ref(false);

const setLoading: SetLoading = (value: boolean) => {
  manualLoading.value = value;
};
</script>
<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="false"
    :focus-trap="{ initialFocus: '#confirm', allowOutsideClick: true }"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <div class="modal-header">
      <h2 class="mb-0" v-text="title" />
    </div>
    <div class="modal-content">
      <p v-if="message" v-html="message" />
      <slot />
    </div>
    <div class="modal-footer">
      <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
        <AppButton light :disabled="loading || manualLoading" @click="close">
          {{ t('common.cancel') }}
        </AppButton>
        <AppButton
          id="confirm"
          color="danger"
          class="mt-2 mt-sm-2"
          :loading="loading || manualLoading"
          @click="emit('confirm', { setLoading })"
        >
          {{ t('common.confirm') }}
        </AppButton>
      </div>
    </div>
  </VueFinalModal>
</template>
