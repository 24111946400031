import route from 'ziggy-js';
import { Options, SearchParamsOption } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  IServiceCompanyBodyRequest,
  IServiceCompanyContactPreviewResource,
  IServiceCompanyContactRequestBody,
  IServiceCompanyContactResource,
  IServiceCompanyContactRoleRequestBody,
  IServiceCompanyContactRoleResource,
  IServiceCompanyListResource,
  IServiceCompanyResource,
  IServiceCompanyTypeBodyRequest,
  IServiceCompanyTypePreviewResource,
  IServiceCompanyTypeResource,
} from '@/types/ServiceCompany';

export default {
  async list(options?: Options): Promise<IResponse<IServiceCompanyListResource[]>> {
    return await httpClient.get(route('service-companies.list'), options).json();
  },
  async get(serviceCompany: string): Promise<IResponse<IServiceCompanyResource>> {
    return await httpClient.get(route('service-companies.get', { serviceCompany })).json();
  },
  async store(body: IServiceCompanyBodyRequest): Promise<IResponse<IServiceCompanyResource>> {
    return await httpClient.post(route('service-companies.store'), { json: body }).json();
  },
  async update(serviceCompany: string, body: IServiceCompanyBodyRequest): Promise<IResponse<IServiceCompanyResource>> {
    return await httpClient.put(route('service-companies.update', { serviceCompany }), { json: body }).json();
  },
  async destroy(serviceCompany: string): Promise<IResponse<never>> {
    return await httpClient.delete(route('service-companies.delete', { serviceCompany })).json();
  },
  types: {
    async list(searchParams?: SearchParamsOption): Promise<IResponse<IServiceCompanyTypePreviewResource[]>> {
      return await httpClient.get(route('service-companies.types.list'), { searchParams }).json();
    },
    async get(serviceCompanyType: string): Promise<IResponse<IServiceCompanyTypeResource>> {
      return await httpClient.get(route('service-companies.types.get', { serviceCompanyType })).json();
    },
    async store(body: IServiceCompanyTypeBodyRequest): Promise<IResponse<IServiceCompanyTypeResource>> {
      return await httpClient.post(route('service-companies.types.store'), { json: body }).json();
    },
    async update(
      serviceCompanyType: string,
      body: IServiceCompanyTypeBodyRequest,
    ): Promise<IResponse<IServiceCompanyTypeResource>> {
      return await httpClient
        .put(route('service-companies.types.update', { serviceCompanyType }), { json: body })
        .json();
    },
    async destroy(serviceCompanyType: string): Promise<IResponse<never>> {
      return await httpClient.delete(route('service-companies.types.delete', { serviceCompanyType })).json();
    },
    roles: {
      async list(
        serviceCompanyType: string,
        searchParams?: SearchParamsOption,
      ): Promise<IResponse<IServiceCompanyContactRoleResource[]>> {
        return await httpClient
          .get(route('service-companies.types.roles.list', { serviceCompanyType }), { searchParams })
          .json();
      },
      async store(
        serviceCompanyType: string,
        body: IServiceCompanyContactRoleRequestBody,
      ): Promise<IResponse<IServiceCompanyContactRoleResource>> {
        return await httpClient
          .post(route('service-companies.types.roles.store', { serviceCompanyType }), { json: body })
          .json();
      },
      async update(
        serviceCompanyType: string,
        serviceCompanyContactRole: string,
        body: IServiceCompanyContactRoleRequestBody,
      ): Promise<IResponse<IServiceCompanyContactRoleResource>> {
        return await httpClient
          .put(route('service-companies.types.roles.update', { serviceCompanyType, serviceCompanyContactRole }), {
            json: body,
          })
          .json();
      },
      async destroy(serviceCompanyType: string, serviceCompanyContactRole: string): Promise<IResponse<never>> {
        return await httpClient
          .delete(route('service-companies.types.roles.delete', { serviceCompanyType, serviceCompanyContactRole }))
          .json();
      },
    },
  },
  contacts: {
    async list(
      serviceCompany: string,
      searchParams?: SearchParamsOption,
    ): Promise<IResponse<IServiceCompanyContactPreviewResource[]>> {
      return await httpClient
        .get(route('service-companies.contacts.index', { serviceCompany }), { searchParams })
        .json();
    },
    async store(
      serviceCompany: string,
      body: IServiceCompanyContactRequestBody,
    ): Promise<IResponse<IServiceCompanyContactResource>> {
      return await httpClient
        .post(route('service-companies.contacts.store', { serviceCompany }), { json: body })
        .json();
    },
    async get(
      serviceCompany: string,
      serviceCompanyContact: string,
    ): Promise<IResponse<IServiceCompanyContactResource>> {
      return await httpClient
        .get(route('service-companies.contacts.get', { serviceCompany, serviceCompanyContact }))
        .json();
    },
    async update(
      serviceCompany: string,
      serviceCompanyContact: string,
      body: IServiceCompanyContactRequestBody,
    ): Promise<IResponse<IServiceCompanyContactResource>> {
      return await httpClient
        .put(route('service-companies.contacts.update', { serviceCompany, serviceCompanyContact }), { json: body })
        .json();
    },
    async destroy(serviceCompany: string, serviceCompanyContact: string): Promise<IResponse<never>> {
      return await httpClient
        .delete(route('service-companies.contacts.delete', { serviceCompany, serviceCompanyContact }))
        .json();
    },
  },
};
