import route from 'ziggy-js';
import { Options } from 'ky';

import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  IClientVisitResource,
  IDeadlineEventResource,
  IEventWeekRequestBody,
  ISplitEventRequestBody,
  ITimeEntryActivityEventResource,
  ITimeEntryEventResource,
  ITimeEntryProjectTaskEventResource,
  ITimeEntryProjectTaskEventRequest,
  ITimeEntryActivityEventRequest,
} from '@/types/Event';

export default {
  updateWeek(event: number, body: IEventWeekRequestBody): Promise<IResponse<ITimeEntryEventResource>> {
    return httpClient.patch(route('events.update-week', { event }), { json: body }).json();
  },
  destroy(event: number, options?: Options): Promise<void> {
    return httpClient.delete(route('events.delete', { event }), options).json();
  },
  split(event: number, body: ISplitEventRequestBody): Promise<IResponse<ITimeEntryEventResource>> {
    return httpClient.post(route('events.split', { event }), { json: body }).json();
  },
  done(event: number): Promise<void> {
    return httpClient.post(route('events.done', { event })).json();
  },
  move(body: { ids: number[]; week: number }): Promise<void> {
    return httpClient.post(route('events.move'), { json: body }).json();
  },
  moveWeekForward(options?: Options): Promise<void> {
    return httpClient.post(route('events.move_week_forward'), options).json();
  },
  activities: {
    get(event: number, options: Options = {}) {
      return httpClient.get(route('events.get', { event }), options).json<IResponse<ITimeEntryActivityEventResource>>();
    },
    update(
      event: number,
      json: ITimeEntryActivityEventRequest,
      options: Options = {},
    ): Promise<IResponse<ITimeEntryActivityEventResource>> {
      return httpClient.patch(route('events.update', { event }), { json, ...options }).json();
    },
  },
  projectTasks: {
    get(event: number, options: Options = {}) {
      return httpClient
        .get(route('events.get', { event }), options)
        .json<IResponse<ITimeEntryProjectTaskEventResource>>();
    },
    update(
      event: number,
      json: ITimeEntryProjectTaskEventRequest,
    ): Promise<IResponse<ITimeEntryProjectTaskEventResource>> {
      return httpClient.patch(route('events.update', { event }), { json }).json();
    },
  },
  deadlines: {
    index(options: Options = {}) {
      return httpClient.get(route('events.deadlines.index'), options).json<IResponse<IDeadlineEventResource[]>>();
    },
  },
  clientVisits: {
    index(options: Options = {}) {
      return httpClient.get(route('events.client-visits.index'), options).json<IResponse<IClientVisitResource[]>>();
    },
  },
};
