<script setup lang="ts">
import { ref, reactive, computed, onMounted, provide } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useRouteQuery } from '@vueuse/router';

import useLoader from '@/composables/useLoader';
import useProjectStore from '@/store/ProjectStore';
import {
  AppLoader,
  AppButton,
  FontIcon,
  ProjectStatus,
  HelpInformation,
  ConfirmDialog,
  CancelOrRolloverProjectModal,
} from '@/components';
import api from '@/services/api';
import { ClientTabs, IClientFullResource } from '@/types/Client';
import { IProjectResource, ProjectStep } from '@/types/Project';

import ProjectService from '@/components/Projects/ProjectService.vue';
import ProjectPrice from '@/components/Projects/ProjectPrice.vue';
import ProjectTeam from '@/components/Projects/ProjectTeam.vue';
import ProjectPlanning from '@/components/Projects/ProjectPlanning.vue';
import ProjectActivate from '@/components/Projects/ProjectActivate.vue';
import useProject from '@/composables/useProject';
import { createConfirmDialog } from 'vuejs-confirm-dialog';

const router = useRouter();
const projectStore = useProjectStore();
const { projectSteps } = useProject();
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();

const { setProject, unsetProject, setClientResponsibleUser } = projectStore;
const { project, isDraft, isActive, isCancelled, lockedTabs } = storeToRefs(projectStore);

const props = defineProps<{
  id?: number;
  uuid: string;
}>();

const step = useRouteQuery<ProjectStep>('step', ProjectStep.Service);
const fromTab = useRouteQuery<string>('from', ClientTabs.Overview);
const loaded = ref(false);

const client = reactive<Partial<IClientFullResource>>({
  name: '',
});

const title = computed(() =>
  props.id ? t('project.edit.title', { name: client.name }) : t('project.create.title', { name: client.name }),
);

const editMode = computed(() => !!props.id);

async function getClient() {
  try {
    const response = await api.clients.get(props.uuid);
    client.name = response.data.name;
    setClientResponsibleUser(response.data.responsible_user);
  } catch (error) {
    console.error(error);
  }
}

function reactivateProject(project: IProjectResource) {
  if (!project) {
    return;
  }

  const dialog = createConfirmDialog(ConfirmDialog, {
    title: t('project.reactivate_modal.title', { name: project.name }),
    confirmBtnType: 'success',
    delayTime: 0,
    size: 's',
    confirmCallback: async () => {
      await api.projects.reactivate(props.uuid, project.id);
      await getProject();
    },
  });

  dialog.reveal();
}

function cancelProject(project: IProjectResource) {
  if (!project) {
    return;
  }
  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'cancel',
    clientUuid: props.uuid,
    project: {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProject();
      if (newProjectId) {
        await router.push({ name: 'projects.edit', params: { uuid: props.uuid, id: newProjectId } });
      } else {
        window.location.reload();
      }
    },
  });

  dialog.reveal();
}

function rolloverProject(project: IProjectResource) {
  if (!project) {
    return;
  }
  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'rollover',
    clientUuid: props.uuid,
    project: {
      id: project.id,
      name: project.name,
      has_unfinished_tasks: project.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProject();
      if (newProjectId) {
        await router.push({ name: 'projects.edit', params: { uuid: props.uuid, id: newProjectId } });
      } else {
        window.location.reload();
      }
    },
  });

  dialog.reveal();
}

async function deleteProject(project: IProjectResource) {
  const dialog = createConfirmDialog(ConfirmDialog, {
    title: t('project.confirm.destroy.title'),
    message: t('project.confirm.destroy.text', { name: project.name }),
    confirmBtnType: 'danger',
    delayTime: 0,
    size: 'm',
    confirmCallback: async () => {
      await api.projects.destroy(props.uuid, props.id!);
      await router.push({ name: 'projects.index', params: { uuid: props.uuid } });
    },
  });

  await dialog.reveal();
}

async function getProject() {
  loader.start();
  await getClient();
  if (props.id) {
    try {
      const response = await api.projects.get(props.uuid, props.id);
      setProject(response.data);
    } catch (error) {
      console.error(error);
    }
  } else {
    unsetProject();
  }
  loader.finish();
  loaded.value = true;
}

onMounted(async () => {
  await getProject();
});

provide('projectId', props.id);
provide('clientUuid', props.uuid);
provide('editMode', editMode.value);
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-if="loaded">
    <div class="container-fluid px-md-5">
      <!-- Project header -->
      <div class="mb-4 d-flex d-flex align-items-center flex-nowrap">
        <div class="d-flex align-items-center flex-nowrap">
          <RouterLink
            class="d-inline-flex"
            :to="{
              name: 'clients.edit',
              params: { uuid: props.uuid },
              query: { tab: fromTab },
            }"
            v-tooltip.right="t('common.back')"
          >
            <AppButton ghost color="secondary" light circle>
              <FontIcon name="arrow-back-up" />
            </AppButton>
          </RouterLink>
          <div class="d-flex align-items-end ml-3">
            <h1 class="mb-0" v-text="title" />
            <HelpInformation class="ml-1" :translation="editMode ? 'project.edit.help' : 'project.create.help'" />
          </div>
        </div>
        <template v-if="project">
          <ProjectStatus class="mx-3" :status="project.status" />
          <div class="ml-auto">
            <AppButton
              v-if="isCancelled"
              @click.prevent="reactivateProject(project)"
              class="mr-2"
              color="success"
              light
            >
              {{ t('project.index.reactivate') }}
            </AppButton>
            <AppButton v-if="isActive" @click.prevent="cancelProject(project)" class="mr-2" color="danger" light>
              {{ t('project.index.cancel') }}
            </AppButton>
            <AppButton v-if="isActive" @click.prevent="rolloverProject(project)" class="mr-2" color="secondary" light>
              {{ t('project.index.rollover') }}
            </AppButton>
            <AppButton v-if="isDraft" @click.prevent="deleteProject(project)" color="danger" light>
              {{ t('project.index.remove') }}
            </AppButton>
          </div>
        </template>
      </div>
      <!-- / Project header -->

      <!-- Project tabs -->
      <ul class="tabs">
        <template v-for="(options, name) in projectSteps" :key="name">
          <template v-if="options.visible">
            <li
              class="tab-item"
              :class="{
                active: step === name,
                disabled:
                  lockedTabs ||
                  (!editMode && name !== ProjectStep.Service) ||
                  options.order > projectSteps[project?.step || ProjectStep.Service].order,
              }"
            >
              <a v-if="editMode" class="tab-link" @click.prevent="step = name" href="#">
                <i v-if="options.order > projectSteps[project?.step || ProjectStep.Service].order" class="ti ti-lock" />
                {{ t(`project.step.${name}`) }}
              </a>
              <span v-else class="tab-link">
                <i v-if="name !== ProjectStep.Service" class="ti ti-lock" />
                {{ t(`project.step.${name}`) }}
              </span>
            </li>
          </template>
        </template>
      </ul>
      <!-- / Project tabs -->

      <div class="mt-4">
        <ProjectService v-if="step === ProjectStep.Service" @change-step="(event) => (step = event)" />
        <ProjectPrice v-if="step === ProjectStep.Price" @change-step="(event) => (step = event)" />
        <ProjectTeam v-if="step === ProjectStep.Team" @change-step="(event) => (step = event)" />
        <ProjectPlanning v-if="step === ProjectStep.Planning" @change-step="(event) => (step = event)" />
        <ProjectActivate v-if="step === ProjectStep.Activate" @change-step="(event) => (step = event)" />
      </div>
    </div>
  </template>
</template>
