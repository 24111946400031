<script setup lang="ts">
import { computed, Ref, ref } from 'vue';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';

import useColumnSettings from '@/composables/useColumnSettings';

import { AppButton, ColumnSettings, FontIcon, HelpInformation } from '@/components';
import InternalProjectsList from '@/components/InternalProjects/InternalProjectsList.vue';
import { InternalProjectStatusType } from '@/types/InternalProject';

const { t } = useI18n({ useScope: 'global' });

const { columns, enabledColumns } = useColumnSettings('internal_projects_columns', [
  'date',
  'task',
  'status',
  'frequency',
  'hours',
  'group',
]);

enum PageTab {
  ACTIVE = 'active',
  CLOSED = 'closed',
}
const activeTab: Ref<PageTab> = useRouteQuery('tab', PageTab.ACTIVE);
const tabs = computed(() => [
  { name: PageTab.ACTIVE, title: t('internal-projects.index.tabs.active') },
  { name: PageTab.CLOSED, title: t('internal-projects.index.tabs.closed') },
]);
const lockedTabs = ref(false);
</script>

<template>
  <div class="container-wide">
    <div class="d-flex align-items-center mb-3">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="t('internal-projects.index.title')" />
        <HelpInformation class="ml-1" translation="internal-projects.index.help" />
      </div>
      <Dropdown class="ml-auto" placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`internal-projects.table.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <ul class="tabs">
      <li
        :class="{ active: tab.name === activeTab, disabled: lockedTabs }"
        v-for="tab in tabs"
        :key="tab.name"
        class="tab-item"
      >
        <a class="tab-link" @click.prevent="activeTab = tab.name" href="#" v-text="tab.title" />
      </li>
    </ul>
    <div class="mt-4">
      <InternalProjectsList
        v-if="activeTab === PageTab.ACTIVE"
        :id="PageTab.ACTIVE"
        :filters="{ statuses: [InternalProjectStatusType.DRAFT, InternalProjectStatusType.ACTIVE] }"
        :title="t('internal-projects.index.active_events')"
        :columns="enabledColumns"
        creatable
      />
      <InternalProjectsList
        v-else-if="activeTab === PageTab.CLOSED"
        :id="PageTab.CLOSED"
        :title="t('internal-projects.index.closed_events')"
        :description="t('internal-projects.index.closed_events_description')"
        :filters="{ statuses: [InternalProjectStatusType.CLOSED] }"
        :columns="enabledColumns"
      />
    </div>
  </div>
</template>
