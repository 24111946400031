<script setup lang="ts">
import { computed, nextTick, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal, VueFinalModal } from 'vue-final-modal';
import VueSelect, { VueSelectInstance } from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import { nanoid } from 'nanoid';
import { findKey, get, has } from 'lodash';
import { DateTime } from 'luxon';

import api from '@/services/api';
import progress from '@/services/progress';
import {
  AppAlert,
  AppButton,
  AppCloseButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  DeleteEventModal,
  EventMileageAllowance,
  FontIcon,
  FormLabel,
  FormMinutesDuration,
  FormSwitch,
  FormTextarea,
  SplitEventModal,
  TimeEntryModalSplitEvents,
} from '@/components';
import useDate from '@/composables/useDate';
import useTime from '@/composables/useTime';
import useLoader from '@/composables/useLoader';
import useModalFeatures from '@/composables/useModalFeatures';
import useTrackChanges from '@/composables/useTrackChanges';
import useAuthStore from '@/store/AuthStore';
import useTask from '@/composables/useTask';
import {
  ActivityCommentType,
  ActivityDurationRule,
  ActivityGroupType,
  ActivityModalProps,
  IActivityGroupListResource,
  ICreateActivityRequest,
  IActivityResource,
  IActivityTemplateListResource,
  IActivityTemplateResource,
} from '@/types/Activity';
import {
  EventType,
  IEventTrackedTimeRequest,
  ISplitEventRequestBody,
  ITimeEntryActivityEventResource,
  ITimeEntryActivityEventRequest,
  ITimeEntryProjectTaskEventRequest,
} from '@/types/Event';
import { IUserListResource, IUserPreviewResource, IUserWorkingDays, IVehicleResource } from '@/types/User';
import { IInternalProjectResource, InternalProjectStatusType } from '@/types/InternalProject';
import { ITimePeriodAvailableDates } from '@/types/TimePeriod';
import { Decimal } from 'decimal.js';
import { ISalarySettingsResource } from '@/types/Salary';

type GetWorkingDayDuration = {
  (date: string | null, percentage: false): number;
  (date: string | null, percentage: true): [string, number][];
};

type ActivityGroupOption = {
  id: number;
  name: string;
  type: 'group' | 'internal_project';
};

const props = defineProps<ActivityModalProps>();

const emit = defineEmits<{
  close: [];
  closed: [];
  split: [];
  created: [event: IActivityResource];
  updated: [event: ITimeEntryActivityEventResource];
  deleted: [];
}>();

const activityEvent = ref<null | ITimeEntryActivityEventResource>(null);

type Form = Omit<ICreateActivityRequest, 'mileage_allowance'> &
  Pick<ITimeEntryActivityEventRequest, 'change_future_scheduled_time'> & {
    mileage_allowance: {
      vehicle_id: null | number;
      mileage: number;
      amount: number;
    };
    show_mileage: boolean;
  };

const form = reactive<Form>({
  start_date: DateTime.now().startOf('week').toISODate(),
  end_date: null,
  activity_template_id: null,
  internal_project_id: null,
  user_uuid: null,
  note: null,
  scheduled_time: 0,
  frequency: null,
  is_done: false,
  meta: [],
  tracked_time: [],
  change_future_scheduled_time: false,
  mileage_allowance: {
    vehicle_id: null,
    amount: 0,
    mileage: 0,
  },
  show_mileage: false,
});

const tracker = useTrackChanges(form);
const { t, d, locale } = useI18n({ useScope: 'global' });
const { convertWeekNumberToDates, getWeekNumber, getWeekDates } = useDate();
const { convertMinutesToTime } = useTime();
const { onCtrlEnter } = useModalFeatures();
const { taskFrequencyOptions } = useTask();
const loader = useLoader();
const submitLoader = useLoader();
const splitLoader = useLoader();
const { authenticatedUser } = useAuthStore();

// Node refs
const activityTemplateNodeRef = ref<VueSelectInstance | null>(null);
const activityGroupNodeRef = ref<VueSelectInstance | null>(null);

// Activity Types/Groups/Templates
const activityGroup = ref<null | ActivityGroupOption>(null);
const activityTemplates = ref<IActivityTemplateListResource[]>([]);
const activityTemplatesLoading = ref(false);
const activityTemplateGroupId = ref<null | number>(null);
const activityTemplate = ref<null | IActivityTemplateResource>(null);
const activityTemplateLoading = ref(false);

async function getActivityTemplates(type?: ActivityGroupType) {
  try {
    activityTemplatesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    if (type) {
      searchParams.append('group_type', type);
    }
    const response = await api.activityTemplates.list({ searchParams });
    activityTemplates.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    activityTemplatesLoading.value = false;
  }
}

async function getActivityTemplate() {
  let groupId: number | undefined;
  let templateId: number | undefined;

  if (isEditing.value) {
    if (!activityEvent.value) return;
    groupId = activityEvent.value.template.activity_group.id;
    templateId = activityEvent.value.template.id;
  } else {
    if (!form.activity_template_id || !activityTemplateGroupId.value) return;
    groupId = activityTemplateGroupId.value;
    templateId = form.activity_template_id;
  }

  if (!groupId || !templateId) return;

  try {
    activityTemplateLoading.value = true;
    const response = await api.activityTemplates.get(groupId, templateId);
    activityTemplate.value = response.data;
    if (!activityTemplates.value.find((template) => template.id === activityTemplate.value?.id)) {
      activityTemplates.value = [...activityTemplates.value, { ...(activityTemplate.value ?? {}) }];
    }
  } catch (error) {
    console.error(error);
  } finally {
    activityTemplateLoading.value = false;
  }
}

//  Work duration
const workingDaysLoading = ref(false);
const workingDays = ref<null | IUserWorkingDays>(null);

// Users
const users = ref<(IUserListResource | IUserPreviewResource)[]>([]);
const usersLoading = ref(false);

async function getUsers() {
  try {
    usersLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', 'active');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    usersLoading.value = false;
  }
}

// Vehicles
const vehicles = ref<IVehicleResource[]>([]);
const vehiclesLoading = ref(false);

async function getVehicles(userUuid: string) {
  try {
    vehiclesLoading.value = true;
    const response = await api.users.vehicles.index(userUuid);
    vehicles.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    vehiclesLoading.value = false;
  }
}

// Salary settings
const salarySettings = ref<null | ISalarySettingsResource>(null);

async function getSalarySettings() {
  try {
    const response = await api.salary.settings.index();
    salarySettings.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

// Time period available dates
const timePeriodAvailableDatesLoading = ref(false);
const timePeriodAvailableDates = ref<ITimePeriodAvailableDates | null>(null);

async function getTimePeriodAvailableDates() {
  try {
    timePeriodAvailableDatesLoading.value = true;
    timePeriodAvailableDates.value = await api.timePeriods.availableDates();
  } catch (error) {
    console.error(error);
  } finally {
    timePeriodAvailableDatesLoading.value = false;
  }
}

const minStartDate = computed(() => {
  if (timePeriodAvailableDates.value) {
    return DateTime.fromISO(timePeriodAvailableDates.value.start_date).toJSDate();
  }
  return '';
});

const maxStartDate = computed(() => {
  if (form.end_date) {
    return DateTime.fromISO(form.end_date).startOf('week').minus({ days: 1 }).toJSDate();
  }
  return '';
});

const minEndDate = computed(() => {
  if (form.start_date) {
    return DateTime.fromISO(form.start_date).endOf('week').plus({ days: 1 }).toJSDate();
  }
  return '';
});

const weekDays = computed(() => {
  const weekSource = activityEvent.value ? activityEvent.value.week : getWeekNumber(form.start_date as string);
  return weekSource ? getWeekDates(weekSource) : [];
});

function onTrackTimeDateChange(item: IEventTrackedTimeRequest) {
  if (isRuleOnlyDay.value) {
    item.time = getWorkingDayDurationByPercentage(item.date, '100');
  }
}

const totalTrackedMinutes = computed(() =>
  form.tracked_time.reduce((total, item) => {
    return total + item.time;
  }, 0),
);

const overtimeMinutes = computed(() => totalTrackedMinutes.value - form.scheduled_time);

const isEditing = computed(() => !!props.id);

const isAbsence = computed(() => {
  if (isEditing.value) {
    return activityEvent.value?.template.activity_type === ActivityGroupType.ABSENCE;
  }
  return props.type === 'absence';
});

const isInternal = computed(() => {
  if (isEditing.value) {
    if (activityEvent.value === null) return false;
    return activityEvent.value?.template.activity_type === ActivityGroupType.INTERNAL_WORK;
  }
  return props.type === 'internal';
});

const isRuleNone = computed(() => activityTemplate.value?.duration_rule === null);
const isRuleAllDay = computed(() => activityTemplate.value?.duration_rule === ActivityDurationRule.AVAILABLE_ALL_DAY);
const isRulePercentageRange = computed(
  () => activityTemplate.value?.duration_rule === ActivityDurationRule.AVAILABLE_TIME_PERCENTAGE_RANGE,
);
const isRuleOnlyDay = computed(() => activityTemplate.value?.duration_rule === ActivityDurationRule.ONLY_ALL_DAY);

const deletable = computed(() => {
  return !(
    activityEvent.value === null ||
    activityEvent.value.done_at ||
    activityEvent.value.internal_project ||
    hasSavedTrackedTime.value
  );
});

const activityAbortController = ref<null | AbortController>(null);

const hasSavedTrackedTime = computed(() => form.tracked_time.some((item) => !!item.id));

const splitDisabled = computed(() => disabled.value || usersLoading.value);

const disabled = computed(() => {
  return (
    !form.activity_template_id ||
    !form.start_date ||
    !form.user_uuid ||
    form.meta.some((item) => item.is_required && !item.value) ||
    (form.tracked_time.length > 0 && form.tracked_time.some((item) => !item.date))
  );
});

const activityGroupsOptions = computed<ActivityGroupOption[]>(() => {
  const options1: ActivityGroupOption[] = activityGroups.value.map((item) => ({
    id: item.id,
    name: item.name,
    type: 'group',
  }));
  const options2: ActivityGroupOption[] = internalProjects.value.map((item) => ({
    id: item.id,
    name: item.name,
    type: 'internal_project',
  }));
  return [...options1, ...options2];
});

const activityTemplateOptions = computed(() => {
  if (isAbsence.value || isEditing.value) {
    return activityTemplates.value;
  }
  if (activityGroup.value?.type === 'group') {
    return activityTemplates.value.filter((template) => template.activity_group.id === activityGroup.value?.id);
  } else if (activityGroup.value?.type === 'internal_project') {
    const internalProject = internalProjects.value.find((item) => item.id === activityGroup.value?.id);
    if (!internalProject) return [];
    return activityTemplates.value.filter(
      (template) => template.activity_group.id === internalProject.activity_group.id,
    );
  }
  return [];
});

const deleteModal = useModal({
  component: DeleteEventModal,
  attrs: {
    type: EventType.Activity,
    async onConfirm(withFutureEvents: boolean) {
      if (!activityEvent.value || !props.id) return;
      try {
        if (withFutureEvents) {
          // @ts-ignore
          deleteModal.patchOptions({ attrs: { loadingWithFutureEvents: true } });
          await api.activities.destroy(activityEvent.value.activity_id);
        } else {
          // @ts-ignore
          deleteModal.patchOptions({ attrs: { loading: true } });
          await api.events.destroy(props.id);
        }
        emit('deleted');
        await deleteModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        deleteModal.patchOptions({ attrs: { loadingWithFutureEvents: false, loading: false } });
      }
    },
    // @ts-ignore
    onCancel() {
      deleteModal.close();
    },
    // @ts-ignore
    onClosed() {
      // @ts-ignore
      deleteModal.patchOptions({ attrs: { loadingWithFutureEvents: false, loading: false } });
    },
  },
});

function onDelete() {
  if (!activityEvent.value) return;
  // @ts-ignore
  deleteModal.patchOptions({ attrs: { hasEvents: activityEvent.value.frequency !== null } });
  deleteModal.open();
}

const cancelModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('time-entry.confirm.cancel.title'),
    message: t('time-entry.confirm.cancel.text'),
    onConfirm() {
      emit('close');
      cancelModal.close();
    },
    onCancel() {
      cancelModal.close();
    },
  },
});

const splitModal = useModal({
  component: SplitEventModal,
  attrs: {
    async onConfirm(form: ISplitEventRequestBody) {
      if (!props.id) return;
      try {
        // @ts-ignore
        splitModal.patchOptions({ attrs: { loading: true } });
        await api.events.split(props.id, form);
        emit('split');
        await splitModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        splitModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      splitModal.close();
    },
    // @ts-ignore
    onClosed() {
      // @ts-ignore
      splitModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

function convertFormDataToEventUpdateRequest(
  activity: ITimeEntryActivityEventResource,
): ITimeEntryActivityEventRequest {
  const isInternalProject = activity.internal_project;

  return {
    note: form.note,
    user_uuid: form.user_uuid,
    is_done: form.is_done,
    default_frequency: null,
    default_time_budget: form.scheduled_time,
    change_future_responsible: false,
    change_future_scheduled_time: form.change_future_scheduled_time,
    change_future_scheduled_time_to_actual: false,
    task_week: activity.week,
    tracked_time: form.tracked_time,
    additional_fields: form.meta.map((item) => ({
      id: item.id,
      value: item.value,
    })),
    activity: {
      start_date: isInternalProject ? undefined : form.start_date,
      end_date: isInternalProject ? undefined : form.end_date,
      activity_template_id: isInternalProject ? undefined : form.activity_template_id,
      internal_project_id: isInternalProject ? undefined : form.internal_project_id,
      frequency: isInternalProject ? undefined : form.frequency,
    },
    mileage_allowance: form.show_mileage ? form.mileage_allowance : null,
  };
}

async function submit() {
  try {
    submitLoader.start();
    if (isEditing.value && activityEvent.value) {
      const response = await api.events.activities.update(
        activityEvent.value.id,
        convertFormDataToEventUpdateRequest(activityEvent.value),
      );
      emit('updated', response.data);
    } else {
      const response = await api.activities.store({
        start_date: form.start_date,
        end_date: form.end_date,
        activity_template_id: form.activity_template_id,
        internal_project_id: form.internal_project_id,
        user_uuid: form.user_uuid,
        frequency: form.frequency,
        meta: form.meta,
        is_done: form.is_done,
        note: form.note,
        scheduled_time: form.scheduled_time,
        tracked_time: form.tracked_time,
        mileage_allowance: form.show_mileage ? form.mileage_allowance : null,
      });
      emit('created', response.data);
    }
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

function addTrackedTimeItem() {
  const today = DateTime.now().toFormat('yyyy-MM-dd');
  const trackedTime: IEventTrackedTimeRequest = {
    uid: nanoid(),
    date: weekDays.value.includes(today) ? today : '',
    time: 0,
    note: '',
    is_locked: false,
  };

  // If today earlier than start_date, then set today date
  // if (form.start_date && DateTime.now() > DateTime.fromISO(form.start_date)) {
  //   trackedTime.date = DateTime.now().toFormat('yyyy-MM-dd');
  // }
  // Set date in null if the date is disabled
  // if (trackedTime.date && disabledTrackedDate.value.includes(trackedTime.date)) {
  //   trackedTime.date = null;
  // }
  // If rule is only day then set time in max value
  if (trackedTime.date && isRuleOnlyDay.value) {
    trackedTime.time = getWorkingDayDurationByPercentage(trackedTime.date, '100');
  }
  if ((isRuleNone.value || isRuleAllDay.value || isRulePercentageRange.value) && trackedTime.date) {
    trackedTime.time = Math.min(
      form.scheduled_time - totalTrackedMinutes.value,
      getWorkingDayDuration(trackedTime.date, false),
    );
  }
  if (trackedTime.time < 0) {
    trackedTime.time = 0;
  }
  form.tracked_time.push(trackedTime);
}

function removeTackedTimeItem(uid: string) {
  form.tracked_time = form.tracked_time.filter((item) => item.uid !== uid);
}

async function onSplitEvent() {
  if (!form.start_date || !activityEvent.value) return;
  try {
    splitLoader.start();

    await api.events.activities.update(
      activityEvent.value.id,
      convertFormDataToEventUpdateRequest(activityEvent.value),
    );

    const times = {
      initialTime: Math.max(0, form.scheduled_time - totalTrackedMinutes.value),
      maxTime: Math.max(0, form.scheduled_time - totalTrackedMinutes.value),
    };

    const week = getWeekNumber(form.start_date);
    splitModal.patchOptions({
      attrs: {
        modalMode: EventType.Activity,
        users: users.value,
        ...times,
        initialWeek: Number.parseInt(DateTime.now().plus({ days: 7 }).toFormat('kkkkWW')),
        minWeek: week,
        rule: activityEvent.value.template.duration_rule,
      },
    });
    await splitModal.open();
  } catch (error) {
    console.error(error);
  } finally {
    splitLoader.finish();
  }
}

function onStartWeekChange(event: [Date, Date] | null) {
  form.start_date = event ? DateTime.fromJSDate(event[0]).toFormat('yyyy-MM-dd') : null;
  if (!isEditing.value) {
    form.tracked_time.forEach((item) => {
      // if (form.start_date && item.date && DateTime.fromISO(item.date) < DateTime.fromISO(form.start_date)) {
      //   item.date = null;
      // }
      item.date = '';
    });
  }
}

function onEndWeekChange(event: [Date, Date] | null) {
  form.end_date = event ? DateTime.fromJSDate(event[1]).toFormat('yyyy-MM-dd') : null;
  form.tracked_time.forEach((item) => {
    if (form.end_date && item.date && DateTime.fromISO(item.date) > DateTime.fromISO(form.end_date)) {
      item.date = '';
    }
  });
}

async function activityTemplateChange() {
  if (form.activity_template_id === null) {
    form.meta = [];
    return;
  }
  const template = activityTemplates.value.find(({ id }) => id === form.activity_template_id);
  if (!template) return;
  activityTemplateGroupId.value = template.activity_group.id;
  progress.start();
  await getActivityTemplate();
  if (activityTemplate.value) {
    form.scheduled_time = 0;
    form.frequency = activityTemplate.value.default_frequency;
    form.meta = activityTemplate.value.meta_input.map((item) => ({ ...item, value: '' }));
    form.tracked_time = [];
  }
  progress.done();
}

// @ts-ignore
const getWorkingDayDuration: GetWorkingDayDuration = (date, isPercentageble) => {
  if (!workingDays.value || date === null) return isPercentageble ? [] : 0;
  const weekNumber = findKey(workingDays.value, (o) => has(o.days, date));
  if (!weekNumber) return isPercentageble ? [] : 0;
  if (isPercentageble) {
    const options = get(workingDays.value, [weekNumber, 'days', date], []);
    return Object.entries(options).sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]));
  }
  return get(workingDays.value, [weekNumber, 'days', date, '100'], 0);
};

const getWorkingDayDurationByPercentage = (date: string | null, percentage: string): number => {
  if (!workingDays.value || !date || !percentage) return 0;
  const weekNumber = findKey(workingDays.value, (o) => has(o.days, date));
  if (!weekNumber) return 0;
  return get(workingDays.value, [weekNumber, 'days', date, percentage], 0);
};

function onCancel() {
  if (tracker.isModified.value) {
    cancelModal.open();
  } else {
    emit('close');
  }
}

onCtrlEnter(() => {
  if (!disabled.value) {
    if (isEditing.value) {
      if (tracker.isModified.value) {
        submit();
      } else {
        emit('close');
      }
    } else {
      submit();
    }
  }
});

async function getUserWorkingDays() {
  if (!form.user_uuid || !form.start_date) return;
  progress.start();
  const weeks = isEditing.value && activityEvent.value ? [activityEvent.value.week] : [getWeekNumber(form.start_date)];
  try {
    workingDaysLoading.value = true;
    const searchParams = new URLSearchParams();
    weeks.forEach((week) => {
      searchParams.append('weeks[]', week.toString());
    });
    workingDays.value = await api.users.workingDays(form.user_uuid, { searchParams });
  } catch (error) {
    console.error(error);
  } finally {
    workingDaysLoading.value = false;
    progress.done();
  }
}

const internalProjects = ref<IInternalProjectResource[]>([]);
const internalProjectsLoading = ref(false);

async function getInternalProjects() {
  try {
    internalProjectsLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', InternalProjectStatusType.ACTIVE);
    const response = await api.internalProjects.index({ searchParams });
    internalProjects.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    internalProjectsLoading.value = false;
  }
}

const activityGroups = ref<IActivityGroupListResource[]>([]);
const activityGroupsLoading = ref(false);

async function getActivityGroups() {
  try {
    activityGroupsLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('type', ActivityGroupType.INTERNAL_WORK);
    const response = await api.activityGroups.index({ searchParams });
    activityGroups.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    activityGroupsLoading.value = false;
  }
}

async function onActivityGroupSelected() {
  if (!isEditing.value) {
    await nextTick();
    activityTemplateNodeRef.value?.searchEl.focus();
  }
}

onMounted(async () => {
  loader.start();
  await Promise.all([getUsers(), getTimePeriodAvailableDates(), getSalarySettings()]);
  if (props.id) {
    // Edit mode
    try {
      activityAbortController.value = new AbortController();
      const response = await api.events.activities.get(props.id, {
        signal: activityAbortController.value.signal,
      });
      activityEvent.value = response.data;
      activityTemplates.value.push(response.data.template);
      // Set form
      form.start_date = DateTime.fromISO(response.data.start_date).toFormat('yyyy-MM-dd');
      form.end_date = response.data.end_date ? DateTime.fromISO(response.data.end_date).toFormat('yyyy-MM-dd') : null;
      form.is_done = !!response.data.done_at;
      form.frequency = response.data.frequency;
      form.user_uuid = response.data.user.uuid;
      form.activity_template_id = response.data.template.id;
      form.internal_project_id = response.data.internal_project?.id ?? null;
      form.note = response.data.note;
      form.tracked_time = response.data.tracked_time.map((item) => ({
        uid: nanoid(),
        id: item.id,
        date: item.date,
        time: item.time,
        note: item.note ?? '',
        is_locked: item.is_locked,
      }));
      form.scheduled_time = response.data.scheduled_time;
      form.meta = response.data.template.meta_input.map((item) => ({
        ...item,
        value: response.data.additional_fields?.find(({ id }) => id === item.id)?.value ?? '',
      }));
      form.mileage_allowance = {
        vehicle_id: response.data.mileage_allowance?.vehicle_id ?? null,
        mileage: new Decimal(response.data.mileage_allowance?.mileage ?? 0).toDecimalPlaces(2).toNumber(),
        amount: new Decimal(response.data.mileage_allowance?.amount ?? 0).toDecimalPlaces(2).toNumber(),
      };
      form.show_mileage = response.data.mileage_allowance !== null;
    } catch (error) {
      console.error(error);
    }
    await getActivityTemplates();
    await getActivityTemplate();
  } else {
    // Create mode
    if (props.type === 'absence') {
      await getActivityTemplates(ActivityGroupType.ABSENCE);
    } else if (props.type === 'internal') {
      await Promise.all([getActivityTemplates(), getActivityGroups(), getInternalProjects()]);
    }

    // Set optional initial week
    if (props.initialWeek) {
      const [startDate] = convertWeekNumberToDates(props.initialWeek);
      form.start_date = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd');
    }

    // Set user
    form.user_uuid = authenticatedUser.uuid;
  }

  await Promise.all([getUserWorkingDays(), getVehicles(authenticatedUser.uuid)]);

  loader.finish();

  // Set vehicle
  if (form.mileage_allowance.vehicle_id === null && vehicles.value.length === 1) {
    form.mileage_allowance.vehicle_id = vehicles.value[0].id;
  }

  // Set watchers
  watch([() => form.user_uuid, () => form.start_date], getUserWorkingDays);
  watch([() => form.activity_template_id], activityTemplateChange);

  // Set internal_project_id if group is internal project
  watch(activityGroup, () => {
    form.internal_project_id = activityGroup.value?.type === 'internal_project' ? activityGroup.value.id : null;
    form.activity_template_id = null;
  });

  // Reset end_date if frequency is null (once)
  watch(
    () => form.frequency,
    () => {
      if (form.frequency === null) {
        form.end_date = null;
      }
    },
  );

  // Reset dates in tracked time which greater that end_date
  watch(
    () => form.end_date,
    () => {
      if (form.end_date) {
        form.tracked_time.forEach((item) => {
          if (item.date && DateTime.fromISO(item.date) > DateTime.fromISO(form.end_date!)) {
            item.date = null;
          }
        });
      }
    },
  );

  // Focus on first field
  await nextTick();
  if (!isEditing.value) {
    if (isInternal.value) {
      activityGroupNodeRef.value?.searchEl.focus();
    }
    if (isAbsence.value) {
      activityTemplateNodeRef.value?.searchEl.focus();
    }
  }
  tracker.commit();
});

onUnmounted(() => {
  activityAbortController.value?.abort('Abort fetching activity.');
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container is-huge"
    :click-to-close="false"
    :esc-to-close="false"
    @closed="emit('closed')"
    v-slot="{ close }"
  >
    <form @submit.prevent="submit" @keydown.enter.prevent>
      <div v-if="loader.isNotLoading.value" class="modal-header">
        <h2>
          <span
            v-if="isEditing"
            v-text="t('time-entry.modal.activity.edit_title', { name: activityEvent?.activity_name })"
          />
          <span v-else v-text="t('time-entry.modal.activity.create_title')" />
        </h2>
        <AppCloseButton @close="onCancel" />
      </div>
      <div class="modal-content">
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="large" />
        </div>
        <div v-else class="row">
          <div class="col-5">
            <h3 v-text="t('time-entry.modal.internal_block_title')" />

            <!-- Activity group -->
            <div v-if="isInternal && !isEditing" class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="activity_group" required>
                    {{ t('time-entry.modal.attributes.activity_group') }}
                  </FormLabel>
                </div>
                <div class="col-md-8 activity-select">
                  <VueSelect
                    ref="activityGroupNodeRef"
                    :clearable="false"
                    v-model="activityGroup"
                    input-id="activity_group"
                    :placeholder="t('common.select')"
                    :options="activityGroupsOptions"
                    label="name"
                    :disabled="form.is_done || isEditing"
                    @option:selected="onActivityGroupSelected"
                  />
                </div>
              </div>
            </div>

            <!-- Activity template -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="activity_template" required>
                    {{ t('time-entry.modal.attributes.activity') }}
                  </FormLabel>
                </div>
                <div class="col-md-8 activity-select">
                  <VueSelect
                    ref="activityTemplateNodeRef"
                    :clearable="false"
                    v-model="form.activity_template_id"
                    :reduce="(option: IActivityTemplateListResource) => option.id"
                    :options="activityTemplateOptions"
                    label="name"
                    input-id="activity_template"
                    :placeholder="t('common.select')"
                    :loading="activityTemplatesLoading || activityTemplateLoading"
                    :disabled="(isInternal && !activityGroup) || isEditing || activityTemplatesLoading || form.is_done"
                  />
                </div>
              </div>
            </div>

            <!-- Frequency -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="frequency" required>
                    {{ t('time-entry.modal.attributes.frequency') }}
                  </FormLabel>
                </div>
                <div class="col-md-8">
                  <VueSelect
                    :clearable="false"
                    v-model="form.frequency"
                    :options="taskFrequencyOptions"
                    input-id="frequency"
                    :placeholder="t('common.select')"
                    label="label"
                    :reduce="(option: any) => option.value"
                    :disabled="form.is_done || isEditing"
                  />
                </div>
              </div>
            </div>

            <!-- Week -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="time_week" required>
                    {{ t('time-entry.modal.attributes.week') }}
                  </FormLabel>
                </div>
                <div :class="[form.frequency ? 'col-md-4' : 'col-md-8']">
                  <div class="form-wrapper has-icon d-flex align-items-center">
                    <VueDatePicker
                      input-class-name="form-control"
                      :placeholder="t('common.select')"
                      :model-value="
                        form.start_date
                          ? [
                              DateTime.fromISO(form.start_date).startOf('week').toJSDate(),
                              DateTime.fromISO(form.start_date).endOf('week').toJSDate(),
                            ]
                          : null
                      "
                      @update:model-value="onStartWeekChange"
                      :enable-time-picker="false"
                      :month-change-on-scroll="false"
                      required
                      week-picker
                      week-numbers="iso"
                      format="RR-ww"
                      auto-apply
                      :disabled="form.is_done || (isEditing && !!form.internal_project_id)"
                      :min-date="minStartDate"
                      :max-date="maxStartDate"
                      :locale="locale"
                      :week-num-name="t('common.week_short')"
                      position="left"
                      :clearable="false"
                      teleport-center
                    >
                      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                    </VueDatePicker>
                  </div>
                </div>
                <div v-if="form.frequency" class="col-md-4">
                  <div class="form-wrapper has-icon d-flex align-items-center">
                    <VueDatePicker
                      input-class-name="form-control"
                      :placeholder="t('time-entry.modal.attributes.end_week')"
                      :model-value="
                        form.end_date
                          ? [
                              DateTime.fromISO(form.end_date).startOf('week').toJSDate(),
                              DateTime.fromISO(form.end_date).endOf('week').toJSDate(),
                            ]
                          : null
                      "
                      @update:model-value="onEndWeekChange"
                      :enable-time-picker="false"
                      :month-change-on-scroll="false"
                      week-picker
                      week-numbers="iso"
                      format="RR-ww"
                      auto-apply
                      :disabled="form.is_done || (isEditing && !!form.internal_project_id)"
                      :min-date="minEndDate"
                      :locale="locale"
                      :week-num-name="t('common.week_short')"
                      position="right"
                      clearable
                      teleport-center
                    >
                      <template #input-icon><i class="form-icon ti ti-calendar" /></template>
                    </VueDatePicker>
                  </div>
                </div>
              </div>
            </div>

            <!-- Note -->
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-md-4">
                  <FormLabel html-for="task_notes">
                    {{ t('time-entry.modal.attributes.activity_notes') }}
                  </FormLabel>
                </div>
                <div class="col-md-8">
                  <FormTextarea rows="4" id="task_notes" v-model="form.note" :disabled="form.is_done" />
                </div>
              </div>
            </div>

            <!-- Mileage allowance -->
            <EventMileageAllowance
              v-if="isInternal && form.mileage_allowance"
              :vehicles="vehicles"
              :mileage-allowance-amount="salarySettings?.mileage_allowance_amount ?? 0"
              :disabled="form.is_done"
              :vehicles-loading="vehiclesLoading"
              v-model:visibility="form.show_mileage"
              v-model:vehicleId="form.mileage_allowance.vehicle_id"
              v-model:mileage="form.mileage_allowance.mileage"
              v-model:amount="form.mileage_allowance.amount"
            />

            <!-- Meta -->
            <div v-if="form.meta.length > 0">
              <h3 v-text="t('time-entry.modal.additional_info_title')" />
              <div v-for="item in form.meta" :key="item.id" class="form-group">
                <FormLabel :html-for="item.id" :required="item.is_required">
                  {{ item.label }}
                </FormLabel>
                <div class="form-wrapper">
                  <input
                    :id="item.id"
                    class="form-control"
                    v-model.trim="item.value"
                    :required="item.is_required"
                    :disabled="form.is_done"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-7">
            <!-- Time planned block -->
            <div>
              <h3 v-text="t('time-entry.modal.time_planned_block_title')" />

              <!-- Employee -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="user_uuid" required>
                      {{ t('time-entry.modal.attributes.employee') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <VueSelect
                      :clearable="false"
                      v-model="form.user_uuid"
                      :options="users"
                      label="name"
                      input-id="user_uuid"
                      :placeholder="t('common.select')"
                      :loading="usersLoading"
                      disabled
                      :reduce="(option: IUserListResource) => option.uuid"
                    />
                  </div>
                </div>
              </div>

              <!-- Estimated time -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="estimated_time" required>
                      {{ t('time-entry.modal.attributes.estimated_time') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormMinutesDuration :max="10080" :disabled="form.is_done" v-model="form.scheduled_time" />
                  </div>
                </div>
              </div>

              <!-- Change future scheduled time -->
              <div v-if="isEditing && tracker.isFieldModified(['scheduled_time'])" class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="change_future_scheduled_time" required>
                      {{ t('time-entry.modal.attributes.change_future_scheduled_time') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormSwitch
                      group-class="mb-0"
                      id="change_future_scheduled_time"
                      v-model="form.change_future_scheduled_time"
                    />
                  </div>
                </div>
              </div>

              <!-- Total time -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel>
                      {{ t('time-entry.modal.attributes.total_time') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <span v-text="convertMinutesToTime(totalTrackedMinutes)" />
                    <strong class="text-danger-500 pl-1" v-if="totalTrackedMinutes > form.scheduled_time">
                      (+{{ convertMinutesToTime(overtimeMinutes) }})
                    </strong>
                  </div>
                </div>
              </div>
              <!-- Done -->
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-md-4">
                    <FormLabel html-for="done">
                      {{ t('time-entry.modal.attributes.done') }}
                    </FormLabel>
                  </div>
                  <div class="col-md-8">
                    <FormSwitch group-class="mb-0" id="done" v-model="form.is_done" />
                  </div>
                </div>
              </div>
            </div>

            <!-- Time entries block -->
            <div>
              <h3 v-text="t('time-entry.modal.time_entries_block_title')" />
              <div v-if="workingDaysLoading" class="text-center">
                <AppLoader />
              </div>
              <template v-else>
                <AppTable v-if="form.tracked_time.length" hoverable>
                  <AppTableHead>
                    <AppTableTr>
                      <AppTableTh class="pl-0" nowrap width="25%">
                        {{ t('time-entry.modal.attributes.date') }}
                        <span class="text-danger-500">*</span>
                      </AppTableTh>
                      <AppTableTh nowrap width="25%">
                        {{ t('time-entry.modal.attributes.time') }}
                        <span class="text-danger-500">*</span>
                      </AppTableTh>
                      <AppTableTh nowrap width="45%">
                        {{ t('time-entry.modal.attributes.comment') }}
                        <span
                          v-if="activityTemplate?.comment_type === ActivityCommentType.DROPDOWN"
                          class="text-danger-500"
                          v-text="'*'"
                        />
                      </AppTableTh>
                      <AppTableTh width="5%" class="text-right pr-0" nowrap>
                        {{ t('time-entry.modal.attributes.actions') }}
                      </AppTableTh>
                    </AppTableTr>
                  </AppTableHead>
                  <AppTableBody>
                    <AppTableTr v-for="item in form.tracked_time" :key="item.uid">
                      <AppTableTd class="pl-0">
                        <div class="form-wrapper">
                          <select
                            class="form-control"
                            v-model="item.date"
                            :disabled="form.is_done || item.is_locked"
                            @change="onTrackTimeDateChange(item)"
                            required
                          >
                            <option disabled hidden value="" v-text="t('common.select')" />
                            <option
                              v-for="date in weekDays"
                              :key="date"
                              :value="date"
                              v-text="d(new Date(date), 'weekday')"
                              :disabled="isAbsence ? false : DateTime.fromISO(date) > DateTime.now()"
                            />
                          </select>
                        </div>
                      </AppTableTd>
                      <AppTableTd>
                        <FormMinutesDuration
                          :max="1440"
                          :disabled="!item.date || form.is_done || item.is_locked"
                          v-model="item.time"
                        />
                      </AppTableTd>
                      <AppTableTd>
                        <div class="form-wrapper">
                          <input
                            v-if="activityTemplate?.comment_type === ActivityCommentType.FREE_TEXT"
                            class="form-control"
                            v-model.trim="item.note"
                            :disabled="form.is_done || item.is_locked"
                          />
                          <select
                            v-if="activityTemplate?.comment_type === ActivityCommentType.DROPDOWN"
                            class="form-control"
                            v-model="item.note"
                            :disabled="form.is_done || item.is_locked"
                            required
                          >
                            <option disabled hidden :value="null" v-text="t('common.select')" />
                            <option
                              v-for="option in activityTemplate?.comment_options"
                              :key="option.id"
                              :value="option.option"
                              v-text="option.option"
                            />
                          </select>
                        </div>
                      </AppTableTd>
                      <AppTableTd nowrap class="pr-0 text-right">
                        <AppButton
                          @click.prevent="removeTackedTimeItem(item.uid)"
                          color="danger"
                          circle
                          light
                          :disabled="form.is_done || item.is_locked"
                          v-tooltip.left="t('common.delete')"
                        >
                          <FontIcon name="trash" />
                        </AppButton>
                      </AppTableTd>
                    </AppTableTr>
                  </AppTableBody>
                </AppTable>
                <AppAlert v-else>{{ t('common.empty') }}</AppAlert>
                <AppButton
                  @click.prevent="addTrackedTimeItem"
                  class="mt-2"
                  color="success"
                  circle
                  light
                  :disabled="form.is_done || !workingDays || !activityTemplate"
                  v-tooltip.right="t('common.add_time')"
                >
                  <FontIcon name="plus" />
                </AppButton>
              </template>
            </div>

            <!-- Split events -->
            <TimeEntryModalSplitEvents
              class="mt-4"
              :split-events="activityEvent?.split_events ?? []"
              :original-scheduled-time="activityEvent?.original_scheduled_time ?? 0"
            />
          </div>
        </div>
        <slot />
      </div>
      <div v-if="loader.isNotLoading.value" class="modal-footer">
        <div class="d-flex justify-content-between">
          <template v-if="isEditing">
            <AppButton
              v-if="deletable"
              color="danger"
              @click.prevent="onDelete"
              :disabled="splitLoader.isLoading.value || submitLoader.isLoading.value"
            >
              {{ t('common.delete') }}
            </AppButton>
            <AppButton
              light
              class="ml-2"
              @click.prevent="onSplitEvent"
              :loading="splitLoader.isLoading.value"
              :disabled="submitLoader.isLoading.value || splitDisabled"
              v-if="!form.is_done && isInternal"
            >
              {{ t('time-entry.modal.split') }}
            </AppButton>
            <AppButton
              class="ml-auto mr-2"
              light
              @click.prevent="onCancel"
              :disabled="splitLoader.isLoading.value || submitLoader.isLoading.value"
            >
              {{ t('common.cancel') }}
            </AppButton>
            <AppButton
              :loading="submitLoader.isLoading.value"
              :disabled="splitLoader.isLoading.value || disabled || !tracker.isModified.value"
              color="success"
            >
              {{ t('common.update') }}
            </AppButton>
          </template>
          <template v-else>
            <AppButton light @click.prevent="close">
              {{ t('common.close') }}
            </AppButton>
            <AppButton :loading="submitLoader.isLoading.value" :disabled="disabled" color="secondary">
              {{ t('common.create') }}
            </AppButton>
          </template>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
