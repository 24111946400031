import route from 'ziggy-js';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { ICustomDayDurationsListResource, ICustomDayDurationsRequestResource } from '@/types/BusinessDay';

export default {
  async list(): Promise<IResponse<ICustomDayDurationsListResource>> {
    return await httpClient.get(route('customers.custom-day-durations')).json();
  },
  async store(body: ICustomDayDurationsRequestResource): Promise<IResponse<ICustomDayDurationsListResource>> {
    return await httpClient.put(route('customers.custom-day-duration'), { json: body }).json();
  },
};
