import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  IServiceResource,
  IServicePreviewResource,
  IServiceStoreRequest,
  IServiceUpdateRequest,
} from '@/types/Service';

export default {
  async list(options?: Options): Promise<IResponse<IServicePreviewResource[]>> {
    return await httpClient.get(route('services.index'), options).json();
  },
  async get(service: string): Promise<IResponse<IServiceResource>> {
    return await httpClient.get(route('services.get', { service })).json();
  },
  async store(body: IServiceStoreRequest): Promise<IResponse<IServiceResource>> {
    return await httpClient.post(route('services.store'), { json: body }).json();
  },
  async update(service: string, body: IServiceUpdateRequest): Promise<IResponse<IServiceResource>> {
    return await httpClient.patch(route('services.update', { service }), { json: body }).json();
  },
  async destroy(service: string): Promise<IResponse<never>> {
    return await httpClient.delete(route('services.delete', { service })).json();
  },
};
