export default {
  index: {
    title: 'Samarbetspartners',
    create: 'Skapa ny',
    see_clients: 'Visa gemensamma kunder',
    help: '',
    filters: {
      name: 'Namn',
    },
  },
  create: {
    title: 'Skapa sida för samarbetspartner',
    help: '',
  },
  edit: {
    title: 'Redigera sida för samarbetspartner',
    help: '',
  },
  form: {
    general_info: 'Allmän info',
    contacts: 'Kontakter',
    other_information: 'Övrig information',
  },
  empty: 'Ingen samarbetspartner',
  attributes: {
    name: 'Namn',
    type: 'Typ',
    main_contact: 'Primär kontakt',
    phone: 'Tele',
    email: 'Epost',
    website: 'Hemsida',
    shared_client: 'Gemensamma kunder',
    partner_discount: 'Partner-rabatt',
    responsible_user: 'Vår primära kontakt',
    general_info: 'Allmän info',
    systems_descriptions: 'Systembeskrivning',
  },
  tooltip: {
    edit: 'Redigera {name}',
    destroy: 'Radra {name}',
  },
  confirm: {
    destroy: {
      title: 'Radera',
      text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
    },
  },
  templates: {
    title: 'Typer & Roller',
    add: 'Lägg till',
    help: '',
  },
  type: {
    create: {
      title: 'Skapa ny typ',
    },
    edit: {
      title: 'Redigera {name} typ',
    },
    empty: 'Inga typer',
    attributes: {
      name: 'Namn',
    },
    tooltip: {
      create: 'Skapa ny typ',
      edit: 'Redigera {name}',
      destroy: 'Radera {name}',
    },
    confirm: {
      destroy: {
        title: 'Radera typ',
        text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
      },
    },
  },
  role: {
    create: {
      title: 'Skapa ny roll',
    },
    edit: {
      title: 'Redigera {name}',
    },
    empty: 'Inga roller',
    attributes: {
      name: 'Namn',
    },
    tooltip: {
      create: 'Skapa ny roll',
      edit: 'Redigera {name}',
      destroy: 'Delete {name}',
    },
    confirm: {
      destroy: {
        title: 'Radera roll',
        text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
      },
    },
  },
  contact: {
    create: {
      title: 'Skapa ny kontakt',
    },
    edit: {
      title: 'Redigera {name}',
    },
    empty: 'Inga kontakter',
    see_clients: 'Visa gemensamma kunder',
    attributes: {
      name: 'Namn',
      short_name: 'För och efternamn',
      role: 'Roll',
      personal_number: 'Personnummer',
      email: 'Email',
      phone: 'Tele',
      main_contact: 'Primär kontakt',
      shared_clients: 'Gemensamma kunder',
    },
    tooltip: {
      create: 'Skapa ny kontakt',
      edit: 'Redigera {name}',
      destroy: 'Radera {name}',
    },
    confirm: {
      destroy: {
        title: 'Radera kontakt',
        text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
      },
    },
  },
};
