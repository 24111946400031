export enum SalarySystem {
  VISMA = 'visma',
  HOGIA = 'hogia',
}

export interface ISalarySettingsResource {
  system: SalarySystem;
  mileage_allowance_amount: number;
}

export interface ISalarySettingsRequest {
  system: null | SalarySystem;
  mileage_allowance_amount: number;
}

export interface ISalaryCodeResource {
  id: number;
  name: string;
  system_id: string;
  type: string;
  absence_type: null | string;
  is_default: boolean;
  client_work_time: boolean;
  is_in_use: boolean;
}

export interface ISalaryCodeRequest {
  name: null | string;
  system_id: null | string;
  type: null | string;
  absence_type: null | string;
  is_default: boolean;
  client_work_time: boolean;
}
