export default {
  index: {
    title: 'Tidrapporter att hantera',
    help: '',
  },
  show: {
    title: 'Tidrapport för: {month}-{year}, {username}',
    help: '',
  },
  form: {
    status: 'Status',
  },

  empty: 'Inga tidrapporter',

  // Attributes
  attributes: {
    total_tracked_time: 'Summa registrerad tid',
    time_period: 'Månad',
    status: 'Status',
    review_status: 'Granskningsstatus',
    user: 'Användare',
  },

  status: {
    unlocked: 'Olåst',
    locked: 'Låst',
  },

  review_status: {
    none: 'Ej granskad',
    not_approved: 'Ej godkänd',
    approved: 'Godkänd',
  },

  tooltip: {
    lock: 'Lås tidrapport',
    unlock: 'Lås upp tidrapport',
    approve: 'Godkänn',
    not_approve: 'Ej godkänd',
  },

  // Confirms
  confirm: {
    approve: {
      title: 'Godkänns tidrapporten',
      text: 'Är du säker?',
    },
    lock: {
      title: 'Lock the time sheet',
      text: 'Är du säker?',
    },
    unlock: {
      title: 'Unlock the time sheet',
      text: 'Är du säker?',
    },
  },
};
