import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import ServiceCompanies from '@/views/ServiceCompanies/ServiceCompanies.vue';
import ServiceCompany from '@/views/ServiceCompanies/ServiceCompany.vue';
import ServiceCompanyTemplates from '@/views/ServiceCompanies/ServiceCompanyTemplates.vue';

const prefix = 'service-organizations';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ServiceCompanies,
  },
  {
    path: `/${prefix}/create`,
    name: `${prefix}.create`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ServiceCompany,
  },
  {
    path: `/${prefix}/edit/:uuid`,
    name: `${prefix}.edit`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ServiceCompany,
    props: true,
  },
  {
    path: `/${prefix}/templates`,
    name: `${prefix}.templates.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ServiceCompanyTemplates,
  },
];

export default routes;
