import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';

import { BillingFrequency } from '@/types/Common';
import { IBillingCell, IProjectBillingPlan } from '@/types/Project';

export default function useProjectBillingPlans() {
  function createBillingPlans(
    start: null | string,
    frequency: null | BillingFrequency,
    fees: number[],
  ): IProjectBillingPlan[] {
    if (start === null || frequency === null) return [];
    return fees.map((fee, index) => {
      const mapping = {
        [BillingFrequency.MONTHLY]: 1,
        [BillingFrequency.QUARTERLY]: 3,
        [BillingFrequency.SEMI_ANNUALLY]: 6,
        [BillingFrequency.ANNUALLY]: 12,
      };
      const date = DateTime.fromISO(start).plus({ months: (index + 1) * mapping[frequency] });
      return {
        uid: nanoid(),
        month: date.month,
        year: date.year,
        price: fee,
        is_invoiced: false,
      };
    });
  }

  function calculateCompleteMonths(start: string | null, end: string | null) {
    if (start === null || end === null) return 0;
    const startDate = DateTime.fromISO(start).startOf('month');
    const endDate = DateTime.fromISO(end).endOf('month');
    return Math.ceil(endDate.diff(startDate, 'months').months);
  }

  function createBillingCells(start: string, end: string, extraMonths = 12): IBillingCell[] {
    const startDate = DateTime.fromISO(start).startOf('month');
    const months = calculateCompleteMonths(start, end) + extraMonths;
    return Array.from({ length: months }, (_, index) => ({
      uid: nanoid(),
      month: startDate.plus({ months: index }).month,
      year: startDate.plus({ months: index }).year,
    }));
  }

  function splitFee(amount: number | null, parts: number): number[] {
    if (amount === null || amount <= 0) return [];
    const baseAmount = Math.floor(amount / parts);
    const remainder = amount % parts;
    return Array.from({ length: parts }, (_, i) => (i < remainder ? baseAmount + 1 : baseAmount));
  }

  function calculateBillingCount(start: string | null, end: string | null, frequency: BillingFrequency) {
    const completeMonths = calculateCompleteMonths(start, end);
    switch (frequency) {
      case BillingFrequency.MONTHLY:
        return completeMonths;
      case BillingFrequency.QUARTERLY:
        return Math.max(1, Math.ceil(completeMonths / 3));
      case BillingFrequency.SEMI_ANNUALLY:
        return Math.max(1, Math.ceil(completeMonths / 6));
      case BillingFrequency.ANNUALLY:
        return Math.max(1, Math.ceil(completeMonths / 12));
      default:
        return 1;
    }
  }

  return {
    calculateCompleteMonths,
    calculateBillingCount,
    createBillingCells,
    createBillingPlans,
    splitFee,
  };
}
