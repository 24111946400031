<script setup lang="ts">
import { computed, onMounted, onUnmounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';
import VueSelect from 'vue-select';

import { AppButton, AppLoader, FormLabel, FormTextarea } from '@/components';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import { ICrmPlannerServiceMapRequestBody, ICrmServiceListResource } from '@/types/Crm';
import useServices from '@/composables/useServices';

const { t } = useI18n({ useScope: 'global' });

const loader = useLoader();
const servicesLoader = useLoader({ useProgress: false });
const crmServicesLoader = useLoader({ useProgress: false });
const { services, fetchServices } = useServices();

const props = defineProps<{
  loading?: boolean;
  uuid?: string;
  body?: ICrmPlannerServiceMapRequestBody;
}>();

const crmServices = ref<ICrmServiceListResource[]>([]);

interface IForm {
  planner_service: string;
  crm_services: string[];
  internal_note: string;
}

const form = reactive<IForm>({
  planner_service: '',
  crm_services: [],
  internal_note: '',
});

const emit = defineEmits<{
  (e: 'create', form: IForm): void;
  (e: 'update', uuid: string, form: IForm): void;
  (e: 'cancel'): void;
}>();

const disabled = computed(() => form.planner_service.length === 0 || form.crm_services.length === 0);

const editMode = computed(() => !!props.uuid);

function submit() {
  if (editMode.value) {
    if (!props.uuid) return;
    emit('update', props.uuid, form);
  } else {
    emit('create', form);
  }
}

async function getCrmService() {
  try {
    crmServicesLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('with_soft_deleted', '1');
    const response = await api.crm.services.list(searchParams);
    crmServices.value = response.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    console.error(error);
  } finally {
    crmServicesLoader.finish();
  }
}

onMounted(async () => {
  loader.start();
  if (props.body) {
    form.planner_service = props.body.planner_service;
    form.crm_services = props.body.crm_services;
    form.internal_note = props.body.internal_note;
  }
  await Promise.all([getCrmService(), fetchServices({ withoutPagination: true })]);
  loader.finish();
});

onUnmounted(() => {
  form.planner_service = '';
  form.crm_services = [];
  form.internal_note = '';
});
</script>

<template>
  <VueFinalModal class="modal-overlay" content-class="modal-container" :click-to-close="false" :esc-to-close="false">
    <form @submit.prevent="submit">
      <div v-if="loader.isLoading.value" class="text-center py-3">
        <AppLoader size="large" />
      </div>
      <template v-else>
        <div class="modal-header">
          <h2 v-text="t(`service-mapping.${editMode ? 'edit' : 'create'}.title`)" />
        </div>
        <div class="modal-content">
          <!-- Planner services -->
          <div class="form-group">
            <FormLabel html-for="planner_services" required>
              {{ t('service-mapping.form.planner_services') }}
            </FormLabel>
            <VueSelect
              v-model="form.planner_service"
              :options="services"
              label="name"
              input-id="planner_services"
              :placeholder="t('common.select')"
              :reduce="(option) => option.uuid"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="form.planner_service.length === 0"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </VueSelect>
          </div>
          <!-- Crm services -->
          <div class="form-group">
            <FormLabel html-for="crm_services" required>
              {{ t('service-mapping.form.crm_services') }}
            </FormLabel>
            <VueSelect
              :clearable="false"
              v-model="form.crm_services"
              :options="crmServices"
              label="name"
              input-id="crm_services"
              :placeholder="t('common.type_of_search')"
              :reduce="(option) => option.uuid"
              required
              :loading="servicesLoader.isLoading.value"
              :disabled="servicesLoader.isLoading.value"
              multiple="multiple"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="form.crm_services.length === 0"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </VueSelect>
          </div>
          <div class="form-group">
            <FormLabel html-for="internal_note">
              {{ t('service-mapping.form.internal_note') }}
            </FormLabel>
            <FormTextarea rows="4" id="internal_note" v-model="form.internal_note" />
          </div>
          <slot />
        </div>
        <div class="modal-footer">
          <div class="d-flex">
            <AppButton light @click.prevent="emit('cancel')" :disabled="loading">
              {{ t('common.cancel') }}
            </AppButton>
            <AppButton
              class="ml-auto"
              :color="editMode ? 'success' : 'secondary'"
              :loading="loading"
              :disabled="disabled"
            >
              {{ editMode ? t('common.save') : t('common.create') }}
            </AppButton>
          </div>
        </div>
      </template>
    </form>
  </VueFinalModal>
</template>
