<script setup lang="ts">
import { ref, onMounted, watch, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useRouteQuery } from '@vueuse/router';
import { useI18n } from 'vue-i18n';
import { Dropdown } from 'floating-vue';
import { DateTime } from 'luxon';
import VueSelect from 'vue-select';
import VueDatePicker from '@vuepic/vue-datepicker';
import { useModal } from 'vue-final-modal';

import api from '@/services/api';
import {
  AppAlert,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  AppButton,
  FontIcon,
  FormLabel,
  AppPagination,
  AppLoader,
  AppCollapse,
  ColumnSettings,
  HelpInformation,
  FormSwitch,
  TimeEntryProjectTaskModal,
  DeadlineDate,
} from '@/components';
import useLoader from '@/composables/useLoader';
import useColumnSettings from '@/composables/useColumnSettings';
import useClients from '@/composables/useClients';
import useAuthStore from '@/store/AuthStore';
import { IClientPreviewResource } from '@/types/Client';
import { IDeadlineEventResource } from '@/types/Event';
import { IServicePreviewResource } from '@/types/Service';
import { IUserListResource } from '@/types/User';
import { IProjectPreviewResource, ProjectStep } from '@/types/Project';
import { IProjectTaskResource } from '@/types/ProjectTask';

const router = useRouter();
const { t, locale } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { authenticatedUser } = useAuthStore();
const { clients, onSearchClients } = useClients();

// Events
const events = ref<IDeadlineEventResource[]>([]);

// Pagination
const page = useRouteQuery<number>('page', 1, { transform: Number });
const perPage = ref(20);
const total = ref(0);

// Columns
const { columns, isColumnEnable, enabledColumns } = useColumnSettings('deadlines_columns', [
  'employee',
  'client',
  'service',
  'project',
  'project_responsible',
  'task',
  'deadline',
]);
// Filters
type Filters = {
  assigned_user: null | string;
  client: null | string;
  project: null | number;
  project_responsible: null | string;
  service: null | string;
  task: null | number;
  date_from: null | string;
  date_to: null | string;
  tasks_with_deadlines: boolean;
  unfinished_tasks: boolean;
};

const filters = reactive<Filters>({
  assigned_user: authenticatedUser.uuid,
  client: null,
  project: null,
  project_responsible: null,
  service: null,
  task: null,
  date_from: DateTime.now().startOf('month').toFormat('yyyy-MM-dd'),
  date_to: DateTime.now().endOf('month').toFormat('yyyy-MM-dd'),
  tasks_with_deadlines: true,
  unfinished_tasks: true,
});
const filtersLoader = useLoader();

async function resetFilters() {
  filters.assigned_user = authenticatedUser.uuid;
  filters.client = null;
  filters.project = null;
  filters.project_responsible = null;
  filters.service = null;
  filters.task = null;
  filters.date_from = DateTime.now().startOf('month').toFormat('yyyy-MM-dd');
  filters.date_to = DateTime.now().endOf('month').toFormat('yyyy-MM-dd');
  filters.tasks_with_deadlines = true;
  filters.unfinished_tasks = true;
  await onFilter();
}

async function getDeadlines() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', page.value.toString());
    searchParams.append('per_page', perPage.value.toString());
    // Set filters
    if (filters.assigned_user) searchParams.append('assigned_user', filters.assigned_user);
    if (filters.client) searchParams.append('client', filters.client);
    if (filters.project) searchParams.append('project', filters.project.toString());
    if (filters.project_responsible) searchParams.append('project_responsible', filters.project_responsible);
    if (filters.service) searchParams.append('service', filters.service);
    if (filters.task) searchParams.append('task', filters.task.toString());
    if (filters.date_from) searchParams.append('date_from', filters.date_from);
    if (filters.date_to) searchParams.append('date_to', filters.date_to);
    searchParams.append('tasks_with_deadlines', Number(filters.tasks_with_deadlines).toString());
    searchParams.append('unfinished_tasks', Number(filters.unfinished_tasks).toString());

    const response = await api.events.deadlines.index({ searchParams });
    events.value = response.data;
    if (response.meta) {
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  }
}

async function onFilter() {
  loader.start();
  filtersLoader.start();
  page.value = 1;
  await getDeadlines();
  filtersLoader.finish();
  loader.finish();
}

// Services
const services = ref<IServicePreviewResource[]>([]);
const servicesLoading = ref(false);

async function getServices() {
  try {
    servicesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.services.list({ searchParams });
    services.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    servicesLoading.value = false;
  }
}

// Projects
const projects = ref<IProjectPreviewResource[]>([]);
const projectsLoading = ref(false);

async function getProjects() {
  if (filters.client === null) return;
  try {
    projectsLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    searchParams.append('statuses[]', 'active');
    const response = await api.projects.index(filters.client, searchParams);
    projects.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    projectsLoading.value = false;
  }
}

// Task
const tasks = ref<IProjectTaskResource[]>([]);
const tasksLoading = ref(false);

async function getTasks() {
  if (filters.client === null || filters.project === null) return;
  try {
    tasksLoading.value = true;
    const response = await api.projects.get(filters.client, filters.project);
    tasks.value = response.data.tasks;
  } catch (error) {
    console.error(error);
  } finally {
    tasksLoading.value = false;
  }
}

// Users
const users = ref<IUserListResource[]>([]);
const usersLoading = ref(false);

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function onDone(id: number, event: MouseEvent) {
  const target = event.target as HTMLElement;
  const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
  button.classList.add('is-loading');
  try {
    await api.events.done(id);
    await getDeadlines();
  } catch (error) {
    console.error(error);
  } finally {
    button.classList.remove('is-loading');
  }
}

function onTrackTime(event: IDeadlineEventResource) {
  const { open, close, destroy } = useModal({
    component: TimeEntryProjectTaskModal,
    attrs: {
      id: event.id,
      onSplit() {
        getDeadlines();
        close();
      },
      onUpdated() {
        getDeadlines();
        close();
      },
      async onDeleted() {
        await getDeadlines();
        if (events.value.length === 0 && page.value > 1) {
          page.value -= 1;
        }
        await close();
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onProjectView(event: IDeadlineEventResource) {
  const { href } = router.resolve({
    name: 'projects.view',
    params: { uuid: event.client.uuid, id: event.project.id },
  });
  window.open(href, '_blank');
}

onMounted(async () => {
  loader.start();
  await Promise.all([getDeadlines(), getServices(), getUsers()]);
  loader.finish();
});

watch(page, async () => {
  loader.start();
  await getDeadlines();
  loader.finish();
});
</script>

<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="t('deadline.index.title')" />
        <HelpInformation class="ml-1" translation="deadline.index.help" />
      </div>
      <Dropdown class="ml-auto" placement="bottom-end" :distance="10">
        <AppButton class="ml-2" light circle>
          <FontIcon name="table-options" />
        </AppButton>
        <template #popper>
          <ColumnSettings
            v-model="enabledColumns"
            :columns="columns"
            :get-label="(columnName) => t(`deadline.attributes.${columnName}`)"
          />
        </template>
      </Dropdown>
    </div>
    <AppCollapse class="my-3" :title="t('common.filters')">
      <form @submit.prevent="onFilter" class="my-3">
        <div class="row row-cols-5 align-items-end">
          <!-- Employee -->
          <div class="form-group col">
            <FormLabel html-for="filter-assigned_user">
              {{ t('deadline.index.filters.assigned_user') }}
            </FormLabel>
            <VueSelect
              v-model="filters.assigned_user"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="filter-assigned_user"
              :loading="usersLoading"
              :disabled="filtersLoader.isLoading.value"
              :placeholder="t('common.search')"
            />
          </div>
          <!-- Client -->
          <div class="form-group col">
            <FormLabel html-for="filter-client">
              {{ t('deadline.index.filters.client') }}
            </FormLabel>
            <VueSelect
              v-model="filters.client"
              :options="clients"
              :reduce="(option: IClientPreviewResource) => option.uuid"
              label="name"
              input-id="filter-client"
              :disabled="filtersLoader.isLoading.value"
              :filterable="false"
              @search="onSearchClients"
              :placeholder="t('common.search')"
              @update:model-value="
                () => {
                  filters.project = null;
                  getProjects();
                }
              "
            >
              <template #no-options>{{ t('common.type_to_search') }}</template>
            </VueSelect>
          </div>
          <!-- Service -->
          <div class="form-group col">
            <FormLabel html-for="filter-service">
              {{ t('deadline.index.filters.service') }}
            </FormLabel>
            <VueSelect
              v-model="filters.service"
              :options="services"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="filter-service"
              :loading="servicesLoading"
              :disabled="filtersLoader.isLoading.value"
              :placeholder="t('common.search')"
            />
          </div>
          <!-- Project -->
          <div class="form-group col">
            <FormLabel html-for="filter-project">
              {{ t('deadline.index.filters.project') }}
            </FormLabel>
            <VueSelect
              v-model="filters.project"
              :options="projects"
              :reduce="(option: IProjectPreviewResource) => option.id"
              label="name"
              input-id="filter-project"
              :loading="projectsLoading"
              :disabled="filters.client === null || filtersLoader.isLoading.value"
              :placeholder="t('common.search')"
              @update:model-value="
                () => {
                  filters.task = null;
                  getTasks();
                }
              "
            />
          </div>
          <!-- Project responsible -->
          <div class="form-group col">
            <FormLabel html-for="filter-project_responsible">
              {{ t('deadline.index.filters.project_responsible') }}
            </FormLabel>
            <VueSelect
              v-model="filters.project_responsible"
              :options="users"
              :reduce="(option: IUserListResource) => option.uuid"
              label="name"
              input-id="filter-project_responsible"
              :loading="usersLoading"
              :disabled="filtersLoader.isLoading.value"
              :placeholder="t('common.search')"
            />
          </div>
          <!-- Task -->
          <div class="form-group col">
            <FormLabel html-for="filter-task">
              {{ t('deadline.index.filters.task') }}
            </FormLabel>
            <VueSelect
              v-model="filters.task"
              :options="tasks"
              :reduce="(option: IProjectPreviewResource) => option.id"
              label="name"
              input-id="filter-task"
              :loading="tasksLoading"
              :disabled="filters.project === null || filtersLoader.isLoading.value"
              :placeholder="t('common.search')"
            />
          </div>
          <!-- From -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-date_from">
              {{ t('deadline.index.filters.date_from') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter-date_from"
                input-class-name="form-control"
                v-model="filters.date_from"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                :disabled="filtersLoader.isLoading.value"
                :placeholder="t('common.select')"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- To -->
          <div class="form-group col">
            <FormLabel html-for="dp-input-filter-date_to">
              {{ t('deadline.index.filters.date_to') }}
            </FormLabel>
            <div class="form-wrapper has-icon">
              <VueDatePicker
                uid="filter-date_to"
                input-class-name="form-control"
                v-model="filters.date_to"
                model-type="format"
                format="yyyy-MM-dd"
                :enable-time-picker="false"
                :month-change-on-scroll="false"
                auto-apply
                text-input
                :locale="locale"
                :week-num-name="t('common.week_short')"
                :disabled="filtersLoader.isLoading.value"
                :placeholder="t('common.select')"
              >
                <template #input-icon><i class="form-icon ti ti-calendar" /></template>
              </VueDatePicker>
            </div>
          </div>
          <!-- Task with deadlines -->
          <div class="form-group col">
            <FormLabel html-for="filter-tasks_with_deadlines">
              {{ t('deadline.index.filters.tasks_with_deadlines') }}
            </FormLabel>
            <FormSwitch
              id="filter-tasks_with_deadlines"
              v-model="filters.tasks_with_deadlines"
              :disabled="filtersLoader.isLoading.value"
            />
          </div>
          <!-- Task with deadlines -->
          <div class="form-group col">
            <FormLabel html-for="filter-unfinished_tasks">
              {{ t('deadline.index.filters.unfinished_tasks') }}
            </FormLabel>
            <FormSwitch
              id="filter-unfinished_tasks"
              v-model="filters.unfinished_tasks"
              :disabled="filtersLoader.isLoading.value"
            />
          </div>
        </div>
        <div>
          <AppButton :disabled="filtersLoader.isLoading.value">
            {{ t('common.apply_filters') }}
          </AppButton>
          <AppButton class="ml-2" light @click.prevent="resetFilters" :disabled="filtersLoader.isLoading.value">
            {{ t('common.reset_filters') }}
          </AppButton>
        </div>
      </form>
    </AppCollapse>
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <template v-else>
      <AppAlert v-if="events.length === 0">
        {{ t('deadline.empty') }}
      </AppAlert>
      <template v-else>
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap v-if="isColumnEnable('employee')">
                {{ t('deadline.attributes.employee') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('client')">
                {{ t('deadline.attributes.client') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('service')">
                {{ t('deadline.attributes.service') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('project')">
                {{ t('deadline.attributes.project') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('project_responsible')">
                {{ t('deadline.attributes.project_responsible') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('task')">
                {{ t('deadline.attributes.task') }}
              </AppTableTh>
              <AppTableTh nowrap v-if="isColumnEnable('deadline')">
                {{ t('deadline.attributes.deadline') }}
              </AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="event in events" :key="event.id">
              <AppTableTd nowrap v-if="isColumnEnable('employee')">
                {{ event.user.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('client')">
                <RouterLink
                  target="_blank"
                  :to="{ name: 'clients.edit', params: { uuid: event.client.uuid } }"
                  class="pointer"
                >
                  {{ event.client.name }}
                </RouterLink>
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('service')">
                {{ event.service.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('project')">
                <RouterLink
                  target="_blank"
                  :to="{
                    name: 'projects.edit',
                    params: { uuid: event.client.uuid, id: event.project.id },
                    query: { step: ProjectStep.Planning },
                  }"
                  class="pointer"
                >
                  {{ event.project.name }}
                </RouterLink>
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('project_responsible')">
                {{ event.projectResponsible.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('task')">
                {{ event.task.name }}
              </AppTableTd>
              <AppTableTd nowrap v-if="isColumnEnable('deadline')">
                <DeadlineDate :deadline="event.deadline" :done-at="event.done_at" />
              </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton
                  v-if="!event.done_at"
                  @click.prevent="onDone(event.id, $event)"
                  size="small"
                  light
                  color="success"
                  circle
                  v-tooltip.left="t('deadline.tooltip.set_as_done')"
                >
                  <FontIcon name="check" />
                </AppButton>
                <AppButton
                  @click.prevent="onTrackTime(event)"
                  class="ml-2"
                  size="small"
                  light
                  circle
                  v-tooltip.left="t('deadline.tooltip.open_task')"
                >
                  <FontIcon name="info-circle" />
                </AppButton>
                <AppButton
                  @click.prevent="onProjectView(event)"
                  class="ml-2"
                  size="small"
                  light
                  circle
                  v-tooltip.left="t('deadline.tooltip.open_project')"
                >
                  <FontIcon name="briefcase" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
        <AppPagination :per-page="perPage" :total="total" v-model="page" />
      </template>
    </template>
  </div>
</template>
