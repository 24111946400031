<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal, useVfm } from 'vue-final-modal';
import { useRouteQuery } from '@vueuse/router';
import { DateTime } from 'luxon';

import api from '@/services/api';
import toast from '@/services/toast';
import useLoader from '@/composables/useLoader';
import useTime from '@/composables/useTime';
import {
  AppAlert,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  EmptyValue,
  FontIcon,
  InternalProjectModal,
  InternalProjectStatus,
} from '@/components';
import {
  IInternalProjectCreateOrUpdateRequestBody,
  IInternalProjectResource,
  InternalProjectStatusType,
} from '@/types/InternalProject';
import { CrudMode } from '@/types/Common';
import { IActivityListResource } from '@/types/Activity';

type Props = {
  id: string;
  title: string;
  description?: string;
  columns: string[];
  creatable?: boolean;
  filters: {
    statuses: InternalProjectStatusType[];
  };
};

const props = defineProps<Props>();

const { t, d } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { convertMinutesToTime } = useTime();
const { closeAll } = useVfm();

const internalProjects = ref<IInternalProjectResource[]>([]);
const internalProjectsAbortController = ref<null | AbortController>(null);
const currentPage = useRouteQuery(`${props.id}-page`, '1', { transform: Number });
const perPage = ref(20);
const total = ref(0);

async function getInternalProjects() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentPage.value.toString());
    props.filters.statuses.forEach((status) => {
      searchParams.append('statuses[]', status);
    });
    internalProjectsAbortController.value = new AbortController();
    const response = await api.internalProjects.index({
      searchParams,
      signal: internalProjectsAbortController.value.signal,
    });
    internalProjects.value = response.data;
    if (response.meta) {
      perPage.value = response.meta.per_page;
      total.value = response.meta.total;
    }
  } catch (error) {
    console.error(error);
  } finally {
    internalProjectsAbortController.value = null;
  }
}

const createModal = useModal({
  component: InternalProjectModal,
  keepAlive: false,
  attrs: {
    mode: CrudMode.CREATING,
    async onCreate(form: IInternalProjectCreateOrUpdateRequestBody) {
      // @ts-ignore
      createModal.patchOptions({ attrs: { loading: true } });
      try {
        await api.internalProjects.store(form);
        await getInternalProjects();
        await createModal.close();
        toast.success(t('common.messages.has_been_created', { name: form.name }));
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        createModal.patchOptions({ attrs: { loading: false } });
      }
    },
  },
});

const editModal = useModal({
  component: InternalProjectModal,
  keepAlive: false,
  attrs: {
    mode: CrudMode.EDITING,
    async onUpdate(id: number, form: IInternalProjectCreateOrUpdateRequestBody) {
      // @ts-ignore
      editModal.patchOptions({ attrs: { loading: true } });
      try {
        await api.internalProjects.update(id, form);
        await getInternalProjects();
        await editModal.close();
        toast.success(t('common.messages.has_been_updated', { name: form.name }));
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        editModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onActivate,
    onClose,
  },
});

const deleteModal = useModal({
  component: ConfirmModal,
  keepAlive: false,
  // @ts-ignore
  attrs: {
    async onConfirm() {
      try {
        // @ts-ignore
        deleteModal.patchOptions({ attrs: { loading: true } });
        await api.internalProjects.destroy(deleteModal.options.attrs?.params?.id as number);
        await getInternalProjects();
        await deleteModal.close();
        toast.success(t('common.messages.has_been_deleted', { name: deleteModal.options.attrs?.params?.name }));
        if (internalProjects.value.length === 0 && currentPage.value > 1) {
          currentPage.value -= 1;
        }
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        deleteModal.patchOptions({ attrs: { loading: false } });
      }
    },
  },
});

const activateModal = useModal({
  component: ConfirmModal,
  keepAlive: false,
  // @ts-ignore
  attrs: {
    async onConfirm() {
      try {
        // @ts-ignore
        activateModal.patchOptions({ attrs: { loading: true } });
        await api.internalProjects.activate(activateModal.options.attrs?.params?.id as number);
        await getInternalProjects();
        await closeAll();
        toast.success(t('common.messages.has_been_activated', { name: activateModal.options.attrs?.params?.name }));
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        activateModal.patchOptions({ attrs: { loading: false } });
      }
    },
  },
});

const closeModal = useModal({
  component: ConfirmModal,
  keepAlive: false,
  // @ts-ignore
  attrs: {
    async onConfirm() {
      try {
        // @ts-ignore
        closeModal.patchOptions({ attrs: { loading: true } });
        await api.internalProjects.close(closeModal.options.attrs?.params?.id as number);
        await getInternalProjects();
        await closeAll();
        toast.success(t('common.messages.has_been_closed', { name: closeModal.options.attrs?.params?.name }));
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        closeModal.patchOptions({ attrs: { loading: false } });
      }
    },
  },
});

function onCreate() {
  createModal.open();
}

function onEdit(id: number) {
  // @ts-ignore
  editModal.patchOptions({ attrs: { id } });
  editModal.open();
}

function onDelete(id: number, name: string) {
  deleteModal.patchOptions({
    // @ts-ignore
    attrs: {
      params: { id, name },
      title: t('internal-projects.confirm.destroy.title'),
      message: t('internal-projects.confirm.destroy.text', { name }),
    },
  });
  deleteModal.open();
}

function onActivate(id: number, name: string) {
  activateModal.patchOptions({
    // @ts-ignore
    attrs: {
      params: { id, name },
      title: t('internal-projects.confirm.activate.title'),
      message: t('internal-projects.confirm.activate.text', { name }),
    },
  });
  activateModal.open();
}

function onClose(id: number, name: string) {
  closeModal.patchOptions({
    // @ts-ignore
    attrs: {
      params: { id, name },
      title: t('internal-projects.confirm.close.title'),
      message: t('internal-projects.confirm.close.text', { name }),
    },
  });
  closeModal.open();
}

function onCopy(id: number, name: string) {
  console.log(id, name);
}

function onReopen(id: number, name: string) {
  activateModal.patchOptions({
    // @ts-ignore
    attrs: {
      params: { id, name },
      title: t('internal-projects.confirm.reopen.title'),
      message: t('internal-projects.confirm.reopen.text', { name }),
    },
  });
  activateModal.open();
}

function formatUsers(activities: IActivityListResource[]) {
  return [
    ...new Set(
      activities.reduce((users, activity) => [...users, ...activity.users.map(({ name }) => name)], [] as string[]),
    ),
  ].join(', ');
}

watch(currentPage, async () => {
  loader.start();
  await getInternalProjects();
  loader.finish();
});

onMounted(async () => {
  loader.start();
  await getInternalProjects();
  loader.finish();
});

onUnmounted(async () => {
  internalProjectsAbortController.value?.abort('Abort fetching internal projects.');
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <template v-else>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div>
        <h2 class="mb-0" v-text="title" />
        <p v-if="description" class="mb-0" v-text="description"></p>
      </div>
      <AppButton v-if="creatable" @click.prevent="onCreate" color="secondary">
        {{ t('internal-projects.index.add_event') }}
      </AppButton>
    </div>
    <AppAlert v-if="internalProjects.length === 0">{{ t('internal-projects.empty') }}</AppAlert>
    <template v-else>
      <AppTable hoverable>
        <AppTableHead>
          <AppTableTr>
            <AppTableTh nowrap>
              {{ t('internal-projects.table.name') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="columns.includes('status')">
              {{ t('internal-projects.table.status') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="columns.includes('date')">
              {{ t('internal-projects.table.date') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="columns.includes('task')">
              {{ t('internal-projects.table.task') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="columns.includes('frequency')">
              {{ t('internal-projects.table.frequency') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="columns.includes('hours')">
              {{ t('internal-projects.table.hours') }}
            </AppTableTh>
            <AppTableTh nowrap v-if="columns.includes('group')">
              {{ t('internal-projects.table.group') }}
            </AppTableTh>
            <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="internalProject in internalProjects" :key="internalProject.id">
            <AppTableTd nowrap><strong v-text="internalProject.name" /></AppTableTd>
            <AppTableTd nowrap v-if="columns.includes('status')">
              <InternalProjectStatus :status="internalProject.status" />
            </AppTableTd>
            <AppTableTd nowrap v-if="columns.includes('date')">
              <span>{{ d(DateTime.fromISO(internalProject.start_date).toJSDate(), 'long') }}</span>
              <span v-if="internalProject.end_date">
                - {{ d(DateTime.fromISO(internalProject.end_date).toJSDate(), 'long') }}
              </span>
            </AppTableTd>
            <AppTableTd nowrap v-if="columns.includes('task')">
              <EmptyValue v-if="internalProject.activities.length === 0" />
              <span
                v-else-if="internalProject.activities.length === 1"
                v-text="internalProject.activities[0].template.name"
              />
              <span
                v-else-if="internalProject.activities.length > 1"
                v-text="t('internal-projects.attributes.task.multiple')"
                v-tooltip.top="internalProject.activities.map(({ template }) => template.name).join(', ')"
              />
            </AppTableTd>
            <AppTableTd nowrap v-if="columns.includes('frequency')">
              <EmptyValue v-if="internalProject.activities.length === 0" />
              <span
                v-else-if="internalProject.activities.length === 1"
                v-text="
                  internalProject.activities[0].frequency === null
                    ? t('internal-projects.attributes.frequency.once')
                    : t(`internal-projects.attributes.frequency.${internalProject.activities[0].frequency}`)
                "
              />
              <span
                v-else-if="internalProject.activities.length > 1"
                v-text="t('internal-projects.attributes.frequency.varying')"
              />
            </AppTableTd>
            <AppTableTd nowrap v-if="columns.includes('hours')">
              <EmptyValue v-if="internalProject.activities.length === 0" />
              <span
                v-else-if="internalProject.activities.length === 1"
                v-text="convertMinutesToTime(internalProject.activities[0].scheduled_time)"
              />
              <span v-else-if="internalProject.activities.length > 1">
                {{
                  convertMinutesToTime(
                    Math.min(...internalProject.activities.map(({ scheduled_time }) => scheduled_time)),
                  )
                }}
                -
                {{
                  convertMinutesToTime(
                    Math.max(...internalProject.activities.map(({ scheduled_time }) => scheduled_time)),
                  )
                }}
              </span>
            </AppTableTd>
            <AppTableTd v-if="columns.includes('group')">
              <EmptyValue v-if="internalProject.activities.length === 0" />
              <span v-text="formatUsers(internalProject.activities)" />
            </AppTableTd>
            <AppTableTd nowrap class="text-right">
              <AppButton
                v-if="
                  [InternalProjectStatusType.DRAFT, InternalProjectStatusType.ACTIVE].includes(internalProject.status)
                "
                v-tooltip.left="t('internal-projects.tooltip.edit', { name: internalProject.name })"
                @click.stop.prevent="onEdit(internalProject.id)"
                size="small"
                light
                circle
              >
                <FontIcon name="pencil" />
              </AppButton>
              <AppButton
                v-if="[InternalProjectStatusType.DRAFT].includes(internalProject.status)"
                v-tooltip.left="t('internal-projects.tooltip.activate', { name: internalProject.name })"
                @click.stop.prevent="onActivate(internalProject.id, internalProject.name)"
                class="ml-2"
                color="secondary"
                size="small"
                light
                circle
              >
                <FontIcon name="check" />
              </AppButton>
              <AppButton
                v-if="[InternalProjectStatusType.ACTIVE].includes(internalProject.status)"
                v-tooltip.left="t('internal-projects.tooltip.close', { name: internalProject.name })"
                @click.stop.prevent="onClose(internalProject.id, internalProject.name)"
                class="ml-2"
                color="danger"
                size="small"
                light
                circle
              >
                <FontIcon name="ban" />
              </AppButton>
              <AppButton
                v-if="[InternalProjectStatusType.DRAFT].includes(internalProject.status)"
                v-tooltip.left="t('internal-projects.tooltip.destroy', { name: internalProject.name })"
                @click.stop.prevent="onDelete(internalProject.id, internalProject.name)"
                class="ml-2"
                color="danger"
                size="small"
                light
                circle
              >
                <FontIcon name="trash" />
              </AppButton>
              <AppButton
                v-if="0 && InternalProjectStatusType.CLOSED === internalProject.status"
                v-tooltip.left="t('internal-projects.tooltip.copy', { name: internalProject.name })"
                @click.stop.prevent="onCopy(internalProject.id, internalProject.name)"
                class="ml-2"
                size="small"
                light
                circle
                disabled
              >
                <FontIcon name="copy" />
              </AppButton>
              <AppButton
                v-if="InternalProjectStatusType.CLOSED === internalProject.status"
                v-tooltip.left="t('internal-projects.tooltip.reopen', { name: internalProject.name })"
                @click.stop.prevent="onReopen(internalProject.id, internalProject.name)"
                class="ml-2"
                size="small"
                light
                circle
              >
                <FontIcon name="rotate" />
              </AppButton>
            </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppPagination :per-page="perPage" :total="total" v-model="currentPage" />
    </template>
  </template>
</template>
