export default {
  index: {
    title: 'Checkpoints',
    help: '',
    filters: {
      employee: 'Employee',
      project_responsible: 'Project responsible',
      from: 'From',
      to: 'To',
      client_name: 'Client',
      project_name: 'Project',
      checkpoint_status: 'Checkpoint status',
      service: 'Service',
    },
  },
  attributes: {
    employee: 'Employee',
    project_responsible: 'Project responsible',
    end_financial_year_date: 'Financial year end',
    deadline: 'Project deadline',
    client: 'Client',
    project: 'Project',
    checkpoint_status: 'Checkpoint Status',
    checkpoints: 'Checkpoints',
  },
  empty: 'No checkpoints',
};
