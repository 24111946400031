<script setup lang="ts">
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';

import { FormInput, FormLabel } from '@/components';
import { IClientCategory } from '@/types/Client';
import { IUserListResource } from '@/types/User';
import { ICountry } from '@/types/Country';
import VueDatePicker from '@vuepic/vue-datepicker';
import { InvoiceDistributionType } from '@/types/Invoice';

type Props = {
  mode: 'creating' | 'editing';
  users: IUserListResource[];
  countries: ICountry[];
  clientCategories: IClientCategory[];
  invoiceDistribution: null | InvoiceDistributionType;
};

const { t, locale } = useI18n({ useScope: 'global' });

const creatingMode = computed(() => props.mode === 'creating');
const editingMode = computed(() => props.mode === 'editing');

const props = defineProps<Props>();

const name = defineModel<string | null>('name', { required: true });
const orgNumber = defineModel<string | null>('orgNumber', { required: true });
const vatNumber = defineModel<string | null>('vatNumber', { required: true });
const type = defineModel<string | null>('type', { required: true });
const categoryUuid = defineModel<string | null>('categoryUuid', { required: true });
const phone = defineModel<string | null>('phone', { required: true });
const responsibleUserUuid = defineModel<string | null>('responsibleUserUuid', { required: true });
const startFinancialYearDate = defineModel<string | null>('startFinancialYearDate', { required: true });
const endFinancialYearDate = defineModel<string | null>('endFinancialYearDate', { required: true });
const firstAddress = defineModel<string | null>('firstAddress', { required: true });
const secondAddress = defineModel<string | null>('secondAddress', { required: true });
const zip = defineModel<string | null>('zip', { required: true });
const city = defineModel<string | null>('city', { required: true });
const country = defineModel<string | null>('country', { required: true });
const invoiceEmail = defineModel<string | null>('invoiceEmail', { required: true });
const invoiceFirstAddress = defineModel<string | null>('invoiceFirstAddress', { required: true });
const invoiceSecondAddress = defineModel<string | null>('invoiceSecondAddress', { required: true });
const invoiceZip = defineModel<string | null>('invoiceZip', { required: true });
const invoiceCity = defineModel<string | null>('invoiceCity', { required: true });
const invoiceCountry = defineModel<string | null>('invoiceCountry', { required: true });

const categoriesByClientType = computed(() =>
  props.clientCategories.filter((category: IClientCategory) => category.type === type.value),
);

watch(
  () => type.value,
  () => {
    categoryUuid.value = '';
  },
);
</script>

<template>
  <div>
    <h2 v-text="t('client.form.general_info')" />
    <div class="row row-cols-sm-2 row-cols-md-3">
      <div class="form-group col">
        <FormLabel html-for="name" required>{{ t('client.attributes.name') }}</FormLabel>
        <FormInput id="name" v-model="name" required />
      </div>
      <div class="form-group col">
        <FormLabel html-for="org_number" required>{{ t('client.attributes.org_number') }}</FormLabel>
        <FormInput id="org_number" v-model="orgNumber" required :readonly="editingMode" />
      </div>
      <div class="form-group col">
        <FormLabel html-for="vat">{{ t('client.attributes.vat') }}</FormLabel>
        <FormInput id="vat" v-model="vatNumber" />
      </div>
      <div class="form-group col">
        <FormLabel html-for="category" required>{{ t('client.attributes.company_category') }}</FormLabel>
        <VueSelect
          :reduce="(option: IClientCategory) => option.uuid"
          :clearable="false"
          v-model="categoryUuid"
          :options="categoriesByClientType"
          :get-option-label="(option: IClientCategory) => t(`company-categories.type.${option.name}`)"
          input-id="category"
          :placeholder="t('common.select')"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!categoryUuid" v-bind="attributes" v-on="events" />
          </template>
        </VueSelect>
      </div>
      <div class="form-group col">
        <FormLabel html-for="main_phone">{{ t('client.attributes.main_phone') }}</FormLabel>
        <FormInput type="tel" id="main_phone" v-model="phone" />
      </div>
      <div class="form-group col">
        <FormLabel html-for="responsible" required>{{ t('client.attributes.responsible_user') }}</FormLabel>
        <VueSelect
          :clearable="false"
          v-model="responsibleUserUuid"
          :reduce="(option: IUserListResource) => option.uuid"
          :options="users"
          label="name"
          input-id="responsible"
          :placeholder="t('common.search')"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!responsibleUserUuid" v-bind="attributes" v-on="events" />
          </template>
        </VueSelect>
      </div>
      <div class="form-group col" v-if="creatingMode">
        <FormLabel html-for="dp-input-financial_year_date" :placeholder="t('common.select')" required>
          {{ t('client.attributes.start_financial_year_date') }}
        </FormLabel>
        <div class="form-wrapper has-icon">
          <VueDatePicker
            input-class-name="form-control"
            :placeholder="t('common.select')"
            v-model="startFinancialYearDate"
            model-type="format"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            :month-change-on-scroll="false"
            required
            auto-apply
            text-input
            :locale="locale"
            :week-num-name="t('common.week_short')"
            uid="financial_year_date"
          >
            <template #input-icon><i class="form-icon ti ti-calendar" /></template>
          </VueDatePicker>
        </div>
      </div>
      <div class="form-group col" v-if="creatingMode">
        <FormLabel html-for="dp-input-end_financial_year_date" required>
          {{ t('client.attributes.end_financial_year_date') }}
        </FormLabel>
        <div class="form-wrapper has-icon">
          <VueDatePicker
            input-class-name="form-control"
            :placeholder="t('common.select')"
            v-model="endFinancialYearDate"
            model-type="format"
            format="yyyy-MM-dd"
            :enable-time-picker="false"
            :month-change-on-scroll="false"
            required
            auto-apply
            text-input
            :locale="locale"
            :week-num-name="t('common.week_short')"
            uid="end_financial_year_date"
          >
            <template #input-icon><i class="form-icon ti ti-calendar" /></template>
          </VueDatePicker>
        </div>
      </div>
    </div>
    <div class="row row-cols-sm-2">
      <div class="col mt-4">
        <h2 v-text="t('client.form.registered_address')" />
        <div class="row">
          <div class="form-group col-12">
            <FormLabel html-for="first_address">{{ t('client.attributes.first_address') }}</FormLabel>
            <FormInput id="first_address" v-model="firstAddress" />
          </div>
          <div class="form-group col-12">
            <FormLabel html-for="second_address">{{ t('client.attributes.second_address') }}</FormLabel>
            <FormInput id="second_address" v-model="secondAddress" />
          </div>
          <div class="form-group col-md-6">
            <FormLabel html-for="zip">{{ t('client.attributes.zip') }}</FormLabel>
            <FormInput id="zip" v-model="zip" />
          </div>
          <div class="form-group col-md-6">
            <FormLabel html-for="city">{{ t('client.attributes.city') }}</FormLabel>
            <FormInput id="city" v-model="city" />
          </div>
          <div class="form-group col-12">
            <FormLabel html-for="country">{{ t('client.attributes.country') }}</FormLabel>
            <VueSelect
              v-if="countries.length > 0"
              :reduce="(option: ICountry) => option.code"
              :clearable="false"
              v-model="country"
              :options="countries"
              label="code"
              :get-option-label="(option: ICountry) => option.name[locale]"
              input-id="country"
              :placeholder="t('common.select')"
            />
          </div>
        </div>
      </div>
      <div class="col mt-4">
        <h2 v-text="t('client.form.invoice_address')" />
        <div class="row">
          <div class="form-group col-12">
            <FormLabel html-for="invoice_first_address">{{ t('client.attributes.first_address') }}</FormLabel>
            <FormInput id="invoice_first_address" v-model="invoiceFirstAddress" />
          </div>
          <div class="form-group col-12">
            <FormLabel html-for="invoice_second_address">{{ t('client.attributes.second_address') }}</FormLabel>
            <FormInput id="invoice_second_address" v-model="invoiceSecondAddress" />
          </div>
          <div class="form-group col-md-6">
            <FormLabel html-for="invoice_zip">{{ t('client.attributes.zip') }}</FormLabel>
            <FormInput id="invoice_zip" v-model="invoiceZip" />
          </div>
          <div class="form-group col-md-6">
            <FormLabel html-for="invoice_city">{{ t('client.attributes.city') }}</FormLabel>
            <FormInput id="invoice_city" v-model="invoiceCity" />
          </div>
          <div class="form-group col-md-6">
            <FormLabel html-for="invoice_country">{{ t('client.attributes.country') }}</FormLabel>
            <VueSelect
              v-if="countries.length > 0"
              :reduce="(option: ICountry) => option.code"
              :clearable="false"
              v-model="invoiceCountry"
              :options="countries"
              label="code"
              :get-option-label="(option: ICountry) => option.name[locale]"
              input-id="invoice_country"
              :placeholder="t('common.select')"
            />
          </div>
          <div class="form-group col-md-6">
            <FormLabel html-for="invoice_email" :required="invoiceDistribution === InvoiceDistributionType.EMAIL">
              {{ t('client.attributes.invoice_email') }}
            </FormLabel>
            <FormInput
              type="email"
              id="invoice_email"
              v-model="invoiceEmail"
              :required="invoiceDistribution === InvoiceDistributionType.EMAIL"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
