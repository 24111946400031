<script setup lang="ts">
import { computed, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { storeToRefs } from 'pinia';

import useActivityTemplatesStore from '@/store/ActivityTemplatesStore';
import ActivityTemplates from '@/components/ActivityTemplateSection/Templates.vue';
import ActivityGroups from '@/components/ActivityTemplateSection/Groups.vue';
import { HelpInformation } from '@/components';

enum PageTab {
  TEMPLATES = 'templates',
  GROUPS = 'groups',
}

const { t } = useI18n({ useScope: 'global' });
const activeTab: Ref<PageTab> = useRouteQuery('tab', PageTab.TEMPLATES);
const { lockedTabs } = storeToRefs(useActivityTemplatesStore());

const tabs = computed(() => [
  { name: PageTab.TEMPLATES, title: t('activities.templates.index.title') },
  { name: PageTab.GROUPS, title: t('activities.groups.index.title') },
]);
</script>

<template>
  <div class="container-wide">
    <div class="d-flex align-items-end mb-3">
      <h1 class="mb-0" v-text="t('activities.settings.title')" />
      <HelpInformation class="ml-1" translation="activities.settings.help" />
    </div>
    <ul class="tabs">
      <li
        :class="{ active: tab.name === activeTab, disabled: lockedTabs }"
        v-for="tab in tabs"
        :key="tab.name"
        class="tab-item"
      >
        <a class="tab-link" @click.prevent="activeTab = tab.name" href="#" v-text="tab.title" />
      </li>
    </ul>

    <div class="mt-4">
      <ActivityTemplates v-if="activeTab === PageTab.TEMPLATES" />
      <ActivityGroups v-else-if="activeTab === PageTab.GROUPS" />
    </div>
  </div>
</template>
