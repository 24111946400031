import { ref } from 'vue';
import { debounce } from 'lodash';

import api from '@/services/api';
import { IClientContact } from '@/types/Client';

export default function useClientContactSearch(clientUuid?: string) {
  const selectedContact = ref<IClientContact | null>(null);

  const contacts = ref<IClientContact[]>([]);

  const onSearch = debounce(async (search: string, loading: (isLoading: boolean) => void) => {
    if (!clientUuid) return;
    if (search.length <= 2) return;
    try {
      loading(true);
      const response = await api.clients.contacts.list(clientUuid, { search });
      contacts.value = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      loading(false);
    }
  }, 300);

  return { selectedContact, contacts, onSearch };
}
