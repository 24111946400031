<script setup lang="ts">
import { computed, reactive, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import { AppButton, FormLabel, FormInput } from '@/components';
import { IServiceCompanyContactRoleRequestBody } from '@/types/ServiceCompany';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  loading?: boolean;
  typeUuid: string;
  title?: string;
  name: null | string;
  roleUuid: null | string;
}>();

const form = reactive<IServiceCompanyContactRoleRequestBody>({
  name: '',
});

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'create', typeUuid: string, form: IServiceCompanyContactRoleRequestBody): void;
  (e: 'update', typeUuid: string, roleUuid: string, form: IServiceCompanyContactRoleRequestBody): void;
}>();

const disabled = computed(() => form.name === '');

function submit() {
  if (props.roleUuid) {
    emit('update', props.typeUuid, props.roleUuid, form);
  } else {
    emit('create', props.typeUuid, form);
  }
}

onMounted(async () => {
  form.name = props.name ?? '';
});
</script>

<template>
  <VueFinalModal class="modal-overlay" content-class="modal-container" :click-to-close="false" :esc-to-close="true">
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="props.title" />
      </div>
      <div class="modal-content">
        <!-- Name -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-md-3">
              <FormLabel html-for="role_name" required>{{ t('service-company.role.attributes.name') }}</FormLabel>
            </div>
            <div class="col-md-9">
              <FormInput v-model="form.name" id="role_name" trim required />
            </div>
          </div>
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between">
          <AppButton light @click.prevent="emit('cancel')" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton v-if="roleUuid" class="mt-2 mt-sm-0" color="success" :loading="loading" :disabled="disabled">
            {{ t('common.update') }}
          </AppButton>
          <AppButton v-else class="mt-2 mt-sm-0" color="secondary" :loading="loading" :disabled="disabled">
            {{ t('common.create') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
