<script setup lang="ts">
import { AppBox, AppBoxBody, AppButton, FontIcon, PlanningProgressMultiBar } from '@/components';
import useTime from '@/composables/useTime';
import { IEventTimelineWeek } from '@/types/Event';

defineProps<{
  expanded: boolean;
  data: IEventTimelineWeek['tasks']['events'][0];
  userWorkingTimeMinutes: number;
}>();

const emit = defineEmits<{
  (e: 'toggle', clientUuid: string): void;
}>();

const { convertMinutesToTime } = useTime();
</script>

<template>
  <AppBox shadow>
    <AppBoxBody>
      <div class="row d-flex align-items-center">
        <h3 class="col-3 mb-0">
          <RouterLink
            target="_blank"
            :to="{ name: 'clients.edit', params: { uuid: data.uuid } }"
            class="pointer unlink px-0"
          >
            <strong class="word-break" v-text="data.name" />
          </RouterLink>
        </h3>
        <div class="col-1 text-right">
          <AppButton size="small" @click.prevent="emit('toggle', data.uuid)" light circle>
            <FontIcon v-if="expanded" name="chevron-up" />
            <FontIcon v-else name="chevron-down" />
          </AppButton>
        </div>
        <div class="col-1 text-center">
          <strong v-text="convertMinutesToTime(data.time.budgeted)" />
        </div>
        <div class="col-1" />
        <div class="col-6">
          <PlanningProgressMultiBar
            :green="data.time.finished"
            :orange="data.time.reported"
            :gray="Math.max(data.time.remaining, 0)"
          />
        </div>
      </div>
    </AppBoxBody>
  </AppBox>
</template>
