<script setup lang="ts">
import { computed, ref, onMounted, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';

import api from '@/services/api';
import {
  AppAlert,
  AppLoader,
  AppTable,
  AppTableHead,
  AppTableBody,
  AppTableTr,
  AppTableTd,
  AppTableTh,
  FormLabel,
  HelpInformation,
} from '@/components';
import useLoader from '@/composables/useLoader';
import { ITimeSheetResource } from '@/types/TimeSheet';
import useTime from '@/composables/useTime';
import useDate from '@/composables/useDate';

const props = defineProps<{
  id: number;
}>();

const filters = reactive<{
  projectId: null | number;
  clientUuid: null | string;
  type: null | 'project_task' | 'activity';
}>({
  projectId: null,
  clientUuid: null,
  type: null,
});

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const { convertMinutesToTime } = useTime();
const { formatYearWeek } = useDate();

const timeSheet = ref<null | ITimeSheetResource>(null);

const filteredEvents = computed(() => {
  return (
    timeSheet.value?.eventTrackedTimes
      .filter((event) => (filters.type ? event.eventable_type === filters.type : event))
      .filter((event) => (filters.projectId ? event.eventable_data?.project?.id === filters.projectId : event))
      .filter((event) =>
        filters.clientUuid ? event.eventable_data?.project?.client?.uuid === filters.clientUuid : event,
      ) || []
  );
});

const includedProjects = ref<{ id: number; name: string }[]>([]);
const includedClients = ref<{ uuid: string; name: string }[]>([]);

async function getTimeSheet() {
  try {
    const response = await api.timeSheets.get(props.id);
    timeSheet.value = response.data;
    includedProjects.value = response.data.eventTrackedTimes.reduce<{ id: number; name: string }[]>((acc, current) => {
      if (current.eventable_data && current.eventable_data.project) {
        const { id, name } = current.eventable_data.project;
        if (!acc.some((item) => item.id === id)) {
          acc.push({ id, name });
        }
      }
      return acc;
    }, []);
    includedClients.value = response.data.eventTrackedTimes.reduce<{ uuid: string; name: string }[]>((acc, current) => {
      if (current.eventable_data && current.eventable_data.project && current.eventable_data.project.client) {
        const { uuid, name } = current.eventable_data.project.client;
        if (!acc.some((item) => item.uuid === uuid)) {
          acc.push({ uuid, name });
        }
      }
      return acc;
    }, []);
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  loader.start();
  await getTimeSheet();
  loader.finish();
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div v-if="timeSheet" class="container-fluid">
    <div class="d-flex align-items-end">
      <h1
        class="mb-0"
        v-text="
          t('time-sheet.show.title', {
            month: timeSheet.time_period.month.toString().padStart(2, '0'),
            year: timeSheet.time_period.year,
            username: timeSheet.user.name,
          })
        "
      />
      <HelpInformation class="ml-1" translation="time-sheet.show.help" />
    </div>
    <div class="row my-3" v-if="timeSheet.eventTrackedTimes.length > 0">
      <div class="form-group col-3">
        <FormLabel html-for="filters_type">{{ t(`event.tracked_time.attributes.type`) }}</FormLabel>
        <VueSelect
          v-model="filters.type"
          :options="[
            { value: 'project_task', name: t('event.type.project_task') },
            { value: 'activity', name: t('event.type.activity') },
          ]"
          label="name"
          :reduce="(option) => option.value"
          input-id="filters_type"
          :placeholder="t('common.select')"
        />
      </div>
      <div class="form-group col-3">
        <FormLabel html-for="filters_project_id">{{ t(`event.tracked_time.attributes.project`) }}</FormLabel>
        <VueSelect
          v-model="filters.projectId"
          :options="includedProjects"
          label="name"
          :reduce="(option) => option.id"
          input-id="filters_project_id"
          :placeholder="t('common.select')"
          :disabled="includedProjects.length === 0"
        />
      </div>
      <div class="form-group col-3">
        <FormLabel html-for="filters_client_uuid">{{ t(`event.tracked_time.attributes.client`) }}</FormLabel>
        <VueSelect
          v-model="filters.clientUuid"
          :options="includedClients"
          label="name"
          :reduce="(option) => option.uuid"
          input-id="filters_client_uuid"
          :placeholder="t('common.select')"
          :disabled="includedClients.length === 0"
        />
      </div>
    </div>
    <AppAlert class="mt-3" v-if="filteredEvents.length === 0">{{ t('common.empty') }}</AppAlert>
    <AppTable v-else hoverable>
      <AppTableHead>
        <AppTableTr>
          <AppTableTh nowrap>{{ t('event.tracked_time.attributes.name') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('event.tracked_time.attributes.type') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('event.tracked_time.attributes.project') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('event.tracked_time.attributes.client') }}</AppTableTh>
          <AppTableTh class="text-right" nowrap>{{ t('event.tracked_time.attributes.scheduled_time') }}</AppTableTh>
          <AppTableTh class="text-right" nowrap>{{ t('event.tracked_time.attributes.estimated_time') }}</AppTableTh>
          <AppTableTh class="text-right" nowrap>{{ t('event.tracked_time.attributes.reported_time') }}</AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr v-for="(event, index) in filteredEvents" :key="index">
          <AppTableTd nowrap>
            <span v-if="event.eventable_data?.name" v-text="event.eventable_data?.name" />
            <i v-else class="text-neutral-300" v-text="t('common.empty')" />
          </AppTableTd>
          <AppTableTd nowrap>{{ t(`event.type.${event.eventable_type}`) }}</AppTableTd>
          <AppTableTd nowrap>
            <span v-if="event.eventable_type === 'project_task'" v-text="event.eventable_data?.project?.name" />
            <i v-else class="text-neutral-300" v-text="t('common.empty')" />
          </AppTableTd>
          <AppTableTd nowrap>
            <span v-if="event.eventable_type === 'project_task'" v-text="event.eventable_data?.project?.client?.name" />
            <i v-else class="text-neutral-300" v-text="t('common.empty')" />
          </AppTableTd>
          <AppTableTd class="text-right" nowrap>{{ formatYearWeek(event.event.week) }}</AppTableTd>
          <AppTableTd class="text-right" nowrap>
            <span
              v-if="event.event.original_scheduled_time_unit"
              v-text="convertMinutesToTime(event.event.original_scheduled_time)"
            />
          </AppTableTd>
          <AppTableTd class="text-right" nowrap>{{ convertMinutesToTime(event.time) }}</AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>
  </div>
</template>
