<script setup lang="ts">
import { computed, inject, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { nanoid } from 'nanoid';

import useLoader from '@/composables/useLoader';
import useTime from '@/composables/useTime';
import {
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
} from '@/components';

import api from '@/services/api';
import toast from '@/services/toast';
import useProject from '@/composables/useProject';
import useProjectStore from '@/store/ProjectStore';
import useHelpers from '@/composables/useHelpers';

import { IServiceResource } from '@/types/Service';
import {
  IProjectTaskResource,
  IProjectTaskSummaryView,
  IPlannedProjectTaskResource,
  ProjectTaskPriceType,
} from '@/types/ProjectTask';
import { ProjectPriceType } from '@/types/Common';
import {
  IGetProjectTasksEstimatesRequestBody,
  IProjectEstimates,
  IProjectResource,
  IProjectRequestActivateStepBody,
  IProjectTaskEstimates,
  ProjectStep,
} from '@/types/Project';
import { ClientTabs } from '@/types/Client';
import useDate from '@/composables/useDate';

const projectId = inject<number>('projectId') as number;
const clientUuid = inject<string>('clientUuid') as string;

const { calculateAverageCost } = useProject();
const projectStore = useProjectStore();
const { setProject, lockTabs, unlockTabs } = projectStore;
const { project, isDraft, isActive, plannedTasksExists, plannedBillingPlansExists } = storeToRefs(projectStore);
const router = useRouter();
const { convertMinutesToTime } = useTime();
const { formatYearWeek } = useDate();
const { parseAmount } = useHelpers();

const { t, n } = useI18n({ useScope: 'global' });
const loader = useLoader({ useProgress: false });
const activateLoader = useLoader({ useProgress: false });

const emit = defineEmits<{
  (e: 'change-step', step: ProjectStep): void;
}>();

const form = reactive<IProjectRequestActivateStepBody>({
  step: ProjectStep.Activate,
});

const service = ref<null | IServiceResource>(null);

const estimates = ref<null | IProjectEstimates>(null);

const tasksEstimates = reactive<IProjectTaskEstimates>({
  tasks: [],
  plannedTasks: [],
});

const billingPlans = computed(() => {
  if (project.value === null) {
    return [];
  }
  return [...project.value.billing_plans].sort((a, b) => (a.year !== b.year ? a.year - b.year : a.month - b.month));
});

const plannedBillingPlans = computed(() => {
  if (project.value === null) {
    return [];
  }
  return [...project.value.planned_billing_plans].sort((a, b) =>
    a.year !== b.year ? a.year - b.year : a.month - b.month,
  );
});

const allTasks = computed<IProjectTaskSummaryView[]>(() => {
  const list: IProjectTaskSummaryView[] = [];
  if (project.value) {
    project.value.tasks.forEach((task) => {
      if (plannedTasksIds.value.includes(task.id)) return;
      list.push({
        uid: nanoid(),
        id: task.id,
        name: task.name,
        default_frequency: task.default_frequency,
        task_week: task.task_week,
        default_time_budget: task.default_time_budget,
        custom_price: task.custom_price,
        custom_price_type: task.custom_price_type,
        custom_price_value: parseAmount(task.custom_price_value),
        user: task.user ?? null,
        order: task.serviceTask.order ?? 9999,
        is_planned: false,
        is_deleted: false,
      });
    });
    project.value.planned_tasks.forEach((task) => {
      list.push({
        uid: nanoid(),
        id: task.id,
        name: task.name,
        default_frequency: task.default_frequency,
        task_week: task.task_week,
        default_time_budget: task.default_time_budget,
        custom_price: task.custom_price,
        custom_price_type: task.custom_price_type,
        custom_price_value: parseAmount(task.custom_price_value),
        user: task.user,
        order: task.serviceTask.order ?? 9999,
        is_planned: true,
        is_deleted: task.is_deleted,
      });
    });
    return list.sort((a: IProjectTaskSummaryView, b: IProjectTaskSummaryView) => {
      if (a.task_week.toString().slice(0, 4) !== b.task_week.toString().slice(0, 4)) {
        return +a.task_week.toString().slice(0, 4) - +b.task_week.toString().slice(0, 4);
      }
      if (a.task_week % 100 !== b.task_week % 100) {
        return (a.task_week % 100) - (b.task_week % 100);
      }
      return a.order! - b.order!;
    });
  }
  return [];
});

async function submit() {
  lockTabs();
  activateLoader.start();
  try {
    const response = await api.projects.update(form, clientUuid, projectId);
    setProject(response.data);
    await router.push({ name: 'clients.edit', params: { uuid: clientUuid }, query: { tab: ClientTabs.Overview } });
    toast.success(t('project.messages.project_has_been_activated.text'));
  } catch (error) {
    console.error(error);
  } finally {
    activateLoader.finish();
    unlockTabs();
  }
}

async function getService(uuid: string) {
  try {
    const response = await api.services.get(uuid);
    service.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

function taskViewToSummaryEstimatesRequest(task: IProjectTaskResource | IPlannedProjectTaskResource) {
  return {
    task_week: task.task_week.toString(),
    user_uuid: task.user?.uuid ?? null,
    default_time_budget: task.default_time_budget,
    default_frequency: task.default_frequency,
    custom_price: task.custom_price,
    custom_price_value: task.custom_price_value,
    custom_price_type: task.custom_price_type,
  };
}

const plannedTasksIds = computed(() => {
  if (!project.value) return [];
  return project.value.planned_tasks.map(({ parent_project_task_id }) => parent_project_task_id).filter(Boolean);
});

async function getSummaryEstimates() {
  if (!project.value) return;
  try {
    estimates.value = await api.projects.summaryEstimates(clientUuid, projectId, {
      json: {
        tasks: [
          ...project.value.tasks
            .filter(({ id }) => !plannedTasksIds.value.includes(id))
            .map(taskViewToSummaryEstimatesRequest),
          ...project.value.planned_tasks.filter(({ is_deleted }) => !is_deleted).map(taskViewToSummaryEstimatesRequest),
        ],
      },
    });
  } catch (error) {
    console.error(error);
  }
}

async function getTasksEstimates(clientUuid: string, project: IProjectResource) {
  try {
    const body: IGetProjectTasksEstimatesRequestBody = {
      tasks: project.tasks.map(({ id }) => id).filter((id) => !plannedTasksIds.value.includes(id)),
      planned_tasks: project.planned_tasks.filter(({ is_deleted }) => !is_deleted).map((task) => task.id),
    };
    const response = await api.projects.tasks.getEstimates(clientUuid, project.id, body);
    tasksEstimates.tasks = response.tasks;
    tasksEstimates.plannedTasks = response.plannedTasks;
  } catch (error) {
    console.error(error);
  }
}

function getTaskProductionCost(taskId: number): number {
  const taskEstimates = tasksEstimates.tasks.find((item) => item.id === taskId);
  return taskEstimates?.production_cost ?? 0;
}

function getPlannedTaskProductionCost(plannedTaskId: number): number {
  const taskEstimates = tasksEstimates.plannedTasks.find((item) => item.id === plannedTaskId);
  return taskEstimates?.production_cost ?? 0;
}

onMounted(async () => {
  lockTabs();
  loader.start();
  if (project.value) {
    await getService(project.value.service.uuid);
    await getSummaryEstimates();
    await getTasksEstimates(clientUuid, project.value);
  }
  loader.finish();
  unlockTabs();
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <form v-else @submit.prevent="submit">
    <AppTable v-if="project">
      <AppTableHead>
        <AppTableTr>
          <AppTableTh colspan="8">
            <h2 class="mb-0 text-neutral-900">{{ t('project.task_details_table.title') }}</h2>
          </AppTableTh>
        </AppTableTr>
        <AppTableTr>
          <AppTableTh nowrap>{{ t('project.task_details_table.task') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('project.task_details_table.frequency') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('project.task_details_table.week') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('project.task_details_table.est_time') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('project.task_details_table.team_member') }}</AppTableTh>
          <AppTableTh nowrap>{{ t('project.task_details_table.price_info') }}</AppTableTh>
          <AppTableTh nowrap class="text-right">{{ t('project.task_details_table.production_cost') }}</AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr
          v-for="task in allTasks"
          :class="{ 'text-secondary-500': task.is_planned && !task.is_deleted, 'text-danger-500': task.is_deleted }"
          :key="task.uid"
        >
          <AppTableTd nowrap>{{ task.name }}</AppTableTd>
          <AppTableTd nowrap>{{ t(`task.frequency.${task.default_frequency || 'once'}`) }}</AppTableTd>
          <AppTableTd nowrap>{{ formatYearWeek(task.task_week) }}</AppTableTd>
          <AppTableTd nowrap>{{ convertMinutesToTime(parseAmount(task.default_time_budget)) }}</AppTableTd>
          <AppTableTd nowrap>{{ task.user?.name ?? t('common.noname') }}</AppTableTd>
          <AppTableTd nowrap>
            <template v-if="task.custom_price">
              <span v-if="task.custom_price_type === ProjectTaskPriceType.FIXED">
                {{ t('common.fixed') }}, {{ n(parseAmount(task.custom_price_value), 'currency') }}
              </span>
              <span v-if="task.custom_price_type === ProjectTaskPriceType.HOURLY_DISCOUNT">
                {{ t('common.hourly') }}, {{ t('common.discount') }} {{ task.custom_price_value }} %
              </span>
            </template>
          </AppTableTd>
          <AppTableTd nowrap class="text-right">
            <span v-if="!task.is_planned" v-text="n(getTaskProductionCost(task.id), 'currency')" />
            <span v-else v-text="n(getPlannedTaskProductionCost(task.id), 'currency')" />
          </AppTableTd>
        </AppTableTr>
        <AppTableTr v-if="estimates" class="font-bold">
          <AppTableTd colspan="3" nowrap>
            {{ t('project.task_details_table.total_this_project') }}
          </AppTableTd>
          <AppTableTd colspan="3" nowrap>
            {{ convertMinutesToTime(estimates.current_project.total_minutes) }}
          </AppTableTd>
          <AppTableTd class="text-right" nowrap>
            {{ n(estimates.current_project.internal_total_price, 'currency') }}
          </AppTableTd>
        </AppTableTr>
        <AppTableTr v-if="estimates" class="text-neutral-500 font-bold">
          <AppTableTd colspan="3" nowrap>
            {{ t('project.task_details_table.total_next_project') }}
          </AppTableTd>
          <AppTableTd colspan="3" nowrap>
            {{ convertMinutesToTime(estimates.next_project.total_minutes) }}
          </AppTableTd>
          <AppTableTd class="text-right" nowrap>
            {{ n(estimates.next_project.internal_total_price, 'currency') }}
          </AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>

    <AppTable v-if="project" class="mt-3">
      <AppTableHead>
        <AppTableTr>
          <AppTableTh colspan="8">
            <h2 class="mb-0 text-neutral-900">{{ t('project.fee_and_invoicing_table.title') }}</h2>
          </AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr>
          <AppTableTd nowrap style="width: 200px">
            <strong>{{ t('project.fee_and_invoicing_table.price_type') }}</strong>
          </AppTableTd>
          <AppTableTd nowrap>{{ t(`service.price_type.${project.price_type}`) }}</AppTableTd>
        </AppTableTr>
        <AppTableTr v-if="project.price_type === ProjectPriceType.FIXED">
          <AppTableTd nowrap>
            <strong>{{ t('project.fee_and_invoicing_table.invoice_frequency') }}</strong>
          </AppTableTd>
          <AppTableTd nowrap>{{ t(`service.invoice_frequency.${project.billing_frequency}`) }}</AppTableTd>
        </AppTableTr>
        <AppTableTr v-if="project.price_type === ProjectPriceType.HOURLY">
          <AppTableTd nowrap>
            <strong>{{ t('project.fee_and_invoicing_table.discount') }}</strong>
          </AppTableTd>
          <AppTableTd nowrap>{{ project.discount || '0' }}%</AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>

    <template v-if="project">
      <AppTable class="mt-3">
        <AppTableHead>
          <AppTableTr>
            <AppTableTh colspan="8">
              <h2 class="mb-0 text-neutral-900">
                {{ t('project.invoice_schedule') }}
              </h2>
            </AppTableTh>
          </AppTableTr>
          <AppTableTr>
            <AppTableTh style="width: 200px" nowrap>{{ t('project.invoices_table.amount') }}</AppTableTh>
            <AppTableTh style="width: 200px" nowrap>{{ t('project.invoices_table.fee_type') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.invoices_table.month') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="(billingPlan, index) in billingPlans" :key="index">
            <AppTableTd nowrap>{{ n(parseAmount(billingPlan.price), 'currency') }}</AppTableTd>
            <AppTableTd nowrap v-if="billingPlan.type === 'base_fee'">{{ t('project.base_price') }}</AppTableTd>
            <AppTableTd nowrap v-if="billingPlan.type === 'separate_fee'">
              {{ t('project.separate_price') }}
            </AppTableTd>
            <AppTableTd nowrap v-if="billingPlan.type === 'hourly_fee'">
              {{ t('project.estimated_hourly_fee') }}
            </AppTableTd>
            <AppTableTd nowrap> {{ billingPlan.year }}-{{ billingPlan.month.toString().padStart(2, '0') }} </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
      <AppTable v-if="plannedBillingPlans.length > 0" class="mt-3">
        <AppTableHead>
          <AppTableTr>
            <AppTableTh colspan="8">
              <h2 class="mb-0 text-neutral-900">
                {{ t('project.invoice_schedule') + (isActive ? ` (${t('project.statuses.draft')})` : '') }}
              </h2>
            </AppTableTh>
          </AppTableTr>
          <AppTableTr>
            <AppTableTh style="width: 200px" nowrap>{{ t('project.invoices_table.amount') }}</AppTableTh>
            <AppTableTh style="width: 200px" nowrap>{{ t('project.invoices_table.fee_type') }}</AppTableTh>
            <AppTableTh nowrap>{{ t('project.invoices_table.month') }}</AppTableTh>
          </AppTableTr>
        </AppTableHead>
        <AppTableBody>
          <AppTableTr v-for="(billingPlan, index) in plannedBillingPlans" :key="index">
            <AppTableTd nowrap>{{ n(parseAmount(billingPlan.price), 'currency') }}</AppTableTd>
            <AppTableTd nowrap v-if="billingPlan.type === 'base_fee'">{{ t('project.base_price') }}</AppTableTd>
            <AppTableTd nowrap v-if="billingPlan.type === 'separate_fee'">
              {{ t('project.separate_price') }}
            </AppTableTd>
            <AppTableTd nowrap v-if="billingPlan.type === 'hourly_fee'">
              {{ t('project.estimated_hourly_fee') }}
            </AppTableTd>
            <AppTableTd nowrap> {{ billingPlan.year }}-{{ billingPlan.month.toString().padStart(2, '0') }} </AppTableTd>
          </AppTableTr>
        </AppTableBody>
      </AppTable>
    </template>
    <AppTable v-if="estimates" class="mt-3">
      <AppTableHead>
        <AppTableTr>
          <AppTableTh colspan="8">
            <h2 class="mb-0 text-neutral-900">{{ t('project.project_earnings_estimate_title.title') }}</h2>
          </AppTableTh>
        </AppTableTr>
        <AppTableTr>
          <AppTableTh style="width: 200px" nowrap></AppTableTh>
          <AppTableTh style="width: 200px" nowrap>
            {{ t('project.project_earnings_estimate_title.first_year') }}
          </AppTableTh>
          <AppTableTh nowrap>
            {{ t('project.project_earnings_estimate_title.next_year') }}
          </AppTableTh>
        </AppTableTr>
      </AppTableHead>
      <AppTableBody>
        <AppTableTr>
          <AppTableTd nowrap>
            <strong>{{ t('project.project_earnings_estimate_title.annual_price') }}</strong>
          </AppTableTd>
          <AppTableTd nowrap>{{ n(estimates.current_project.external_total_price, 'currency') }}</AppTableTd>
          <AppTableTd nowrap>{{ n(estimates.next_project.external_total_price, 'currency') }}</AppTableTd>
        </AppTableTr>
        <AppTableTr>
          <AppTableTd nowrap>
            <strong>{{ t('project.project_earnings_estimate_title.annual_hours') }}</strong>
          </AppTableTd>
          <AppTableTd nowrap>{{ convertMinutesToTime(estimates.current_project.total_minutes) }}</AppTableTd>
          <AppTableTd nowrap>{{ convertMinutesToTime(estimates.next_project.total_minutes) }}</AppTableTd>
        </AppTableTr>
        <AppTableTr>
          <AppTableTd nowrap>
            <strong>{{ t('project.project_earnings_estimate_title.revenue_hour') }}</strong>
          </AppTableTd>
          <AppTableTd nowrap>
            {{
              calculateAverageCost(
                estimates.current_project.external_total_price,
                estimates.current_project.total_minutes,
              )
            }}/{{ t('project.planning_table.hour') }}
          </AppTableTd>
          <AppTableTd nowrap>
            {{
              calculateAverageCost(estimates.next_project.external_total_price, estimates.next_project.total_minutes)
            }}/{{ t('project.planning_table.hour') }}
          </AppTableTd>
        </AppTableTr>
        <AppTableTr>
          <AppTableTd nowrap>
            <strong>{{ t('project.project_earnings_estimate_title.production_cost') }}</strong>
          </AppTableTd>
          <AppTableTd nowrap>{{ n(estimates.current_project.internal_total_price, 'currency') }}</AppTableTd>
          <AppTableTd nowrap>{{ n(estimates.next_project.internal_total_price, 'currency') }}</AppTableTd>
        </AppTableTr>
        <AppTableTr>
          <AppTableTd nowrap>
            <strong>{{ t('project.project_earnings_estimate_title.margin') }}</strong>
          </AppTableTd>
          <AppTableTd nowrap>{{ n(estimates.current_project.marginality, 'currency') }}</AppTableTd>
          <AppTableTd nowrap>{{ n(estimates.next_project.marginality, 'currency') }}</AppTableTd>
        </AppTableTr>
        <AppTableTr>
          <AppTableTd nowrap>
            <strong>{{ t('project.project_earnings_estimate_title.margin_percent') }}</strong>
          </AppTableTd>
          <AppTableTd nowrap>{{ estimates.current_project.marginality_percent }} %</AppTableTd>
          <AppTableTd nowrap>{{ estimates.next_project.marginality_percent }} %</AppTableTd>
        </AppTableTr>
      </AppTableBody>
    </AppTable>

    <div class="mt-3 d-flex flex-nowrap">
      <AppButton
        @click.prevent="emit('change-step', ProjectStep.Planning)"
        light
        :disabled="activateLoader.isLoading.value"
      >
        {{ t('common.back') }}
      </AppButton>
      <AppButton
        class="ml-auto"
        v-if="isDraft || plannedTasksExists || plannedBillingPlansExists"
        color="secondary"
        :loading="activateLoader.isLoading.value"
      >
        {{ t('project.active_project') }}
      </AppButton>
    </div>
  </form>
</template>
