export default {
  index: {
    title: 'ToDo & messages',
    help: '',
    create: 'Create',
  },
  attributes: {
    type: 'Type',
    receiver: 'Receiver',
    text: 'Text',
    deadline: 'Deadline',
    status: 'Status',
    created_by: 'Created by',
    updated_at: 'Updated at',
  },
  filters: {
    search: 'Search',
    from_date: 'From date',
    to_date: 'To date',
    created_by: 'Created by',
    receiver: 'Receiver',
    client: 'Client',
    project: 'Project',
    type: 'Type',
    status: 'Status',
  },
  tooltip: {
    complete: 'Complete todo',
    edit: 'Edit todo',
    destroy: 'Delete todo',
  },
  empty: 'No todos',
  // Confirms
  confirm: {
    destroy: {
      title: 'Delete todo',
      text: 'Are you sure you want to delete this todo from the system?',
    },
  },
};
