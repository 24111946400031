<script lang="ts" setup>
import { ref, onMounted } from 'vue';

import progress from '@/services/progress';
import api from '@/services/api';
import useAuthStore from '@/store/AuthStore';

const preloaded = ref(false);
const { authenticatedUser, setAuthenticatedUser, updateAuthenticatedUser, unsetAuthenticatedUser } = useAuthStore();

onMounted(async () => {
  const fragment = window.location.href.split('#')[1] || '';
  const searchParams = Object.fromEntries(new URLSearchParams(fragment));
  if (searchParams['/accept-invite?invite']) {
    preloaded.value = true;
    return;
  }
  try {
    progress.start();
    const response = await api.users.currentAuthenticatedUser();
    updateAuthenticatedUser(response);
    const { _csi_user_uuid, _csi_token } = Object.fromEntries(new URLSearchParams(window.location.search));
    if ((_csi_user_uuid && _csi_token) || !authenticatedUser) {
      setAuthenticatedUser(response);
    }
  } catch (error) {
    console.error(error);
    unsetAuthenticatedUser();
  } finally {
    progress.done();
  }
  preloaded.value = true;
});
</script>
<template>
  <component v-if="preloaded" :is="$route.meta.layout">
    <router-view :key="$route.path" />
  </component>
</template>
