<script lang="ts" setup>
const props = defineProps<{
  nowrap?: boolean;
  nohover?: boolean;
}>();
</script>

<template>
  <td v-bind="$attrs" :class="{ nowrap: props.nowrap, nohover: props.nohover }">
    <slot />
  </td>
</template>
