<script setup lang="ts">
import { VueFinalModal } from 'vue-final-modal';
import { useI18n } from 'vue-i18n';

import { AppButton } from '@/components';
import { EventType } from '@/types/Event';

const { t } = useI18n({ useScope: 'global' });

defineProps<{
  type: EventType;
  loading?: boolean;
  loadingWithFutureEvents?: boolean;
  hasEvents?: boolean;
}>();

const emit = defineEmits<{
  closed: [];
  confirm: [withFutureEvents: boolean];
}>();
</script>
<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="false"
    v-slot="{ close }"
    @closed="emit('closed')"
  >
    <div class="modal-header">
      <h2 class="mb-0" v-text="t('event.confirm.destroy.title')" />
    </div>
    <div class="modal-content">
      <p v-html="t('event.confirm.destroy.text')" />
      <slot />
    </div>
    <div class="modal-footer">
      <div class="d-flex">
        <AppButton light :disabled="loading || loadingWithFutureEvents" @click="close">
          {{ t('common.cancel') }}
        </AppButton>
        <div class="d-flex ml-auto">
          <AppButton
            v-if="hasEvents"
            class="mr-2"
            color="danger"
            :loading="loadingWithFutureEvents"
            :disabled="loading"
            @click="emit('confirm', true)"
          >
            {{ t(`event.confirm.destroy.${type}.confirm_with_future_events`) }}
          </AppButton>
          <AppButton
            color="danger"
            :loading="loading"
            :disabled="loadingWithFutureEvents"
            @click="emit('confirm', false)"
          >
            {{ t(`event.confirm.destroy.${type}.confirm`) }}
          </AppButton>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>
