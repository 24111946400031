import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import ActivityTemplates from '@/views/Activities/ActivityTemplates.vue';
import ActivityTemplate from '@/views/Activities/ActivityTemplate.vue';
import MySchedule from '@/views/Activities/MySchedule.vue';
import useAuthStore from '@/store/AuthStore';

const prefix = 'activities';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}/templates`,
    name: `${prefix}.templates.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ActivityTemplates,
    beforeEnter: (to, from, next) => {
      const { isCustomerAdminRole, isSuperAdminRole } = useAuthStore();
      if (isCustomerAdminRole || isSuperAdminRole) {
        return next();
      }
      return next({ name: 'dashboard' });
    },
  },
  {
    path: `/${prefix}/templates/create`,
    name: `${prefix}.templates.create`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ActivityTemplate,
    beforeEnter: (to, from, next) => {
      const { isCustomerAdminRole, isSuperAdminRole } = useAuthStore();
      if (isCustomerAdminRole || isSuperAdminRole) {
        return next();
      }
      return next({ name: 'dashboard' });
    },
  },
  {
    path: `/${prefix}/templates/:groupId/:id/edit`,
    name: `${prefix}.templates.edit`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: ActivityTemplate,
    beforeEnter: (to, from, next) => {
      const { isCustomerAdminRole, isSuperAdminRole } = useAuthStore();
      if (isCustomerAdminRole || isSuperAdminRole) {
        return next();
      }
      return next({ name: 'dashboard' });
    },
    props: (route) => ({
      ...route.params,
      groupId: Number(route.params.groupId),
      id: Number(route.params.id),
    }),
  },
  {
    path: `/${prefix}`,
    name: `${prefix}.my-schedule.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: MySchedule,
  },
];

export default routes;
