export default {
  index: {
    title: 'Projekt',
    help: '',
    remove: 'Radera projekt',
    complete: 'Complete projekt',
    cancel: 'Stäng project',
    rollover: 'Rulla projekt',
    reactivate: 'Reaktivera projekt',
    filters: {
      name_or_client: 'Projekt eller kund',
      name: 'Projekt',
    },
    create_modal: {
      title: 'Ange vilken kund du vill skapa ett projekt för',
      name: 'Kund',
    },
  },
  create: {
    title: 'Planera nytt projekt för {name}',
    help: '',
  },
  edit: {
    title: 'Redigera projekt för {name}',
    help: '',
  },

  empty: 'Inga projekt',

  step: {
    service: 'Tjänst',
    price: 'Pris',
    team: 'Team',
    planning: 'Uppgiftsplanering',
    activate: 'Sammanfattning',
  },

  // Attributes
  attributes: {
    name: 'Projektnamn',
    status: 'Status',
    client: 'Kund',
    user: 'Projektansvarig',
    service: 'Tjänst',
    financial_year: 'Räkenskapsår',
    start_date: 'Startdatum',
    end_date: 'Slutdatum',
    start_end_date: 'Start / slutdatum',
    start_and_end_date: 'Projektets start- och slutdatum',
    deadline_date: 'Leveransdatum',
    price_type: 'Pristyp',
    billing_frequency: 'Faktureringsintervall',
    annual_price: 'Årligt grundarvode',
    only_invoice_part_of_year: 'Fakturera endast del av år',
    number_of_months_for_invoices_per_part_of_year: 'Antal månader',
    estimating_price: 'Detta årets arvode',
    discount: 'Rabatt %',
    this_year_fee: 'Detta årets arvode',
    estimating_annual_fee: 'Beräknat årligt arvode',
    tasks_in_portal: 'Uppgifter i kundportal',
  },
  placeholders: {
    name: 'Ange namn',
  },

  invoice_schedule: 'Fakturaschema',

  price: {
    fixed: 'Fast',
    hourly: 'Timarvode',
  },

  billing_frequency: {
    monthly: 'Månad',
    quarterly: 'Kvartal',
    'semi-annually': 'Halvår',
    annually: 'Årligen',
  },

  add_member_title: 'Välj en anställd och roll i projektet för att lägga till',
  team_member_role: {
    team_member: 'Team-medlem',
    responsible: 'Projektansvarig',
  },
  project_role: 'Projektroll',

  planning_table: {
    summary: 'Sammanfattning',
    total_time: 'Summa tid',
    total_price: 'Summa arvode',
    margin: 'Marginal %',
    this_project: 'Detta projektår',
    next_project: 'Nästa projektår',
    hour: 'h',
  },

  tasks_table: {
    name: 'Uppgift',
    frequency: 'Frekvens',
    week: 'Vecka',
    deadline: 'Deadline',
    estimated_time: 'Uppskattad tid',
    team_member: 'Team-medlem',
    charge_separately: 'Debitera extra',
    price_type: 'Pristyp',
    sub_label: 'Undernamn',
    amount: 'Belopp, SEK',
    discount: 'Rabatt, %',
    add_label_on_tasks: 'Lägg till extra etikett på uppgift',
    restore_plan_from_default: 'Återställ plan till standard',
  },

  task_details_table: {
    title: 'Uppgiftsdetaljer',
    task: 'Uppgift',
    frequency: 'Frekvens',
    week: 'Vecka',
    deadline: 'Deadline',
    est_time: 'Uppskattad tid',
    team_member: 'Team-medlem',
    price_info: 'Prisinfo',
    production_cost: 'Självkostnad',
    total_this_project: 'Summa detta projektår',
    total_next_project: 'Summa nästa projektår',
  },

  fee_and_invoicing_table: {
    title: 'Arvode & fakturering',
    price_type: 'Pristyp',
    invoice_frequency: 'Faktureringsintervall',
    discount: 'Rabatt',
  },

  invoices_table: {
    title: 'Fakturor',
    amount: 'Belopp',
    fee_type: 'Arvodestyp',
    month: 'Månad',
  },
  project_earnings_estimate_title: {
    title: 'Beräknad projektmarginal',
    first_year: 'Detta projektår',
    next_year: 'Nästa projektår',
    annual_price: 'Årsarvode',
    annual_hours: 'Årstimmar',
    revenue_hour: 'Arvode/timma',
    production_cost: 'Självkostnad',
    margin: 'Marginal',
    margin_percent: 'Marginal %',
  },

  statuses: {
    all: 'Alla',
    active: 'Aktiv',
    draft: 'Utkast',
    done: 'Klar',
    cancelled: 'Avbruten',
    deleted: 'Raderad',
  },

  active_project: 'Aktivera projekt',
  base_price: 'Grundarvode',
  separate_price: 'Separat extra arvode',
  estimated_hourly_fee: 'Timarvode (beräknat)',

  summaries: {
    invoiced: 'Fakturerat',
    scheduled: 'Schemalagt',
    fee_summary: 'Summa arvoden',
    difference: 'Differens',
  },

  // Tooltips
  tooltip: {
    edit: 'Redigera "{name}"',
    destroy: 'Radera "{name}"',
    view: 'Granska "{name}" projektet',
    plan: 'Planera "{name}" projektet',
    cancel: 'Avbryt "{name}" projektet',
    rollover: 'Rulla "{name}"',
    reactivate: 'Reaktivera "{name}"',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Radera projekt',
      text: 'Är du säker på att du vill radera <strong>{name}</strong>?',
    },
    restore_default_tasks: {
      title: 'Återställ till standarduppgifter',
      text: 'Är du säker på att du vill återställa till standarduppgifter?',
    },
  },

  messages: {
    the_fee_summary_does_not_match: {
      title: 'Summa arvoden avviker mot angivet projektarvode.',
      text: 'Uppdatera schemalagda arvoden eller projektets arvode?',
    },
    project_dates_updated: {
      title: 'Projektdatum är uppdaterade.',
      text: 'Du kan behöva ändra schemalagda fakturor och tidsplan för uppgifter. Fortsätt med planering av projektet för att göra eventuellt nödvändiga följdändringar.',
    },
    project_has_been_activated: {
      text: 'Projektet har skapats/uppdaterats och uppgifter har schemalagts.',
    },
  },

  view: {
    overview: 'Översikt',
    status: 'Status',
    deadline: 'Deadline',
    none: 'Ingen',
    tasks: 'Uppgifter',
    comment: 'Kommentar',
    there_are_unfinished_tasks:
      'Det finns uppgifter som inte har slutförts. Kontrollera dessa innan du stänger projektet.',
    there_are_no_unfinished_tasks: 'Alla uppgifter är klara',
    cancel_all_open_tasks: 'Cancel all open tasks',
    all_tasks_must_be_completed_to_close_project: 'All project tasks must be completed to close the project',
  },

  cancel_modal: {
    title: 'Stäng project {name}',
    message:
      'Genom att klicka på "Bekräfta", stängs projekt och blir inaktiverat. Du kan reaktivera det senare om du behöver.',
    warning:
      'Projektet har uppgifter som inte påbörjats eller slutförts. Genom att stänga projektet, kommer påbörjade uppgifter klarmarkeras, och ej startade uppgifter kommer tas bort från uppgiftslistan och går ej att rapportera tid på.',
    start_new_project: 'Starta planering av ett nytt projekt genom att kopiera planering för projekt {name}',
    increase_prices: 'Räkna upp eventuella fasta priser automatiskt',
    percentage_price_increase: 'Ange procentuell prisökning för fasta priser ',
  },
  reactivate_modal: {
    title: 'Reaktivera projekt {name}',
    message: '',
  },
  rollover_modal: {
    title: 'Rulla projekt {name}',
  },
};
