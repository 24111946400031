<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import VueSelect from 'vue-select';
import { nanoid } from 'nanoid';
import { useModal } from 'vue-final-modal';
import { animations } from '@formkit/drag-and-drop';
import { useDragAndDrop } from '@formkit/drag-and-drop/vue';

import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useTask from '@/composables/useTask';
import {
  ActivityGroupModal,
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ConfirmModal,
  FontIcon,
  FormInput,
  FormLabel,
  FormSwitch,
  HelpInformation,
} from '@/components';
import {
  ActivityCommentType,
  ActivityDurationRule,
  ActivityGroupType,
  IActivityGroupListResource,
  IActivityGroupResource,
  IActivityTemplateRequest,
  ICommentOptionResource,
  IMetaInputResource,
  MetaInputType,
} from '@/types/Activity';
import { ISalaryCodeResource } from '@/types/Salary';

const { taskFrequencyOptions } = useTask();
const loader = useLoader();
const submitLoader = useLoader();
const { t } = useI18n({ useScope: 'global' });
const router = useRouter();

type Props = {
  id?: number;
  groupId?: number;
};

const props = defineProps<Props>();

const [commentOptionsNodeRef, commentOptions, updateCommentOptionsConfig] = useDragAndDrop<ICommentOptionResource>([], {
  plugins: [animations()],
});

const metaInputTypeOptions = computed(() => [
  { value: 'input_text', label: t('activities.meta_input.type.input_text') },
]);

const form = reactive<Omit<IActivityTemplateRequest, 'comment_options'>>({
  name: '',
  duration_rule: null,
  meta_input: [],
  comment_type: ActivityCommentType.FREE_TEXT,
  include_in_planning: false,
  default_frequency: null,
  salary_code_id: null,
});

const name = ref('');

const disabled = computed(() => {
  if (props.id) {
    return form.name === '';
  }
  return activityGroup.value === null || form.name === '';
});

type DurationRuleOption = { value: null | string; label: string };

const durationRuleOptions = computed<DurationRuleOption[]>(() => [
  { value: null, label: t('activities.templates.duration_rule.none') },
  { value: ActivityDurationRule.AVAILABLE_ALL_DAY, label: t('activities.templates.duration_rule.available_all_day') },
  {
    value: ActivityDurationRule.AVAILABLE_TIME_PERCENTAGE_RANGE,
    label: t('activities.templates.duration_rule.available_time_percentage_range'),
  },
  { value: ActivityDurationRule.ONLY_ALL_DAY, label: t('activities.templates.duration_rule.only_all_day') },
]);

const activityType = ref<null | ActivityGroupType>(null);
const activityGroupsLoading = ref(false);
const activityGroup = ref<null | number>(null);
const activityGroups = ref<IActivityGroupListResource[]>([]);
const salaryCodesLoading = ref(false);
const salaryCodes = ref<ISalaryCodeResource[]>([]);

async function getActivityTemplate() {
  if (!props.groupId || !props.id) return;
  try {
    const response = await api.activityTemplates.get(props.groupId, props.id);
    form.name = response.data.name;
    form.comment_type = response.data.comment_type;
    name.value = response.data.name;
    form.duration_rule = response.data.duration_rule;
    form.meta_input = response.data.meta_input;
    form.include_in_planning = response.data.include_in_planning;
    form.default_frequency = response.data.default_frequency;
    form.salary_code_id = response.data.salary_code?.id ?? null;
    commentOptions.value = response.data.comment_options;
    activityType.value = response.data.activity_type;
  } catch (error) {
    console.error(error);
  }
}

async function getActivityGroups() {
  activityGroupsLoading.value = true;
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.activityGroups.index({ searchParams });
    activityGroups.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    activityGroupsLoading.value = false;
  }
}

async function getSalaryCodes() {
  try {
    salaryCodesLoading.value = true;
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.salary.codes.index({ searchParams });
    salaryCodes.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    salaryCodesLoading.value = false;
  }
}

function addMetaInput() {
  form.meta_input.push({ id: nanoid(48), label: '', is_required: false, type: MetaInputType.INPUT_TEXT });
}

function onDeleteMetaInput(metaInput: IMetaInputResource) {
  const { open, close, destroy } = useModal({
    component: ConfirmModal,
    attrs: {
      title: t('activities.meta_input.confirm.destroy.title'),
      message: t('activities.meta_input.confirm.destroy.text', {
        name: metaInput.label || t('common.noname'),
      }),
      onConfirm() {
        form.meta_input = form.meta_input.filter(({ id }) => id !== metaInput.id);
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function submit() {
  if (props.id) {
    await update();
  } else {
    await create();
  }
}

async function create() {
  if (activityGroup.value === null || activityType.value === null) return;
  try {
    submitLoader.start();
    const response = await api.activityTemplates.store(activityGroup.value, {
      json: {
        ...form,
        comment_options: form.comment_type === ActivityCommentType.DROPDOWN ? commentOptions.value : [],
      },
    });
    await router.push({
      name: 'activities.templates.edit',
      params: {
        groupId: response.data.activity_group.id,
        id: response.data.id,
      },
    });
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

async function update() {
  if (!props.groupId || !props.id) return;
  try {
    submitLoader.start();
    await api.activityTemplates.update(props.groupId, props.id, {
      json: {
        ...form,
        comment_options: form.comment_type === ActivityCommentType.DROPDOWN ? commentOptions.value : [],
      },
    });
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

function onCreateActivityGroup() {
  if (activityType.value === null) return;
  const { open, close, destroy } = useModal({
    component: ActivityGroupModal,
    attrs: {
      activityGroupType: activityType.value,
      activityGroup: null,
      async onCreated(createdActivityGroup: IActivityGroupResource) {
        await getActivityGroups();
        activityGroup.value = createdActivityGroup.id;
        await close();
      },
      onCancel() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function addCommentOption() {
  commentOptions.value.push({ id: nanoid(), option: '' });
}

function deleteCommentOption(commentOption: ICommentOptionResource) {
  commentOptions.value = commentOptions.value.filter((item) => item.id !== commentOption.id);
}

onMounted(async () => {
  loader.start();
  await Promise.all([getSalaryCodes(), getActivityGroups()]);
  if (props.id) {
    await getActivityTemplate();
  }
  loader.finish();
  updateCommentOptionsConfig({ plugins: [animations()] });
});
</script>
<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <form v-else @submit.prevent="submit" class="container-wide">
    <div class="d-flex align-items-center mb-4">
      <AppButton
        @click.prevent="router.push({ name: 'activities.templates.index' })"
        color="secondary"
        circle
        light
        v-tooltip="t('common.back')"
      >
        <FontIcon name="arrow-back-up" />
      </AppButton>
      <div class="d-flex align-items-end ml-3">
        <h1 v-if="props.id" class="mb-0" v-text="t('activities.templates.edit.title', { name })" />
        <h1 v-else class="mb-0" v-text="t('activities.templates.create.title')" />
        <HelpInformation
          class="ml-1"
          :translation="props.id ? 'activities.templates.edit.help' : 'activities.templates.create.help'"
        />
      </div>
    </div>
    <AppBox shadow>
      <AppBoxBody>
        <template v-if="!props.id">
          <!-- Type -->
          <div class="row align-items-center form-group">
            <div class="col-md-2">
              <FormLabel class="mb-0" html-for="activity_type" required>
                {{ t('activities.templates.attributes.type') }}
              </FormLabel>
            </div>
            <div class="col-md-4">
              <VueSelect
                :clearable="false"
                v-model="activityType"
                input-id="activity_type"
                :placeholder="t('common.select')"
                :options="Object.values(ActivityGroupType)"
                :get-option-label="(option: string) => t(`activities.groups.type.${option}`)"
                @update:model-value="
                  () => {
                    activityGroup = null;
                    form.duration_rule = null;
                  }
                "
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!activityType" v-bind="attributes as object" v-on="events" />
                </template>
              </VueSelect>
            </div>
          </div>

          <!-- Group -->
          <div class="row align-items-center form-group">
            <div class="col-md-2">
              <FormLabel class="mb-0" html-for="activity_group" required>
                {{ t('activities.templates.attributes.group') }}
              </FormLabel>
            </div>
            <div class="col-md-4 d-flex align-items-center">
              <div class="flex-grow-1">
                <VueSelect
                  :clearable="false"
                  v-model="activityGroup"
                  :reduce="(option: IActivityGroupListResource) => option.id"
                  :options="activityGroups.filter((group) => group.type === activityType)"
                  label="name"
                  input-id="activity_group"
                  :placeholder="t('common.select')"
                  :disabled="activityGroupsLoading || activityType === null"
                  :loading="activityGroupsLoading"
                />
              </div>
              <AppButton
                @click.prevent="onCreateActivityGroup"
                class="flex-shrink-0 ml-1"
                circle
                light
                :disabled="activityGroupsLoading || !activityType"
                color="success"
                v-tooltip.right="t('common.create')"
              >
                <FontIcon name="plus" />
              </AppButton>
            </div>
          </div>
        </template>
        <!-- Name -->
        <div class="row align-items-center form-group">
          <div class="col-md-2">
            <FormLabel class="mb-0" html-for="activity_name" required>
              {{ t('activities.templates.attributes.name') }}
            </FormLabel>
          </div>
          <div class="col-md-4">
            <FormInput id="activity_name" v-model.trim="form.name" required />
          </div>
        </div>
        <!-- Default frequency -->
        <div class="row align-items-center form-group">
          <div class="col-md-2">
            <FormLabel html-for="task_frequency" required>
              {{ t('activities.templates.attributes.default_frequency') }}
            </FormLabel>
          </div>
          <div class="col-md-4">
            <VueSelect
              :clearable="false"
              :searchable="false"
              v-model="form.default_frequency"
              :options="taskFrequencyOptions"
              label="label"
              :reduce="(option: any) => option.value"
              input-id="task_frequency"
              :placeholder="t('common.select')"
            />
          </div>
        </div>
        <!-- Duration rule -->
        <div class="row align-items-center form-group">
          <div class="col-md-2">
            <FormLabel class="mb-0" html-for="duration_rule" required>
              {{ t('activities.templates.attributes.duration_rule') }}
            </FormLabel>
          </div>
          <div class="col-md-4">
            <VueSelect
              :clearable="false"
              :filterable="false"
              :searchable="false"
              :reduce="(option: DurationRuleOption) => option.value"
              v-model="form.duration_rule"
              :options="durationRuleOptions"
              label="label"
              input-id="duration_rule"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!form.duration_rule" v-bind="attributes as object" v-on="events" />
              </template>
            </VueSelect>
          </div>
        </div>
        <!-- Salary code -->
        <div class="row align-items-center form-group">
          <div class="col-md-2">
            <FormLabel class="mb-0" html-for="salary_code" required>
              {{ t('activities.templates.attributes.salary_code') }}
            </FormLabel>
          </div>
          <div class="col-md-4">
            <VueSelect
              :clearable="false"
              v-model="form.salary_code_id"
              input-id="salary_code"
              :placeholder="t('common.select')"
              :options="salaryCodes"
              :reduce="(option: ISalaryCodeResource) => option.id"
              label="name"
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!form.salary_code_id"
                  v-bind="attributes as object"
                  v-on="events"
                />
              </template>
            </VueSelect>
          </div>
        </div>
        <!-- Comment type -->
        <div class="row form-group">
          <div class="col-md-2">
            <FormLabel class="mb-0 mt-2" html-for="comment_field_type" required>
              {{ t('activities.templates.attributes.comment_field_type') }}
            </FormLabel>
          </div>
          <div class="col-md-4">
            <VueSelect
              :clearable="false"
              v-model="form.comment_type"
              input-id="comment_field_type"
              :placeholder="t('common.select')"
              :options="Object.values(ActivityCommentType)"
              :get-option-label="(option: string) => t(`activities.templates.attributes.${option}`)"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!form.comment_type" v-bind="attributes as object" v-on="events" />
              </template>
            </VueSelect>
            <div class="mt-2" v-show="form.comment_type === ActivityCommentType.DROPDOWN">
              <ul ref="commentOptionsNodeRef" class="comment-options">
                <li class="comment-option" v-for="commentOption in commentOptions" :key="commentOption.id">
                  <div class="d-flex align-items-center">
                    <i class="ti ti-grip-vertical text-3 text-neutral-400 pr-2 pointer" />
                    <div class="form-wrapper flex-grow-1">
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="t('activities.templates.attributes.name')"
                        v-model="commentOption.option"
                      />
                    </div>
                    <AppButton
                      class="ml-2 flex-shrink-0"
                      @click.stop.prevent="deleteCommentOption(commentOption)"
                      color="danger"
                      size="small"
                      light
                      circle
                    >
                      <FontIcon name="trash" />
                    </AppButton>
                  </div>
                </li>
              </ul>
              <AppButton
                class="mt-2"
                @click.prevent="addCommentOption"
                color="success"
                light
                circle
                v-tooltip.right="t('common.add')"
              >
                <FontIcon name="plus" />
              </AppButton>
            </div>
          </div>
        </div>
        <!-- Auto-include in planning -->
        <div class="row align-items-center form-group">
          <div class="col-md-2">
            <FormLabel class="mb-0" html-for="include_in_planning">
              {{ t('activities.templates.attributes.include_in_planning') }}
            </FormLabel>
          </div>
          <div class="col-md-4">
            <FormSwitch group-class="mb-0" id="include_in_planning" v-model="form.include_in_planning" />
          </div>
        </div>
      </AppBoxBody>
      <AppBoxBody>
        <h2 class="mb-0" v-text="t('activities.templates.attributes.meta_input')" />
        <AppAlert class="mt-3" v-if="form.meta_input.length === 0">{{ t('common.empty') }}</AppAlert>
      </AppBoxBody>
      <AppBoxBody v-if="form.meta_input.length > 0" size="tiny">
        <AppTable hoverable>
          <AppTableHead>
            <AppTableTr>
              <AppTableTh nowrap>{{ t('activities.meta_input.attributes.label') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('activities.meta_input.attributes.is_required') }}</AppTableTh>
              <AppTableTh nowrap>{{ t('activities.meta_input.attributes.type') }}</AppTableTh>
              <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr v-for="metaInput in form.meta_input" :key="metaInput.id">
              <AppTableTd nowrap>
                <FormInput
                  :placeholder="t('activities.meta_input.attributes.label')"
                  v-model="metaInput.label"
                  required
                  trim
                />
              </AppTableTd>
              <AppTableTd nowrap>
                <FormSwitch group-class="mb-0" :id="metaInput.id" v-model="metaInput.is_required" />
              </AppTableTd>
              <AppTableTd nowrap>
                <div class="form-wrapper">
                  <select class="form-control" v-model="metaInput.type" required>
                    <option
                      v-for="(option, optionIndex) in metaInputTypeOptions"
                      :key="optionIndex"
                      :value="option.value"
                      v-text="option.label"
                    />
                  </select>
                </div>
              </AppTableTd>
              <AppTableTd nowrap class="text-right">
                <AppButton
                  v-tooltip.left="
                    t('activities.meta_input.tooltip.destroy', { name: metaInput.label || t('common.noname') })
                  "
                  @click.stop.prevent="onDeleteMetaInput(metaInput)"
                  class="ml-2"
                  color="danger"
                  size="small"
                  light
                  circle
                >
                  <FontIcon name="trash" />
                </AppButton>
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>
      </AppBoxBody>
      <AppBoxBody class="pt-0">
        <AppButton @click.prevent="addMetaInput" color="success" circle light v-tooltip.right="t('common.add')">
          <FontIcon name="plus" />
        </AppButton>
      </AppBoxBody>
      <AppBoxBody class="text-right">
        <AppButton v-if="props.id" color="success" :loading="submitLoader.isLoading.value" :disabled="disabled">
          {{ t('common.update') }}
        </AppButton>
        <AppButton v-else color="secondary" :loading="submitLoader.isLoading.value" :disabled="disabled">
          {{ t('common.create') }}
        </AppButton>
      </AppBoxBody>
    </AppBox>
  </form>
</template>

<style scoped lang="scss">
.comment-options {
  padding: 0;
  margin: 0;
  list-style: none;
}
.comment-option {
  margin-top: 0.75rem;
}
</style>
