<script setup lang="ts">
import {
  AppButton,
  AppLoader,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  CancelOrRolloverProjectModal,
  ConfirmDialog,
  FormTextarea,
  ProjectStatus,
} from '@/components';
import useLoader from '@/composables/useLoader';
import { computed, onMounted, reactive, ref } from 'vue';
import api from '@/services/api';
import { IProjectRequestNotes, IProjectResource, ProjectStatusType } from '@/types/Project';
import { ClientTabs, IClientFullResource } from '@/types/Client';
import { useI18n } from 'vue-i18n';
import { createConfirmDialog } from 'vuejs-confirm-dialog';
import { debounce } from 'lodash';
import { useRouteQuery } from '@vueuse/router';
import { useRouter } from 'vue-router';

const props = defineProps<{
  uuid: string;
  id: number;
}>();

const router = useRouter();
const statusBar = useLoader({
  useProgress: true,
});
const loader = useLoader();
const { t, d } = useI18n({ useScope: 'global' });

const form = reactive<IProjectRequestNotes>({
  notes: '',
});

const client = ref<null | IClientFullResource>(null);
const project = ref<null | IProjectResource>(null);
const abortController = ref<AbortController | null>(null);
const isActive = computed(() => project.value?.status === ProjectStatusType.Active);
const isCancelled = computed(() => project.value?.status === ProjectStatusType.Cancelled);
const fromTab = useRouteQuery<string>('from', ClientTabs.Overview);

const onNotesChange = debounce(async () => {
  try {
    statusBar.start();
    if (abortController.value) {
      abortController.value.abort();
    }
    abortController.value = new AbortController();
    await api.projects.update(form, props.uuid, props.id, { signal: abortController.value.signal });
  } catch (e) {
    //
  } finally {
    statusBar.finish();
  }
}, 500);

function cancelProject() {
  if (!project.value) {
    return;
  }
  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'cancel',
    clientUuid: props.uuid,
    project: {
      id: project.value.id,
      name: project.value.name,
      has_unfinished_tasks: project.value.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProject();
      if (newProjectId) {
        await router.push({ name: 'projects.edit', params: { uuid: props.uuid, id: newProjectId } });
      }
    },
  });

  dialog.reveal();
}

function reactivateProject() {
  if (!project.value) {
    return;
  }
  const dialog = createConfirmDialog(ConfirmDialog, {
    title: t('project.reactivate_modal.title', { name: project.value.name }),
    confirmBtnType: 'success',
    delayTime: 0,
    size: 's',
    confirmCallback: async () => {
      await api.projects.reactivate(props.uuid, props.id);
      await getProject();
    },
  });

  dialog.reveal();
}

function rolloverProject() {
  if (!project.value) {
    return;
  }

  const dialog = createConfirmDialog(CancelOrRolloverProjectModal, {
    type: 'rollover',
    clientUuid: props.uuid,
    project: {
      id: project.value.id,
      name: project.value.name,
      has_unfinished_tasks: project.value.has_unfinished_tasks,
    },
    onConfirmed: async (newProjectId: number | null) => {
      await getProject();
      if (newProjectId) {
        await router.push({ name: 'projects.edit', params: { uuid: props.uuid, id: newProjectId } });
      }
    },
  });

  dialog.reveal();
}

async function getClient() {
  try {
    const response = await api.clients.get(props.uuid);
    client.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getProject() {
  try {
    const response = await api.projects.get(props.uuid, props.id);
    project.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

onMounted(async () => {
  loader.start();
  await Promise.all([getClient(), getProject()]);
  if (project.value) {
    form.notes = project.value.notes;
  }
  loader.finish();
});
</script>

<template>
  <div class="container-fluid px-md-5">
    <div v-if="loader.isLoading.value" class="text-center">
      <AppLoader size="large" />
    </div>
    <div v-else class="mb-4">
      <template v-if="client && project">
        <div class="d-flex align-items-center mb-2">
          <h1 class="mb-0" v-text="project.name" />
          <ProjectStatus class="mx-3" :status="project.status" />
        </div>
        <p>{{ client.name }}: <strong v-text="client.org_number" /></p>
        <AppTable class="mt-3">
          <AppTableHead>
            <AppTableTr>
              <AppTableTh colspan="8">
                <h2 class="mb-0 text-neutral-900">{{ t('project.view.overview') }}</h2>
              </AppTableTh>
            </AppTableTr>
          </AppTableHead>
          <AppTableBody>
            <AppTableTr>
              <AppTableTd nowrap style="width: 200px">{{ t('project.view.deadline') }}</AppTableTd>
              <AppTableTd nowrap
                >{{ project.deadline_date ? d(project.deadline_date) : t('project.view.none') }}
              </AppTableTd>
            </AppTableTr>
            <AppTableTr v-if="project.status === ProjectStatusType.Active">
              <AppTableTd nowrap>{{ t('project.view.tasks') }}</AppTableTd>
              <AppTableTd nowrap>
                <span v-if="project.has_unfinished_tasks" class="text-danger">{{
                  t('project.view.there_are_unfinished_tasks')
                }}</span>
                <span v-else class="text-success">{{ t('project.view.there_are_no_unfinished_tasks') }}</span>
              </AppTableTd>
            </AppTableTr>
            <AppTableTr>
              <AppTableTd style="vertical-align: top" nowrap>{{ t('project.view.comment') }}</AppTableTd>
              <AppTableTd nowrap>
                <FormTextarea rows="6" id="invoice_default_info" v-model.trim="form.notes" @input="onNotesChange" />
              </AppTableTd>
            </AppTableTr>
          </AppTableBody>
        </AppTable>

        <div class="mt-3 d-flex flex-nowrap">
          <RouterLink
            class="mr-2"
            :to="{
              name: 'clients.edit',
              params: { uuid: props.uuid },
              query: { tab: fromTab },
            }"
          >
            <AppButton ghost light>
              {{ t('common.back') }}
            </AppButton>
          </RouterLink>
          <AppButton
            class="ml-auto mr-2"
            v-if="isActive"
            color="danger"
            :loading="loader.isLoading.value"
            @click.prevent="cancelProject"
          >
            {{ t('project.index.cancel') }}
          </AppButton>
          <AppButton
            v-if="isActive"
            color="secondary"
            :loading="loader.isLoading.value"
            @click.prevent="rolloverProject"
          >
            {{ t('project.index.rollover') }}
          </AppButton>
          <AppButton
            class="ml-auto mr-2"
            v-if="isCancelled"
            color="secondary"
            :loading="loader.isLoading.value"
            @click.prevent="reactivateProject"
          >
            {{ t('project.index.reactivate') }}
          </AppButton>
        </div>
      </template>
    </div>
  </div>
</template>
