export default {
  attributes: {
    start_date: 'Start date',
    end_date: 'End date',
  },
  modal: {
    create: {
      title: 'Create new fiscal year',
    },
  },
};
