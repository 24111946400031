<script setup lang="ts">
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import VueSelect from 'vue-select';
import { useModal } from 'vue-final-modal';
import { useRouteQuery } from '@vueuse/router';

import {
  AppAlert,
  AppBox,
  AppBoxBody,
  AppButton,
  AppLoader,
  AppPagination,
  AppTable,
  AppTableBody,
  AppTableHead,
  AppTableTd,
  AppTableTh,
  AppTableTr,
  ClientAddServiceCompanyContactModal,
  ClientAddServiceCompanyModal,
  ClientContactModal,
  ClientsTagModal,
  ConfirmModal,
  EmptyValue,
  FontIcon,
  FormLabel,
  FormSwitch,
  FormTextarea,
  PortalUserStatus,
} from '@/components';
import {
  ClientInvoiceGrouping,
  ClientInvoiceLanguage,
  ClientStatuses,
  ClientType,
  IClientContactListResource,
  IClientContactRequestBody,
  IClientTagResource,
  IClientUpdateRequestBody,
} from '@/types/Client';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useClients from '@/composables/useClients';
import useClientContactSearch from '@/composables/useClientContactSearch';
import { IUserListResource } from '@/types/User';
import { IServiceCompanyContactPreviewResource, IServiceCompanyListResource } from '@/types/ServiceCompany';
import { InvoiceDistributionType } from '@/types/Invoice';
import useCountriesStore from '@/store/CountriesStore';
import useContactRolesStore from '@/store/ContactRolesStore';
import { storeToRefs } from 'pinia';
import ClientGeneralInfoForm from '@/components/ClientSection/ClientGeneralInfoForm.vue';
import useClientSettings from '@/composables/client/useClientSettings';

const props = defineProps<{
  uuid: string;
}>();

const clientContacts = ref<IClientContactListResource[]>([]);

// Contacts data
const contactsElement = ref<HTMLDivElement | null>(null);
const contactsLoader = useLoader({ useProgress: false });
const currentContactsPage = useRouteQuery('settings-contact-page', '1', { transform: Number });
const contactsPerPage = ref(20);
const contactsTotal = ref(0);

// Service companies data
const serviceCompaniesLoader = useLoader({ useProgress: false });
const serviceCompanies = ref<IServiceCompanyListResource[]>([]);
const expandedServiceCompanies = ref<string[]>([]);
const users = ref<IUserListResource[]>([]);
const originalCountry = ref<string>('');
const { reportingFrequencyOptions, getVatReportingDeadlines } = useClientSettings();
const clientStatuses = computed(() => {
  return [
    {
      label: t('client.statuses.prospect'),
      value: ClientStatuses.PROSPECT,
    },
    {
      label: t('client.statuses.active'),
      value: ClientStatuses.ACTIVE,
    },
    {
      label: t('client.statuses.inactive'),
      value: ClientStatuses.INACTIVE,
    },
    {
      label: t('client.statuses.blacklist'),
      value: ClientStatuses.BLACKLIST,
    },
  ];
});
const form = reactive<IClientUpdateRequestBody>({
  type: ClientType.COMPANY,
  name: '',
  org_number: '',
  vat: '',
  category_uuid: '',
  main_phone: '',
  first_address: '',
  second_address: '',
  zip: '',
  city: '',
  country: '',
  invoice_email: '',
  invoice_first_address: '',
  invoice_second_address: '',
  invoice_zip: '',
  invoice_city: '',
  invoice_country: '',
  responsible_user_uuid: '',
  invoice_default_contact_uuid: '',
  invoice_grouping: null,
  invoice_default_language: ClientInvoiceLanguage.SV,
  invoice_default_info: '',
  current_financial_year_start_date: '',
  current_financial_year_end_date: '',
  invoice_distribution: null,
  tags: [],
  settings: {
    large_company: false,
    has_formal_board_meetings: false,
    prepares_financial_budgets: false,
    registered_as_employer: false,
    registered_for_vat: false,
    vat_reporting_frequency: null,
    vat_reporting_deadline: null,
    registered_for_f_tax: false,
    part_of_company_group: false,
    holding_company: false,
    consolidates_group_financial_statement: false,
    dormant_limited_operations: false,
    customer_status: null,
    use_we_connect: false,
    insourced_services: false,
    first_year_audit: false,
    audited_by_another_firm_previous_year: false,
  },
});

const clientTags = ref<IClientTagResource[]>([]);
const clientTagsLoading = ref(false);

const countriesStore = useCountriesStore();
const { getCountries } = countriesStore;
const { countries } = storeToRefs(countriesStore);

const { getContactRoles } = useContactRolesStore();

const clientContactSyncLoading = ref(false);
const loader = useLoader({ useProgress: false });
const submitLoader = useLoader();
const i18n = useI18n({ useScope: 'global' });
const { t } = i18n;
const { clientCategories, getClientCategories } = useClients();
const {
  selectedContact: selectedInvoiceReference,
  contacts: invoiceReferences,
  onSearch: onSearchInvoiceReference,
} = useClientContactSearch(props.uuid);

async function getClient() {
  try {
    const { data } = await api.clients.get(props.uuid!);
    form.type = data.type;
    form.name = data.name;
    form.org_number = data.org_number.toString();
    form.vat = data.vat;
    form.category_uuid = data.company_category?.uuid ?? '';
    form.main_phone = data.main_phone;
    form.first_address = data.reg_address.first_address;
    form.second_address = data.reg_address.second_address;
    form.zip = data.reg_address.zip;
    form.city = data.reg_address.city;
    form.country = data.reg_address.country;
    originalCountry.value = data.reg_address.country;
    form.invoice_email = data.invoice_email;
    form.invoice_first_address = data.invoice_address.first_address;
    form.invoice_second_address = data.invoice_address.second_address;
    form.invoice_zip = data.invoice_address.zip;
    form.invoice_city = data.invoice_address.city;
    form.invoice_country = data.invoice_address.country;
    form.invoice_grouping = data.invoice_grouping;
    form.invoice_default_language = data.invoice_default_language;
    form.invoice_default_info = data.invoice_default_info ?? '';
    form.invoice_distribution = data.invoice_distribution;
    if (data.invoice_default_contact) {
      form.invoice_default_contact_uuid = data.invoice_default_contact.uuid;
      selectedInvoiceReference.value = data.invoice_default_contact;
      invoiceReferences.value.push(data.invoice_default_contact);
    }
    form.responsible_user_uuid = data.responsible_user.uuid;
    form.current_financial_year_start_date = data.current_financial_year.start_date;
    form.current_financial_year_end_date = data.current_financial_year.end_date;
    // Temp
    if (form.country === 'SE') form.country = 'SWE';
    if (form.invoice_country === 'SE') form.invoice_country = 'SWE';
    if (form.country === 'ES') form.country = 'ESP';
    if (form.invoice_country === 'ES') form.invoice_country = 'ESP';
    if (form.country === 'NO') form.country = 'NOR';
    if (form.invoice_country === 'NO') form.invoice_country = 'NOR';

    if (data.settings) {
      form.settings = { ...data.settings };
    }
    form.tags = [...data.tags];
  } catch (error) {
    console.error(error);
  }
}

async function getClientTags() {
  clientTagsLoading.value = true;
  try {
    const response = await api.clients.tags.index();
    clientTags.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    clientTagsLoading.value = false;
  }
}

async function submit() {
  try {
    submitLoader.start();
    await api.clients.update(form, props.uuid!);
    originalCountry.value = form.country;
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

async function getContacts() {
  try {
    contactsLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('page', currentContactsPage.value.toString());
    const response = await api.clients.contacts.list(props.uuid, searchParams);
    clientContacts.value = response.data;
    contactsPerPage.value = response.meta!.per_page;
    contactsTotal.value = response.meta!.total;
  } catch (error) {
    console.error(error);
  } finally {
    contactsLoader.finish();
  }
}

async function getClientServiceCompanies() {
  try {
    serviceCompaniesLoader.start();
    const searchParams = new URLSearchParams();
    searchParams.append('client', props.uuid);
    searchParams.append('without_pagination', '1');
    const response = await api.serviceCompanies.list({ searchParams });
    serviceCompanies.value = response.data;
    serviceCompanyAttachModal.patchOptions({ attrs: { exclude: serviceCompanies.value.map(({ uuid }) => uuid) } });
  } catch (error) {
    console.error(error);
  } finally {
    serviceCompaniesLoader.finish();
  }
}

const createContactModal = useModal({
  component: ClientContactModal,
  attrs: {
    title: t('client.contact.modal.title'),
    async onCreate(formClient: IClientContactRequestBody) {
      try {
        createContactModal.patchOptions({ attrs: { loading: true } });
        await api.clients.contacts.store(props.uuid, formClient);
        await getContacts();
        await createContactModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        createContactModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      createContactModal.close();
    },
  },
});

const clientTagModal = useModal({
  component: ClientsTagModal,
  attrs: {
    onCreated(clientTag: IClientTagResource) {
      form.tags = [...form.tags, clientTag.id];
      clientTags.value = [...clientTags.value, { ...clientTag }];
      clientTagModal.close();
    },
  },
});

const serviceCompanyAttachModal = useModal({
  component: ClientAddServiceCompanyModal,
  attrs: {
    exclude: [] as string[],
    loading: false,
    async onAdd(serviceCompany: string) {
      try {
        serviceCompanyAttachModal.patchOptions({ attrs: { loading: true } });
        await api.clients.serviceCompanies.attach(props.uuid, serviceCompany);
        await getClientServiceCompanies();
        await serviceCompanyAttachModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        // @ts-ignore
        serviceCompanyAttachModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      serviceCompanyAttachModal.close();
    },
  },
});

const serviceCompanyContactAttachModal = useModal({
  component: ClientAddServiceCompanyContactModal,
  attrs: {
    async onAdd(serviceCompanyUuid: string, serviceCompanyContactUuid: string) {
      try {
        serviceCompanyContactAttachModal.patchOptions({ attrs: { loading: true } });
        await api.clients.serviceCompanies.contacts.attach(props.uuid, serviceCompanyUuid, serviceCompanyContactUuid);
        await getClientServiceCompanies();
        await serviceCompanyContactAttachModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        serviceCompanyContactAttachModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      serviceCompanyContactAttachModal.close();
    },
  },
});

function onServiceCompanyContactAttach(serviceCompany: IServiceCompanyListResource) {
  serviceCompanyContactAttachModal.patchOptions({
    attrs: { serviceCompanyUuid: serviceCompany.uuid, exclude: serviceCompany.contacts.map(({ uuid }) => uuid) },
  });
  serviceCompanyContactAttachModal.open();
}

const editContactModal = useModal({
  component: ClientContactModal,
  attrs: {
    async onUpdate(id: string, form: IClientContactRequestBody) {
      try {
        editContactModal.patchOptions({ attrs: { loading: true } });
        await api.clients.contacts.update(props.uuid, id, form);
        await getContacts();
        await editContactModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        editContactModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      editContactModal.close();
    },
  },
});

async function onEdit(contact: IClientContactListResource, event: PointerEvent) {
  if (event.target) {
    const target = event.target as HTMLElement;
    const button = (target.tagName.toLowerCase() === 'button' ? event.target : target.parentNode) as HTMLButtonElement;
    button.classList.add('is-loading');
    try {
      editContactModal.patchOptions({
        attrs: {
          title: t('client.contact.modal.edit_title', { name: contact.name }),
          contact,
        },
      });
      await editContactModal.open();
    } catch (error) {
      console.error(error);
    } finally {
      button.classList.remove('is-loading');
    }
  }
}

const serviceCompanyDetachModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('client.service_company.confirm.detach.title'),
    async onConfirm() {
      try {
        serviceCompanyDetachModal.patchOptions({ attrs: { loading: true } });
        const { uuid: serviceCompanyUuid } = serviceCompanyDetachModal.options.attrs!.params;
        await api.clients.serviceCompanies.detach(props.uuid, serviceCompanyUuid);
        await getClientServiceCompanies();
        await serviceCompanyDetachModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        serviceCompanyDetachModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      serviceCompanyDetachModal.close();
    },
  },
});

function onServiceCompanyDetach(serviceCompany: IServiceCompanyListResource) {
  serviceCompanyDetachModal.patchOptions({
    attrs: {
      params: { uuid: serviceCompany.uuid },
      message: t('client.service_company.confirm.detach.text', { name: serviceCompany.name }),
    },
  });
  serviceCompanyDetachModal.open();
}

const serviceCompanyContactDetachModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('client.service_company.contact.confirm.detach.title'),
    async onConfirm() {
      try {
        serviceCompanyContactDetachModal.patchOptions({ attrs: { loading: true } });
        const { serviceCompanyUuid, serviceCompanyContactUuid } =
          serviceCompanyContactDetachModal.options.attrs!.params;
        await api.clients.serviceCompanies.contacts.detach(props.uuid, serviceCompanyUuid, serviceCompanyContactUuid);
        await getClientServiceCompanies();
        await serviceCompanyContactDetachModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        serviceCompanyContactDetachModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      serviceCompanyContactDetachModal.close();
    },
  },
});

const serviceCompanyContactPortalAccessModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: '',
    async onConfirm() {
      try {
        serviceCompanyContactPortalAccessModal.patchOptions({ attrs: { loading: true } });
        const { uuid, active } = serviceCompanyContactPortalAccessModal.options.attrs!.params;
        await api.users.portal.serviceContacts[active ? 'deactivate' : 'activate'](uuid);
        await getClientServiceCompanies();
        await serviceCompanyContactPortalAccessModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        serviceCompanyContactPortalAccessModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      serviceCompanyContactPortalAccessModal.close();
    },
  },
});

const companyCompanyContactPortalAccessModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: '',
    async onConfirm() {
      try {
        companyCompanyContactPortalAccessModal.patchOptions({ attrs: { loading: true } });
        const { uuid, active } = companyCompanyContactPortalAccessModal.options.attrs!.params;
        await api.users.portal.companyContacts[active ? 'deactivate' : 'activate'](uuid);
        await getContacts();
        await companyCompanyContactPortalAccessModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        companyCompanyContactPortalAccessModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      companyCompanyContactPortalAccessModal.close();
    },
  },
});

function onServiceCompanyContactDetach(
  serviceCompany: IServiceCompanyListResource,
  contact: IServiceCompanyContactPreviewResource,
) {
  serviceCompanyContactDetachModal.patchOptions({
    attrs: {
      params: { serviceCompanyUuid: serviceCompany.uuid, serviceCompanyContactUuid: contact.uuid },
      message: t('client.service_company.contact.confirm.detach.text', {
        company: serviceCompany.name,
        name: contact.name,
      }),
    },
  });
  serviceCompanyContactDetachModal.open();
}

function onServiceContactPortalChange(contact: IServiceCompanyContactPreviewResource) {
  const isActive = ['invited', 'active'].includes(contact.portal_status);
  serviceCompanyContactPortalAccessModal.patchOptions({
    attrs: {
      params: { uuid: contact.uuid, active: isActive },
      title: t(`user.portal.confirm.${isActive ? 'deactivate' : 'activate'}.title`),
      message: t(`user.portal.confirm.${isActive ? 'deactivate' : 'activate'}.text`, {
        name: contact.name,
      }),
    },
  });
  serviceCompanyContactPortalAccessModal.open();
}

function onCompanyContactPortalChange(contact: IClientContactListResource) {
  const isActive = ['invited', 'active'].includes(contact.portal_status);
  companyCompanyContactPortalAccessModal.patchOptions({
    attrs: {
      params: { uuid: contact.contact_uuid, active: isActive },
      title: t(`user.portal.confirm.${isActive ? 'deactivate' : 'activate'}.title`),
      message: t(`user.portal.confirm.${isActive ? 'deactivate' : 'activate'}.text`, {
        name: contact.name,
      }),
    },
  });
  companyCompanyContactPortalAccessModal.open();
}

async function syncClientContacts() {
  clientContactSyncLoading.value = true;
  try {
    await api.clients.contacts.sync(props.uuid);
    await getContacts();
  } catch (error) {
    console.error(error);
  } finally {
    clientContactSyncLoading.value = false;
  }
}

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function toggleServiceCompany(uuid: string) {
  if (expandedServiceCompanies.value.includes(uuid)) {
    expandedServiceCompanies.value = expandedServiceCompanies.value.filter((item) => item !== uuid);
  } else {
    expandedServiceCompanies.value.push(uuid);
  }
}

onMounted(async () => {
  loader.start();
  await Promise.all([getCountries(), getClientTags()]);
  await Promise.all([
    getContactRoles(),
    getClient(),
    getContacts(),
    getUsers(),
    getClientCategories(),
    getClientServiceCompanies(),
  ]);
  loader.finish();
});

watch(currentContactsPage, async () => {
  await getContacts();
  contactsElement.value!.scrollIntoView({ behavior: 'smooth', block: 'start' });
});
</script>

<template>
  <form @submit.prevent="submit">
    <!-- Client details -->
    <AppBox shadow v-if="loader.isLoading.value" class="text-center py-3">
      <AppLoader />
    </AppBox>
    <AppBox shadow v-else>
      <AppBoxBody>
        <div class="row">
          <div class="col-8">
            <ClientGeneralInfoForm
              mode="editing"
              :client-categories="clientCategories"
              :countries="countries"
              :users="users"
              :invoice-distribution="form.invoice_distribution"
              v-model:name="form.name"
              v-model:org-number="form.org_number"
              v-model:vat-number="form.vat"
              v-model:type="form.type"
              v-model:category-uuid="form.category_uuid"
              v-model:phone="form.main_phone"
              v-model:responsible-user-uuid="form.responsible_user_uuid"
              v-model:start-financial-year-date="form.current_financial_year_start_date"
              v-model:end-financial-year-date="form.current_financial_year_end_date"
              v-model:first-address="form.first_address"
              v-model:second-address="form.second_address"
              v-model:zip="form.zip"
              v-model:city="form.city"
              v-model:country="form.country"
              v-model:invoice-email="form.invoice_email"
              v-model:invoice-first-address="form.invoice_first_address"
              v-model:invoice-second-address="form.invoice_second_address"
              v-model:invoice-zip="form.invoice_zip"
              v-model:invoice-city="form.invoice_city"
              v-model:invoice-country="form.invoice_country"
            />
            <h2 class="mt-4" v-text="t('client.form.invoice_settings')" />
            <div class="row row-cols-2">
              <div class="form-group col">
                <FormLabel html-for="invoice_default_contact_uuid">
                  {{ t('client.attributes.invoice_default_contact_uuid') }}
                </FormLabel>
                <VueSelect
                  :clearable="false"
                  :filterable="false"
                  v-model="selectedInvoiceReference"
                  :options="invoiceReferences"
                  @search="onSearchInvoiceReference"
                  label="name"
                  input-id="invoice_default_contact_uuid"
                  :placeholder="t('common.search')"
                  @option:selected="form.invoice_default_contact_uuid = $event.uuid"
                >
                  <template #no-options>{{ t('common.type_to_search') }}</template>
                </VueSelect>
              </div>
              <div class="form-group col">
                <FormLabel html-for="invoice_grouping">
                  {{ t('client.attributes.invoice_grouping') }}
                </FormLabel>
                <VueSelect
                  :reduce="(option: any) => option.value"
                  :clearable="false"
                  :searchable="false"
                  v-model="form.invoice_grouping"
                  :options="[
                    { value: null, label: t('client.invoice_grouping.without_grouping') },
                    ...Object.values(ClientInvoiceGrouping).map((value) => ({
                      value,
                      label: t(`client.invoice_grouping.${value}`),
                    })),
                  ]"
                  label="label"
                  input-id="invoice_grouping"
                />
              </div>
              <div class="form-group col">
                <FormLabel html-for="invoice_distribution">
                  {{ t('client.attributes.invoice_distribution') }}
                </FormLabel>
                <VueSelect
                  :clearable="false"
                  :searchable="false"
                  v-model="form.invoice_distribution"
                  :options="Object.values(InvoiceDistributionType)"
                  :get-option-label="(option: InvoiceDistributionType) => t(`invoice.distribution.${option}`)"
                  label="label"
                  input-id="invoice_distribution"
                  :placeholder="t('common.not_selected')"
                />
              </div>
              <div class="form-group col">
                <FormLabel html-for="invoice_default_language">
                  {{ t('client.attributes.invoice_default_language') }}
                </FormLabel>
                <VueSelect
                  :clearable="false"
                  :searchable="false"
                  v-model="form.invoice_default_language"
                  :options="Object.values(ClientInvoiceLanguage)"
                  :get-option-label="(option: string) => t(`common.${option}`)"
                  label="label"
                  input-id="invoice_default_language"
                />
              </div>
            </div>
            <div class="form-group">
              <FormLabel html-for="invoice_default_info">
                {{ t('client.attributes.invoice_default_info') }}
              </FormLabel>
              <FormTextarea rows="6" id="invoice_default_info" v-model="form.invoice_default_info" />
            </div>
          </div>
          <!-- INFO & SETTINGS -->
          <div class="col-4">
            <h2 v-text="t('client.form.info_and_settings')" />
            <h3 v-text="t('client.form.customer_info')" />
            <div class="form-group">
              <FormLabel html-for="tags">
                {{ t('client.attributes.tags') }}
              </FormLabel>
              <div class="d-flex">
                <VueSelect
                  class="flex-grow-1"
                  v-model="form.tags"
                  :options="clientTags"
                  label="name"
                  input-id="tags"
                  :placeholder="t('common.not_selected')"
                  :reduce="(option: IClientTagResource) => option.id"
                  :loading="clientTagsLoading"
                  multiple
                />
                <AppButton
                  @click.prevent="clientTagModal.open"
                  color="success"
                  class="flex-shrink-0 ml-2"
                  light
                  circle
                  v-tooltip.left="t('common.add')"
                >
                  <FontIcon name="plus" />
                </AppButton>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="large_company">
                  {{ t('client.attributes.large_company') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch group-class="mb-0" id="large_company" v-model="form.settings.large_company" />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="has_formal_board_meetings">
                  {{ t('client.attributes.has_formal_board_meetings') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch
                  group-class="mb-0"
                  id="has_formal_board_meetings"
                  v-model="form.settings.has_formal_board_meetings"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="prepares_financial_budgets">
                  {{ t('client.attributes.prepares_financial_budgets') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch
                  group-class="mb-0"
                  id="prepares_financial_budgets"
                  v-model="form.settings.prepares_financial_budgets"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="registered_as_employer">
                  {{ t('client.attributes.registered_as_employer') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch
                  group-class="mb-0"
                  id="registered_as_employer"
                  v-model="form.settings.registered_as_employer"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="registered_for_vat">
                  {{ t('client.attributes.registered_for_vat') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch group-class="mb-0" id="registered_for_vat" v-model="form.settings.registered_for_vat" />
              </div>
            </div>
            <div v-if="form.settings.registered_for_vat" class="form-group">
              <FormLabel html-for="vat_reporting_frequency">{{ t('client.processes.reporting_frequency') }}</FormLabel>
              <VueSelect
                :reduce="(option: any) => option.value"
                :clearable="false"
                :searchable="false"
                v-model="form.settings.vat_reporting_frequency"
                :options="reportingFrequencyOptions"
                label="label"
                input-id="vat_reporting_frequency"
                @option:selected="form.settings.vat_reporting_deadline = null"
              />
            </div>
            <div v-if="form.settings.registered_for_vat" class="form-group">
              <FormLabel html-for="vat_reporting_dates">
                {{ t('client.attributes.vat_reporting_dates') }}
              </FormLabel>
              <VueSelect
                :reduce="(option: any) => option.value"
                :clearable="false"
                :searchable="false"
                v-model="form.settings.vat_reporting_deadline"
                :options="getVatReportingDeadlines(form.settings.vat_reporting_frequency)"
                label="label"
                input-id="vat_reporting_dates"
              />
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="registered_for_f_tax">
                  {{ t('client.attributes.registered_for_f_tax') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch group-class="mb-0" id="registered_for_f_tax" v-model="form.settings.registered_for_f_tax" />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="part_of_company_group">
                  {{ t('client.attributes.part_of_company_group') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch
                  group-class="mb-0"
                  id="part_of_company_group"
                  v-model="form.settings.part_of_company_group"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="holding_company">
                  {{ t('client.attributes.holding_company') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch group-class="mb-0" id="holding_company" v-model="form.settings.holding_company" />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="consolidates_group_financial_statement">
                  {{ t('client.attributes.consolidates_group_financial_statement') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch
                  group-class="mb-0"
                  id="consolidates_group_financial_statement"
                  v-model="form.settings.consolidates_group_financial_statement"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="dormant_limited_operations">
                  {{ t('client.attributes.dormant_limited_operations') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch
                  group-class="mb-0"
                  id="dormant_limited_operations"
                  v-model="form.settings.dormant_limited_operations"
                />
              </div>
            </div>
            <h3 class="mt-4" v-text="t('client.form.customer_and_project_settings')" />
            <div class="row row-cols-2">
              <div class="form-group col">
                <FormLabel html-for="customer_status">
                  {{ t('client.attributes.customer_status') }}
                </FormLabel>
              </div>
              <div class="form-group col">
                <VueSelect
                  :reduce="(option: any) => option.value"
                  :clearable="false"
                  :searchable="false"
                  v-model="form.settings.customer_status"
                  :options="clientStatuses"
                  label="label"
                  input-id="customer_status"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="use_we_connect">
                  {{ t('client.attributes.use_we_connect') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch group-class="mb-0" id="use_we_connect" v-model="form.settings.use_we_connect" />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="insourced_services">
                  {{ t('client.attributes.insourced_services') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch group-class="mb-0" id="insourced_services" v-model="form.settings.insourced_services" />
              </div>
            </div>
            <h3 class="mt-4" v-text="t('client.form.audit_service_info')" />
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="first_year_audit">
                  {{ t('client.attributes.first_year_audit') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch group-class="mb-0" id="first_year_audit" v-model="form.settings.first_year_audit" />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-10">
                <FormLabel html-for="audited_by_another_firm_previous_year">
                  {{ t('client.attributes.audited_by_another_firm_previous_year') }}
                </FormLabel>
              </div>
              <div class="form-group col-2">
                <FormSwitch
                  group-class="mb-0"
                  id="audited_by_another_firm_previous_year"
                  v-model="form.settings.audited_by_another_firm_previous_year"
                />
              </div>
            </div>
          </div>
          <!-- INFO & SETTINGS END -->
        </div>
        <div class="text-right">
          <AppButton color="success" :loading="submitLoader.isLoading.value">
            {{ t('common.update') }}
          </AppButton>
        </div>
      </AppBoxBody>
    </AppBox>

    <!-- Contacts -->
    <div class="mt-5" ref="contactsElement">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0" v-text="t('client.contact.title')" />
        <div>
          <AppButton
            @click.prevent="createContactModal.open"
            color="secondary"
            :disabled="contactsLoader.isLoading.value"
          >
            {{ t('client.contact.create') }}
          </AppButton>
          <AppButton
            v-if="originalCountry === 'SWE'"
            class="ml-2"
            :disabled="clientContactSyncLoading || contactsLoader.isLoading.value"
            :loading="clientContactSyncLoading"
            @click.prevent="syncClientContacts"
          >
            {{ t('client.contact.sync') }}
          </AppButton>
        </div>
      </div>
      <AppBox shadow v-if="contactsLoader.isLoading.value" class="text-center py-3">
        <AppLoader />
      </AppBox>
      <template v-else>
        <AppAlert v-if="clientContacts.length === 0">{{ t('client.contact.empty') }}</AppAlert>
        <template v-else>
          <AppTable hoverable>
            <AppTableHead>
              <AppTableTr>
                <AppTableTh nowrap>{{ t('client.contact.name') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('client.contact.short_name') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('client.contact.personal_number') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('client.contact.email') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('client.contact.phone') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('client.contact.roles') }}</AppTableTh>
                <AppTableTh>{{ t('client.contact.subscription_list') }}</AppTableTh>
                <AppTableTh>{{ t('client.contact.owner') }}</AppTableTh>
                <AppTableTh>{{ t('client.contact.access_to_portal') }}</AppTableTh>
                <AppTableTh nowrap>{{ t('client.contact.portal_status') }}</AppTableTh>
                <AppTableTh nowrap class="text-right">{{ t('common.actions') }}</AppTableTh>
              </AppTableTr>
            </AppTableHead>
            <AppTableBody>
              <AppTableTr class="pointer" v-for="contact in clientContacts" :key="contact.uuid">
                <AppTableTd nowrap>
                  <strong v-text="contact.name" />
                </AppTableTd>
                <AppTableTd nowrap>
                  <span v-if="contact.short_name" v-text="contact.short_name" />
                  <EmptyValue v-else />
                </AppTableTd>
                <AppTableTd nowrap>{{ contact.personal_number }}</AppTableTd>
                <AppTableTd nowrap>
                  <span v-if="contact.email" v-text="contact.email" />
                  <i v-else class="text-danger-500" v-text="t('common.empty')" />
                </AppTableTd>
                <AppTableTd nowrap>
                  <span v-if="contact.phone" v-text="contact.phone" />
                  <EmptyValue v-else />
                </AppTableTd>
                <AppTableTd nowrap>
                  {{ contact.roles.map((role) => role.name[i18n.locale.value]).join(', ') }}
                </AppTableTd>
                <AppTableTd nowrap>
                  <div class="form-group mb-0">
                    <input :id="contact.uuid + 'subscribe-list'" type="checkbox" class="form-check" disabled />
                    <label :for="contact.uuid + 'subscribe-list'" class="form-label" v-text="'&nbsp;'" />
                  </div>
                </AppTableTd>
                <AppTableTd nowrap>
                  <div class="form-group mb-0">
                    <input :id="contact.uuid + 'owner'" type="checkbox" class="form-check" disabled />
                    <label :for="contact.uuid + 'owner'" class="form-label" v-text="'&nbsp;'" />
                  </div>
                </AppTableTd>
                <AppTableTd nowrap>
                  <div v-tooltip="contact.email ? null : t('validation.required', { name: t('client.contact.email') })">
                    <div class="form-group mb-0">
                      <input
                        :id="`${contact.uuid}-access-to-portal`"
                        type="checkbox"
                        class="form-switch"
                        :disabled="!contact.email"
                        :checked="['invited', 'active'].includes(contact.portal_status)"
                      />
                      <label
                        :for="`${contact.uuid}-access-to-portal`"
                        class="form-label"
                        @click.prevent="onCompanyContactPortalChange(contact)"
                        v-text="'&nbsp;'"
                      />
                    </div>
                  </div>
                </AppTableTd>
                <AppTableTd nowrap>
                  <PortalUserStatus :status="contact.portal_status" />
                </AppTableTd>
                <AppTableTd nowrap class="text-right">
                  <AppButton
                    v-tooltip="t('client.contact.tooltip.edit', { name: contact.name })"
                    @click.stop.prevent="onEdit(contact, $event)"
                    size="small"
                    light
                    circle
                  >
                    <FontIcon name="pencil" />
                  </AppButton>
                </AppTableTd>
              </AppTableTr>
            </AppTableBody>
          </AppTable>
          <AppPagination
            class="mt-3"
            :per-page="contactsPerPage"
            :total="contactsTotal"
            v-model="currentContactsPage"
          />
        </template>
      </template>
    </div>

    <!-- Service companies -->
    <div class="mt-5">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <h2 class="mb-0" v-text="t('client.service_company.title')" />
        <div>
          <AppButton
            @click.prevent="serviceCompanyAttachModal.open"
            color="secondary"
            :disabled="serviceCompaniesLoader.isLoading.value"
          >
            {{ t('common.add') }}
          </AppButton>
        </div>
      </div>
      <AppBox shadow v-if="serviceCompaniesLoader.isLoading.value" class="text-center py-3">
        <AppLoader />
      </AppBox>
      <template v-else>
        <AppAlert v-if="serviceCompanies.length === 0">{{ t('service-company.empty') }}</AppAlert>
        <AppBox
          :class="{ 'mb-2': index !== serviceCompanies.length - 1 }"
          shadow
          v-for="(serviceCompany, index) in serviceCompanies"
          :key="serviceCompany.uuid"
        >
          <AppBoxBody>
            <div class="d-flex justify-content-between align-items-center">
              <RouterLink
                target="_blank"
                class="pointer unlink"
                :to="{ name: 'service-organizations.edit', params: { uuid: serviceCompany.uuid } }"
              >
                <h3 class="mb-0" v-text="serviceCompany.name" />
                <span class="text-neutral-400" v-text="serviceCompany.service_company_type.name" />
              </RouterLink>
              <div>
                <AppButton
                  v-tooltip="t('client.service_company.tooltip.detach', { name: serviceCompany.name })"
                  class="ml-2"
                  color="danger"
                  size="small"
                  @click.prevent="onServiceCompanyDetach(serviceCompany)"
                  light
                  circle
                >
                  <FontIcon name="link-off" />
                </AppButton>
                <AppButton
                  class="ml-2"
                  size="small"
                  @click.prevent="toggleServiceCompany(serviceCompany.uuid)"
                  light
                  circle
                >
                  <FontIcon
                    :name="expandedServiceCompanies.includes(serviceCompany.uuid) ? 'chevron-up' : 'chevron-down'"
                  />
                </AppButton>
              </div>
            </div>
            <div class="pt-3" v-show="expandedServiceCompanies.includes(serviceCompany.uuid)">
              <h4>{{ t('client.service_company.contact.title') }}</h4>
              <AppAlert v-if="serviceCompany.contacts.length === 0">
                {{ t('client.service_company.contact.empty') }}
              </AppAlert>
              <AppTable v-else hoverable>
                <AppTableHead>
                  <AppTableTr>
                    <AppTableTh class="pl-0" nowrap>{{ t('service-company.contact.attributes.name') }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.short_name') }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.personal_number') }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.email') }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.phone') }}</AppTableTh>
                    <AppTableTh nowrap>{{ t('service-company.contact.attributes.role') }}</AppTableTh>
                    <AppTableTh nowrap>
                      {{ t('client.service_company.contact.attributes.account_in_portal') }}
                    </AppTableTh>
                    <AppTableTh nowrap>{{ t('client.service_company.contact.attributes.portal_status') }}</AppTableTh>
                    <AppTableTh class="pr-0 text-right" nowrap>{{ t('common.actions') }}</AppTableTh>
                  </AppTableTr>
                </AppTableHead>
                <AppTableBody>
                  <AppTableTr v-for="contact in serviceCompany.contacts" :key="contact.uuid">
                    <AppTableTd class="pl-0" nowrap>
                      <strong v-text="contact.name" />
                    </AppTableTd>
                    <AppTableTd>
                      <span v-if="contact.short_name" v-text="contact.short_name" />
                      <EmptyValue v-else />
                    </AppTableTd>
                    <AppTableTd>
                      <span v-if="contact.personal_number" v-text="contact.personal_number" />
                      <EmptyValue v-else />
                    </AppTableTd>
                    <AppTableTd>
                      <span v-if="contact.email" v-text="contact.email" />
                      <EmptyValue v-else />
                    </AppTableTd>
                    <AppTableTd>
                      <span v-if="contact.phone" v-text="contact.phone" />
                      <EmptyValue v-else />
                    </AppTableTd>
                    <AppTableTd>{{ contact.role.name }}</AppTableTd>
                    <AppTableTd nowrap>
                      <div
                        v-tooltip="
                          contact.email
                            ? null
                            : t('validation.required', { name: t('service-company.contact.attributes.email') })
                        "
                      >
                        <div class="form-group mb-0">
                          <input
                            :id="contact.uuid + serviceCompany.uuid"
                            type="checkbox"
                            class="form-switch"
                            :disabled="!contact.email"
                            :checked="['invited', 'active'].includes(contact.portal_status)"
                          />
                          <label
                            :for="contact.uuid + serviceCompany.uuid"
                            class="form-label"
                            @click.prevent="onServiceContactPortalChange(contact)"
                            v-text="'&nbsp;'"
                          />
                        </div>
                      </div>
                    </AppTableTd>
                    <AppTableTd nowrap>
                      <PortalUserStatus :status="contact.portal_status" />
                    </AppTableTd>
                    <AppTableTd class="text-right pr-0" nowrap>
                      <AppButton
                        v-tooltip="t('client.service_company.contact.tooltip.detach', { name: contact.name })"
                        class="ml-2"
                        size="small"
                        color="danger"
                        @click.prevent="onServiceCompanyContactDetach(serviceCompany, contact)"
                        light
                        circle
                      >
                        <FontIcon name="link-off" />
                      </AppButton>
                    </AppTableTd>
                  </AppTableTr>
                </AppTableBody>
              </AppTable>
              <AppButton
                class="mt-3"
                v-tooltip="t('client.service_company.contact.tooltip.attach')"
                size="small"
                color="success"
                @click.prevent="onServiceCompanyContactAttach(serviceCompany)"
                light
                circle
              >
                <FontIcon name="plus" />
              </AppButton>
            </div>
          </AppBoxBody>
        </AppBox>
      </template>
    </div>
  </form>
</template>
