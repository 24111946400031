<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import { AppButton, FormLabel, FormTextarea } from '@/components';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  loading?: boolean;
  type: 'crm' | 'planner';
  uuid: string;
  note: string;
}>();

const notes = ref('');

const emit = defineEmits<{
  (e: 'crmServiceUpdate', uuid: string, notes: string): void;
  (e: 'plannerServiceUpdate', uuid: string, notes: string): void;
  (e: 'cancel'): void;
}>();

function submit() {
  if (props.type === 'crm') {
    emit('crmServiceUpdate', props.uuid, notes.value);
  } else if (props.type === 'planner') {
    emit('plannerServiceUpdate', props.uuid, notes.value);
  }
}

onMounted(async () => {
  notes.value = props.note;
});

onUnmounted(() => {
  notes.value = '';
});
</script>

<template>
  <VueFinalModal class="modal-overlay" content-class="modal-container" :click-to-close="false" :esc-to-close="false">
    <form @submit.prevent="submit">
      <div class="modal-header">
        <h2 v-text="t('service-mapping.mapping_comment_modal.title')" />
      </div>
      <div class="modal-content">
        <div class="form-group">
          <FormLabel html-for="crm_service_note" required>
            {{ t('service-mapping.mapping_comment_modal.note') }}
          </FormLabel>
          <FormTextarea rows="4" id="crm_service_note" v-model="notes" required />
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex">
          <AppButton light @click.prevent="emit('cancel')" :disabled="loading">
            {{ t('common.cancel') }}
          </AppButton>
          <AppButton class="ml-auto" color="success" :loading="loading" :disabled="notes === ''">
            {{ t('common.save') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
