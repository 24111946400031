<script lang="ts" setup>
const emits = defineEmits(['update:modelValue']);

const props = defineProps<{
  modelValue: boolean;
  required?: boolean;
  label?: string;
  groupClass?: string;
}>();

function onUpdate(event: Event) {
  emits('update:modelValue', !!(event.target as HTMLInputElement).checked);
}
</script>

<template>
  <div class="form-group" :class="groupClass">
    <input v-bind="$attrs" type="checkbox" class="form-switch" @change="onUpdate($event)" :checked="modelValue" />
    <label :for="$attrs.id" :class="['form-label', { required: props.required }]">
      <slot name="label" :text="props.label" :required="required">
        {{ props.label || '&nbsp;' }}
      </slot>
    </label>
  </div>
  <slot />
</template>
