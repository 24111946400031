export default {
  index: {
    title: 'Invoice item templates',
    create: 'Create item',
  },
  attributes: {
    type: 'Type',
    source: 'Item source',
    price_type: 'Price type',
    default_description_sv: 'Default description (SWE)',
    default_description_en: 'Default description (ENG)',
    article: 'Article',
    invert: 'Invert the amount',
  },
  type: {
    system: 'System',
    manual: 'Manuell',
  },
  source: {
    billing_plan_base_fee: 'Fakturaplan grundarvode',
    billing_plan_separate_fee: 'Fakturaplan separat arvode',
    reported_time: 'Rapporterad tid',
    manual: 'Manuell',
    expense: 'Utlägg',
  },
  empty: 'No invoice item templates',
  tooltip: {
    destroy: 'Radera denna',
  },
  confirm: {
    destroy: {
      title: 'Delete invoice item template',
      text: 'Are you sure you want to delete this invoice item template?',
    },
  },
};
