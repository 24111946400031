import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import Users from '@/views/Users/Users.vue';
import User from '@/views/Users/User.vue';

const prefix = 'users';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: Users,
  },
  {
    path: `/${prefix}/edit/:uuid`,
    name: `${prefix}.edit`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    props: true,
    component: User,
  },
];

export default routes;
