import route from 'ziggy-js';
import { SearchParamsOption } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { IFinancialYearResource, IFinancialYearRequestBody } from '@/types/FinancialYear';

export default {
  async list(client: string, searchParams?: SearchParamsOption): Promise<IResponse<IFinancialYearResource[]>> {
    return await httpClient.get(route('financial-years.index', { client }), { searchParams }).json();
  },
  async store(client: string, body: IFinancialYearRequestBody): Promise<IResponse<IFinancialYearResource>> {
    return await httpClient.post(route('financial-years.store', { client }), { json: body }).json();
  },
  async update(client: string, body: IFinancialYearRequestBody): Promise<IResponse<IFinancialYearResource>> {
    return await httpClient.patch(route('financial-years.update', { client }), { json: body }).json();
  },
  async destroy(client: string): Promise<IResponse<never>> {
    return await httpClient.delete(route('financial-years.delete', { client })).json();
  },
};
