<script setup lang="ts">
import { reactive, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { debounce } from 'lodash';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { vOnClickOutside } from '@vueuse/components';

import {
  AppButton,
  FontIcon,
  FormInput,
  FormLabel,
  AppBox,
  AppBoxBody,
  AppLoader,
  HelpInformation,
} from '@/components';
import { ClientType, IClientSearchResource, IClientStoreRequestBody } from '@/types/Client';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useClients from '@/composables/useClients';
import useAuthStore from '@/store/AuthStore';
import useCountriesStore from '@/store/CountriesStore';
import { IUserListResource } from '@/types/User';
import { SWEDEN } from '@/constants/countries';
import ClientGeneralInfoForm from '@/components/ClientSection/ClientGeneralInfoForm.vue';

const searchCompanyData = reactive<{ term: string; results: IClientSearchResource[]; active: boolean }>({
  term: '',
  results: [],
  active: false,
});

const form = reactive<IClientStoreRequestBody>({
  type: ClientType.COMPANY,
  name: '',
  org_number: '',
  vat: '',
  category_uuid: '',
  main_phone: '',
  first_address: '',
  second_address: '',
  zip: '',
  city: '',
  country: SWEDEN,
  invoice_email: '',
  invoice_first_address: '',
  invoice_second_address: '',
  invoice_zip: '',
  invoice_city: '',
  invoice_country: '',
  responsible_user_uuid: '',
  start_financial_year_date: '',
  end_financial_year_date: '',
});

const { authenticatedUser } = useAuthStore();

const countriesStore = useCountriesStore();
const { getCountries } = countriesStore;
const { countries } = storeToRefs(countriesStore);

const loader = useLoader();
const submitLoader = useLoader();
const searchLoader = useLoader({ useProgress: false });
const basicInfoLoader = useLoader();
const router = useRouter();
const i18n = useI18n({ useScope: 'global' });
const { t } = i18n;
const { clientCategories, getClientCategories } = useClients();
const users = ref<IUserListResource[]>([]);

const onSearchInput = debounce(async () => {
  if (searchCompanyData.term.length > 0) {
    searchLoader.start();
    activateSearch();
    try {
      const response = await api.clients.search(searchCompanyData.term, form.type);
      searchCompanyData.results = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      searchLoader.finish();
    }
  } else {
    searchCompanyData.results = [];
    disableSearch();
  }
}, 300);

function onSearchFocus() {
  if (searchCompanyData.term !== '') {
    activateSearch();
  }
}

function activateSearch() {
  searchCompanyData.active = true;
}

function disableSearch() {
  searchCompanyData.active = false;
}

async function getUsers() {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append('without_pagination', '1');
    const response = await api.users.list({ searchParams });
    users.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

function formatOrganizationNumberByCountry(organizationNumber: string, country: string) {
  switch (country) {
    case SWEDEN:
      return organizationNumber.replace(/-/g, '');
    default:
      return organizationNumber;
  }
}

async function submit() {
  try {
    submitLoader.start();
    const { data } = await api.clients.store({
      ...form,
      org_number: formatOrganizationNumberByCountry(form.org_number, form.country),
    });
    await router.push({ name: 'clients.edit', params: { uuid: data.uuid } });
  } catch (error) {
    console.error(error);
  } finally {
    submitLoader.finish();
  }
}

async function onChooseClientBasicInfo(searchedClient: IClientSearchResource) {
  try {
    basicInfoLoader.start();
    const org_number =
      form.type === 'company' ? searchedClient.result.orgnumber : searchedClient.result.personal_number;
    const response = await api.clients.info.basic(org_number as string, form.type);
    await getClientCategories();
    form.name = response.basic_data.GETDATA_RESPONSE.NAME;
    form.org_number = response.basic_data.GETDATA_RESPONSE.ORGNR;
    form.vat = response.basic_data.GETDATA_RESPONSE.MOMS_NR;
    form.category_uuid =
      clientCategories.value.find(
        (category) => category.name === response.basic_data.GETDATA_RESPONSE.COMPANY_TYPE_TEXT,
      )?.name || '';
    form.first_address = response.basic_data.GETDATA_RESPONSE.ADDRESS;
    form.city = response.basic_data.GETDATA_RESPONSE.TOWN;
    form.zip = response.basic_data.GETDATA_RESPONSE.ZIPCODE;
    form.main_phone = response.basic_data.GETDATA_RESPONSE.TELEPHONE;

    searchCompanyData.term = '';
    searchCompanyData.results = [];
    disableSearch();
  } catch (error) {
    console.error(error);
  } finally {
    basicInfoLoader.finish();
  }
}

onMounted(async () => {
  loader.start();
  await Promise.all([getUsers(), getClientCategories(), getCountries()]);
  form.responsible_user_uuid = authenticatedUser!.uuid;
  loader.finish();
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div v-if="!loader.isLoading.value" class="container-wide">
    <div class="d-flex align-items-center mb-4">
      <AppButton
        @click.prevent="router.push({ name: 'clients.index' })"
        color="secondary"
        circle
        light
        v-tooltip="t('common.back')"
      >
        <FontIcon name="arrow-back-up" />
      </AppButton>
      <div class="d-flex align-items-end ml-3">
        <h1 class="mb-0" v-text="t('client.create.title')" />
        <HelpInformation class="ml-1" translation="client.create.help" />
      </div>
    </div>
    <form @submit.prevent="submit">
      <AppBox shadow>
        <AppBoxBody>
          <div class="row">
            <h2 class="col-12" v-text="t('client.form.search_client')" />
            <div class="col-md-6 col-lg-4 col-xl-3">
              <label class="form-label required" v-text="t('client.form.client_type')" />
              <div class="d-flex">
                <!-- TODO: FormCheck component -->
                <div class="form-group">
                  <input id="company" type="radio" class="form-check" :value="ClientType.COMPANY" v-model="form.type" />
                  <label for="company" class="form-label" v-text="t('client.form.company')" />
                </div>
                <div class="form-group ml-4">
                  <input
                    id="individual"
                    type="radio"
                    class="form-check"
                    :value="ClientType.INDIVIDUAL"
                    v-model="form.type"
                  />
                  <label for="individual" class="form-label" v-text="t('client.form.individual')" />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-8 col-xl-9" v-on-click-outside="disableSearch">
              <div class="form-group">
                <FormLabel html-for="search">{{ t(`client.form.client_${form.type}`) }}</FormLabel>
                <FormInput
                  type="search"
                  id="search"
                  icon="search"
                  @input="onSearchInput"
                  @focus="onSearchFocus"
                  v-model="searchCompanyData.term"
                  :disabled="basicInfoLoader.isLoading.value"
                  @keydown.enter.prevent
                >
                  <template v-if="searchCompanyData.active" #popup>
                    <div v-if="searchLoader.isLoading.value" class="text-center">
                      <AppLoader size="small" />
                    </div>
                    <ul :class="{ disabled: basicInfoLoader.isLoading.value }" v-else>
                      <li
                        class="text-neutral-500"
                        v-if="searchCompanyData.results.length === 0"
                        v-text="t('common.no_results')"
                      />
                      <li
                        class="hoverable pointer"
                        v-for="item in searchCompanyData.results"
                        :key="item.result.orgnumber || item.result.personal_number"
                        @click.stop="onChooseClientBasicInfo(item)"
                      >
                        <strong v-text="item.result.name" />
                        ({{ item.result.orgnumber || item.result.personal_number }})
                      </li>
                    </ul>
                  </template>
                </FormInput>
              </div>
            </div>
          </div>
        </AppBoxBody>
        <AppBoxBody>
          <ClientGeneralInfoForm
            mode="creating"
            :client-categories="clientCategories"
            :countries="countries"
            :users="users"
            :invoice-distribution="null"
            v-model:name="form.name"
            v-model:org-number="form.org_number"
            v-model:vat-number="form.vat"
            v-model:type="form.type"
            v-model:category-uuid="form.category_uuid"
            v-model:phone="form.main_phone"
            v-model:responsible-user-uuid="form.responsible_user_uuid"
            v-model:start-financial-year-date="form.start_financial_year_date"
            v-model:end-financial-year-date="form.end_financial_year_date"
            v-model:first-address="form.first_address"
            v-model:second-address="form.second_address"
            v-model:zip="form.zip"
            v-model:city="form.city"
            v-model:country="form.country"
            v-model:invoice-email="form.invoice_email"
            v-model:invoice-first-address="form.invoice_first_address"
            v-model:invoice-second-address="form.invoice_second_address"
            v-model:invoice-zip="form.invoice_zip"
            v-model:invoice-city="form.invoice_city"
            v-model:invoice-country="form.invoice_country"
          />
        </AppBoxBody>
        <AppBoxBody>
          <div class="pt-5 text-right">
            <AppButton color="secondary" :loading="submitLoader.isLoading.value">
              {{ t('common.create') }}
            </AppButton>
          </div>
        </AppBoxBody>
      </AppBox>
    </form>
  </div>
</template>
