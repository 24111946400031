<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useModal } from 'vue-final-modal';

import {
  IServiceCompanyContactRoleRequestBody,
  IServiceCompanyContactRoleResource,
  IServiceCompanyTypeBodyRequest,
  IServiceCompanyTypePreviewResource,
} from '@/types/ServiceCompany';
import useLoader from '@/composables/useLoader';
import {
  AppAlert,
  AppLoader,
  AppButton,
  AppBox,
  AppBoxBody,
  FontIcon,
  AppTable,
  AppTableHead,
  AppTableBody,
  AppTableTd,
  AppTableTr,
  AppTableTh,
  ServiceCompanyTypeModal,
  ServiceCompanyContactRoleModal,
  ConfirmModal,
  HelpInformation,
} from '@/components';
import api from '@/services/api';

const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();

const serviceCompanyTypes = ref<IServiceCompanyTypePreviewResource[]>([]);
const expandedItems = ref<string[]>([]);

async function getServiceCompanyTypes() {
  try {
    const response = await api.serviceCompanies.types.list();
    serviceCompanyTypes.value = response.data;
  } catch (error) {
    console.error(error);
  }
}

async function toggleItem(uuid: string) {
  if (expandedItems.value.includes(uuid)) {
    expandedItems.value = expandedItems.value.filter((item) => item !== uuid);
  } else {
    expandedItems.value.push(uuid);
  }
}

const serviceCompanyTypeModal = useModal({
  component: ServiceCompanyTypeModal,
  attrs: {
    async onCreate(form: IServiceCompanyTypeBodyRequest) {
      try {
        serviceCompanyTypeModal.patchOptions({ attrs: { loading: true } });
        await api.serviceCompanies.types.store(form);
        await getServiceCompanyTypes();
        await serviceCompanyTypeModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        serviceCompanyTypeModal.patchOptions({ attrs: { loading: false } });
      }
    },
    async onUpdate(uuid: string, form: IServiceCompanyTypeBodyRequest) {
      try {
        serviceCompanyTypeModal.patchOptions({ attrs: { loading: true } });
        await api.serviceCompanies.types.update(uuid, form);
        await getServiceCompanyTypes();
        await serviceCompanyTypeModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        serviceCompanyTypeModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      serviceCompanyTypeModal.close();
    },
    onClosed() {
      serviceCompanyTypeModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

const serviceCompanyContactRoleModal = useModal({
  component: ServiceCompanyContactRoleModal,
  attrs: {
    async onCreate(typeUuid: string, form: IServiceCompanyTypeBodyRequest) {
      try {
        serviceCompanyContactRoleModal.patchOptions({ attrs: { loading: true } });
        await api.serviceCompanies.types.roles.store(typeUuid, form);
        await getServiceCompanyTypes();
        await serviceCompanyContactRoleModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        serviceCompanyContactRoleModal.patchOptions({ attrs: { loading: false } });
      }
    },
    async onUpdate(typeUuid: string, roleUuid: string, form: IServiceCompanyContactRoleRequestBody) {
      try {
        serviceCompanyContactRoleModal.patchOptions({ attrs: { loading: true } });
        await api.serviceCompanies.types.roles.update(typeUuid, roleUuid, form);
        await getServiceCompanyTypes();
        await serviceCompanyContactRoleModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        serviceCompanyContactRoleModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      serviceCompanyContactRoleModal.close();
    },
    onClosed() {
      serviceCompanyContactRoleModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

const deleteTypeModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('service-company.type.confirm.destroy.title'),
    async onConfirm() {
      try {
        deleteTypeModal.patchOptions({ attrs: { loading: true } });
        const { uuid } = deleteTypeModal.options.attrs!.params;
        await api.serviceCompanies.types.destroy(uuid);
        await getServiceCompanyTypes();
        await deleteTypeModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        deleteTypeModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      deleteTypeModal.close();
    },
    onClosed() {
      deleteTypeModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

const deleteRoleModal = useModal({
  component: ConfirmModal,
  attrs: {
    title: t('service-company.role.confirm.destroy.title'),
    async onConfirm() {
      try {
        deleteRoleModal.patchOptions({ attrs: { loading: true } });
        const { typeUuid, roleUuid } = deleteRoleModal.options.attrs!.params;
        await api.serviceCompanies.types.roles.destroy(typeUuid, roleUuid);
        await getServiceCompanyTypes();
        await deleteRoleModal.close();
      } catch (error) {
        console.error(error);
      } finally {
        deleteRoleModal.patchOptions({ attrs: { loading: false } });
      }
    },
    onCancel() {
      deleteRoleModal.close();
    },
    onClosed() {
      deleteRoleModal.patchOptions({ attrs: { loading: false } });
    },
  },
});

function onCreateRole(typeUuid: string) {
  serviceCompanyContactRoleModal.patchOptions({
    attrs: { title: t('service-company.role.create.title'), name: null, typeUuid, roleUuid: null },
  });
  serviceCompanyContactRoleModal.open();
}

function onEditRole(typeUuid: string, role: IServiceCompanyContactRoleResource) {
  serviceCompanyContactRoleModal.patchOptions({
    attrs: {
      title: t('service-company.role.edit.title', { name: role.name }),
      name: role.name,
      typeUuid,
      roleUuid: role.uuid,
    },
  });
  serviceCompanyContactRoleModal.open();
}

function onDeleteRole(typeUuid: string, role: IServiceCompanyContactRoleResource) {
  deleteRoleModal.patchOptions({
    attrs: {
      params: { typeUuid, roleUuid: role.uuid },
      message: t('service-company.role.confirm.destroy.text', { name: role.name }),
    },
  });
  deleteRoleModal.open();
}

function onCreateType() {
  serviceCompanyTypeModal.patchOptions({
    attrs: { title: t('service-company.type.create.title'), name: null, uuid: null },
  });
  serviceCompanyTypeModal.open();
}

function onEditType(type: IServiceCompanyTypePreviewResource) {
  serviceCompanyTypeModal.patchOptions({
    attrs: { title: t('service-company.type.edit.title', { name: type.name }), name: type.name, uuid: type.uuid },
  });
  serviceCompanyTypeModal.open();
}

// eslint-disable-next-line
function onDeleteType(type: IServiceCompanyTypePreviewResource) {
  deleteTypeModal.patchOptions({
    attrs: {
      params: { uuid: type.uuid },
      message: t('service-company.type.confirm.destroy.text', { name: type.name }),
    },
  });
  deleteTypeModal.open();
}

function isAccountingCompany(type: IServiceCompanyTypePreviewResource) {
  return type.name === 'Accounting';
}

onMounted(async () => {
  loader.start();
  await getServiceCompanyTypes();
  loader.finish();
});
</script>

<template>
  <div v-if="loader.isLoading.value" class="text-center">
    <AppLoader size="large" />
  </div>
  <div class="container-fluid" v-else>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-end">
        <h1 class="mb-0" v-text="t('service-company.templates.title')" />
        <HelpInformation class="ml-1" translation="service-company.templates.help" />
      </div>
      <AppButton color="secondary" @click.prevent="onCreateType">
        {{ t('service-company.templates.add') }}
      </AppButton>
    </div>
    <AppAlert v-if="serviceCompanyTypes.length === 0">{{ t('service-company.type.empty') }}</AppAlert>
    <AppBox shadow v-else>
      <AppBoxBody>
        <AppBox
          :class="{ 'mb-2': index !== serviceCompanyTypes.length - 1 }"
          border
          v-for="(type, index) in serviceCompanyTypes"
          :key="type.uuid"
        >
          <AppBoxBody>
            <div class="d-flex justify-content-between align-items-center">
              <h3 @click.prevent="toggleItem(type.uuid)" class="mb-0 pointer" v-text="type.name" />
              <div>
                <template v-if="!isAccountingCompany(type)">
                  <AppButton
                    v-tooltip="t('service-company.type.tooltip.edit', { name: type.name })"
                    size="small"
                    @click.prevent="onEditType(type)"
                    light
                    circle
                  >
                    <FontIcon name="pencil" />
                  </AppButton>
                </template>
                <AppButton class="ml-2" size="small" @click.prevent="toggleItem(type.uuid)" light circle>
                  <FontIcon :name="expandedItems.includes(type.uuid) ? 'chevron-up' : 'chevron-down'" />
                </AppButton>
              </div>
            </div>
            <div class="pt-3" v-show="expandedItems.includes(type.uuid)">
              <AppAlert v-if="type.roles.length === 0">{{ t('service-company.role.empty') }}</AppAlert>
              <AppTable v-else hoverable>
                <AppTableHead>
                  <AppTableTr>
                    <AppTableTh class="pl-0" nowrap>{{ t('service-company.role.attributes.name') }}</AppTableTh>
                    <AppTableTh class="pr-0 text-right" nowrap v-if="!isAccountingCompany(type)">
                      {{ t('common.actions') }}
                    </AppTableTh>
                  </AppTableTr>
                </AppTableHead>
                <AppTableBody>
                  <AppTableTr v-for="role in type.roles" :key="role.uuid">
                    <AppTableTd class="pl-0" nowrap>
                      <strong v-text="role.name" />
                    </AppTableTd>
                    <AppTableTd v-if="!isAccountingCompany(type)" class="text-right pr-0" nowrap>
                      <AppButton
                        v-tooltip="t('service-company.role.tooltip.edit', { name: role.name })"
                        size="small"
                        @click.prevent="onEditRole(type.uuid, role)"
                        light
                        circle
                      >
                        <FontIcon name="pencil" />
                      </AppButton>
                      <AppButton
                        v-tooltip="t('service-company.role.tooltip.destroy', { name: role.name })"
                        class="ml-2"
                        size="small"
                        color="danger"
                        @click.prevent="onDeleteRole(type.uuid, role)"
                        light
                        circle
                      >
                        <FontIcon name="trash" />
                      </AppButton>
                    </AppTableTd>
                  </AppTableTr>
                </AppTableBody>
              </AppTable>
              <AppButton
                v-if="!isAccountingCompany(type)"
                class="mt-3"
                v-tooltip="t('service-company.role.tooltip.create')"
                size="small"
                color="success"
                @click.prevent="onCreateRole(type.uuid)"
                light
                circle
              >
                <FontIcon name="plus" />
              </AppButton>
            </div>
          </AppBoxBody>
        </AppBox>
      </AppBoxBody>
    </AppBox>
  </div>
</template>
