<script setup lang="ts">
import { computed } from 'vue';
import { Menu } from 'floating-vue';
import { useI18n } from 'vue-i18n';

import { FontIcon } from '@/components';

const i18n = useI18n({ useScope: 'global' });

type Props = {
  hidden?: boolean;
  icon?: string;
  translation?: string;
  maxWidth?: number;
};

const props = withDefaults(defineProps<Props>(), {
  icon: 'info-circle',
  translation: '',
  maxWidth: 560,
});

const isVisible = computed(() => {
  return props.translation && i18n.te(props.translation) && i18n.t(props.translation) && !props.hidden;
});
</script>

<template>
  <Menu v-if="isVisible" placement="bottom-start">
    <FontIcon class="trigger" :name="icon" />
    <template #popper>
      <div :style="{ 'max-width': maxWidth + 'px' }" class="content" v-html="i18n.t(translation)" />
    </template>
  </Menu>
</template>

<style scoped>
.trigger {
  cursor: help;
  font-size: 1.35rem;
  color: var(--color-neutral-400-hex);
}
.content {
  padding: 1.5rem;
}
</style>
