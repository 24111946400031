import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  ISalaryCodeRequest,
  ISalaryCodeResource,
  ISalarySettingsRequest,
  ISalarySettingsResource,
} from '@/types/Salary';

export default {
  codes: {
    async index(options: Options = {}) {
      return await httpClient.get(route('salary-codes.index'), options).json<IResponse<ISalaryCodeResource[]>>();
    },
    async store(json: ISalaryCodeRequest, options: Options = {}) {
      return await httpClient
        .post(route('salary-codes.create'), { ...options, json })
        .json<IResponse<ISalaryCodeResource>>();
    },
    async get(salaryCode: number, options: Options = {}) {
      return await httpClient
        .get(route('salary-codes.view', { salaryCode }), options)
        .json<IResponse<ISalaryCodeResource>>();
    },
    async update(salaryCode: number, json: ISalaryCodeRequest, options: Options = {}) {
      return await httpClient
        .patch(route('salary-codes.update', { salaryCode }), { ...options, json })
        .json<IResponse<ISalaryCodeResource>>();
    },
    async delete(salaryCode: number, options: Options = {}) {
      return await httpClient.delete(route('salary-codes.update', { salaryCode }), options).json<void>();
    },
  },
  settings: {
    async index(options: Options = {}) {
      return await httpClient
        .get(route('salary-settings.index'), options)
        .json<IResponse<ISalarySettingsResource | null>>();
    },
    async update(json: ISalarySettingsRequest, options: Options = {}) {
      return await httpClient
        .put(route('salary-settings.set'), { ...options, json })
        .json<IResponse<ISalarySettingsResource>>();
    },
  },
};
