import route from 'ziggy-js';
import { Options } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import {
  IUserResource,
  IUserListResource,
  IUserUpdateRequest,
  IUserWorkingDays,
  IUserDeadlineEventResource,
  IVehicleRequest,
  IVehicleResource,
} from '@/types/User';
import { IEventPreviewResource, IEventTimelineWeek } from '@/types/Event';
import { IWorkRateRequest, IWorkRateResource } from '@/types/WorkRate';

export default {
  list(options: Options = {}) {
    if (options && options.searchParams && options.searchParams instanceof URLSearchParams) {
      options.searchParams.append('include_without_meta', '1');
    }
    return httpClient.get(route('users.index'), options).json<IResponse<IUserListResource[]>>();
  },
  get(user_uuid: string, options: Options = {}) {
    return httpClient.get(route('users.get', { user_uuid }), options).json<IResponse<IUserResource>>();
  },
  update(user_uuid: string, json: IUserUpdateRequest, options: Options = {}) {
    return httpClient
      .patch(route('users.update', { user_uuid }), { ...options, json })
      .json<IResponse<IUserResource>>();
  },
  currentAuthenticatedUser(options: Options = {}) {
    return httpClient.get(route('users.get-current'), options).json<IUserResource>();
  },
  workingDays(user: string, options: Options = {}) {
    return httpClient.get(route('users.working-days.index', { user }), options).json<IUserWorkingDays>();
  },
  events: {
    index(user: string, options: Options = {}) {
      return httpClient.get(route('users.events.index', { user }), options).json<IResponse<IEventPreviewResource[]>>();
    },
    deadlines(user: string, options: Options = {}) {
      return httpClient
        .get(route('users.events.deadlines', { user }), options)
        .json<IResponse<IUserDeadlineEventResource[]>>();
    },

    timeline(user: string, options: Options = {}) {
      return httpClient
        .get(route('users.events.time-line', { user }), options)
        .json<IResponse<Record<string, IEventTimelineWeek>>>();
    },
  },
  prices: {
    destroy(userPrice: number, options: Options = {}) {
      return httpClient.delete(route('users.user-prices.delete', { userPrice }), options).json<void>();
    },
  },
  portal: {
    companyContacts: {
      activate(companyContact: string, options: Options = {}) {
        return httpClient
          .post(route('users.portal-users.company-contacts.activate', { companyContact }), options)
          .json<void>();
      },
      deactivate(companyContact: string, options: Options = {}) {
        return httpClient
          .post(route('users.portal-users.company-contacts.deactivate', { companyContact }), options)
          .json<void>();
      },
    },
    serviceContacts: {
      activate(serviceCompanyContact: string, options: Options = {}) {
        return httpClient
          .post(route('users.portal-users.service-contacts.activate', { serviceCompanyContact }), options)
          .json<void>();
      },
      deactivate(serviceCompanyContact: string, options: Options = {}) {
        return httpClient
          .post(route('users.portal-users.service-contacts.deactivate', { serviceCompanyContact }), options)
          .json<void>();
      },
    },
  },
  workRates: {
    index(user: string, options: Options = {}) {
      return httpClient.get(route('users.work-rates.index', { user }), options).json<IResponse<IWorkRateResource[]>>();
    },
    store(user: string, json: IWorkRateRequest, options: Options = {}) {
      return httpClient
        .post(route('users.work-rates.create', { user }), { ...options, json })
        .json<IResponse<IWorkRateResource>>();
    },
    get(user: string, workRate: number, options: Options = {}) {
      return httpClient
        .get(route('users.work-rates.get', { user, workRate }), options)
        .json<IResponse<IWorkRateResource>>();
    },
    update(user: string, workRate: number, json: IWorkRateRequest, options: Options = {}) {
      return httpClient
        .patch(route('users.work-rates.update', { user, workRate }), { ...options, json })
        .json<IResponse<IWorkRateResource>>();
    },
    delete(user: string, workRate: number, options: Options = {}) {
      return httpClient.delete(route('users.work-rates.delete', { user, workRate }), options).json<void>();
    },
  },
  vehicles: {
    index(user: string, options: Options = {}) {
      return httpClient.get(route('users.vehicles.index', { user }), options).json<IResponse<IVehicleResource[]>>();
    },
    store(user: string, json: IVehicleRequest, options: Options = {}) {
      return httpClient
        .post(route('users.vehicles.create', { user }), { ...options, json })
        .json<IResponse<IVehicleResource>>();
    },
    update(user: string, vehicle: number, json: IVehicleRequest, options: Options = {}) {
      return httpClient
        .put(route('users.vehicles.update', { user, vehicle }), { ...options, json })
        .json<IResponse<IVehicleResource>>();
    },
    delete(user: string, vehicle: number, options: Options = {}) {
      return httpClient.delete(route('users.vehicles.delete', { user, vehicle }), options).json<void>();
    },
  },
};
