export default {
  tracked_time: {
    edit: {
      title: 'Edit reported time',
      add: 'Add reported time',
    },
    attributes: {
      name: 'Name',
      type: 'Type',
      project: 'Project',
      client: 'Client',
      scheduled_time: 'Scheduled time',
      estimated_time: 'Estimated time',
      reported_time: 'Reported time',
      done: 'Done',
      task_is_done: 'Task is done',
      week: 'Week',
      time: 'Time',
      note: 'Note',
      date: 'Date',
    },
    tooltip: {
      edit: 'Edit reported time',
    },
    empty: 'No reported time',
    confirm: {
      destroy: {
        title: 'Delete reported time',
        text: 'Are you sure you want to delete reported time?',
      },
    },
  },
  type: {
    project_task: 'Project task',
    absence: 'Absence',
    activity: 'Activity',
  },
  split: {
    title_project_task: 'Split the task',
    title_activity: 'Split the activity',
  },
  confirm: {
    destroy: {
      title: 'Delete event',
      text: 'Are you sure you want to delete event?',
      activity: {
        confirm: 'Delete this activity',
        confirm_with_future_events: 'Delete this and all future activities ',
      },
      project_task: {
        confirm: 'Delete this event',
        confirm_with_future_events: 'Delete this event and all future events',
      },
    },
  },
  event_has_tracked_time: 'The event has tracked time',
  event_is_done: 'The event is done',
  event_is_invoiced: 'The event is invoiced',

  empty: 'No events',
};
