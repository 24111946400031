<script setup lang="ts">
import { onMounted, provide, Ref, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouteQuery } from '@vueuse/router';
import { useLocalStorage } from '@vueuse/core';
import { useModal } from 'vue-final-modal';
import { Dropdown } from 'floating-vue';
import { DateTime } from 'luxon';

import {
  AppButton,
  AppLoader,
  FontIcon,
  TimeEntryActivityModal,
  TimeEntryDropdownOptions,
  TimeEntryProjectTaskModal,
  TimelineActivities,
  TimelineClient,
  TimelineProject,
  TimelineSheets,
  TimelineWeek,
  UserDeadlines,
} from '@/components';
import api from '@/services/api';
import useLoader from '@/composables/useLoader';
import useDate from '@/composables/useDate';
import useAuthStore from '@/store/AuthStore';
import { IEventTimelineWeek } from '@/types/Event';
import { ActivityModalProps } from '@/types/Activity';
import { ProjectTaskModalProps } from '@/types/ProjectTask';

const { getCurrentYearAndWeek } = useDate();
const { t } = useI18n({ useScope: 'global' });
const loader = useLoader();
const actionLoader = useLoader();
const weekStartLoader = useLoader();
const weekEndLoader = useLoader();
const { authenticatedUser } = useAuthStore();

const hideDone = useLocalStorage<number>('we_plan_dashboard_hide_done', 0);

const currentWeekNumber = ref<number>(getCurrentYearAndWeek());

const userDeadlinesComponent = shallowRef<InstanceType<typeof UserDeadlines>>();

const loadingActivitiesToDone = ref<number[]>([]);
const loadingProjectTasksToDone = ref<number[]>([]);
const loadingWeeksForward = ref<string[]>([]);

const expandedWeeks = ref<string[]>([]);
const expandedClients = ref<string[]>([]);
const collapsedProjects = ref<string[]>([]);
const expandedTimeSheets = ref<string[]>([]);
const expandedActivities = ref<string[]>([]);
const weekStart = useRouteQuery('week-start', '-1', { transform: Number });
const weekEnd = useRouteQuery('week-end', '1', { transform: Number });
const timeline = ref<Record<string, IEventTimelineWeek>>({});

function onProjectTaskCreate(attrs: ProjectTaskModalProps) {
  const { open, close, destroy } = useModal({
    component: TimeEntryProjectTaskModal,
    attrs: {
      ...attrs,
      onCreated() {
        close();
        getTimeline();
        userDeadlinesComponent.value?.getDeadlines();
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onProjectTaskEdit(id: number) {
  const { open, close, destroy } = useModal({
    component: TimeEntryProjectTaskModal,
    attrs: {
      id,
      onSplit() {
        getTimeline();
        userDeadlinesComponent.value?.getDeadlines();
        close();
      },
      onUpdated() {
        close();
        getTimeline();
        userDeadlinesComponent.value?.getDeadlines();
      },
      onDeleted() {
        getTimeline();
        userDeadlinesComponent.value?.getDeadlines();
        close();
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function onProjectTaskDone(idToDone: number) {
  try {
    actionLoader.start();
    loadingProjectTasksToDone.value.push(idToDone);
    await api.events.done(idToDone);
    await getTimeline(false);
  } catch (error) {
    console.error(error);
  } finally {
    loadingProjectTasksToDone.value = loadingProjectTasksToDone.value.filter((id) => id !== idToDone);
    actionLoader.finish();
  }
}

function onActivityCreate(attrs: ActivityModalProps) {
  const { open, close, destroy } = useModal({
    component: TimeEntryActivityModal,
    attrs: {
      ...attrs,
      onCreated() {
        close();
        getTimeline();
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

function onActivityEdit(id: number) {
  const { close, open, destroy } = useModal({
    component: TimeEntryActivityModal,
    attrs: {
      id,
      onSplit() {
        getTimeline();
        close();
      },
      onUpdated() {
        getTimeline();
        close();
      },
      onDeleted() {
        getTimeline();
        close();
      },
      onClose() {
        close();
      },
      onClosed() {
        destroy();
      },
    },
  });
  open();
}

async function onActivityDone(idToDone: number) {
  try {
    actionLoader.start();
    loadingActivitiesToDone.value.push(idToDone);
    await api.events.done(idToDone);
    await getTimeline(false);
  } catch (error) {
    console.error(error);
  } finally {
    loadingActivitiesToDone.value = loadingActivitiesToDone.value.filter((id) => id !== idToDone);
    actionLoader.finish();
  }
}

function toggleWeek(uniqueId: string) {
  if (expandedWeeks.value.includes(uniqueId)) {
    expandedWeeks.value = expandedWeeks.value.filter((item) => item !== uniqueId);
  } else {
    expandedWeeks.value.push(uniqueId);
  }
}

function toggleClient(uniqueId: string) {
  if (expandedClients.value.includes(uniqueId)) {
    expandedClients.value = expandedClients.value.filter((item) => item !== uniqueId);
  } else {
    expandedClients.value.push(uniqueId);
  }
}

function toggleProject(uniqueId: string) {
  if (collapsedProjects.value.includes(uniqueId)) {
    collapsedProjects.value = collapsedProjects.value.filter((item) => item !== uniqueId);
  } else {
    collapsedProjects.value.push(uniqueId);
  }
}

function toggleTimeSheet(uniqueId: string) {
  if (expandedTimeSheets.value.includes(uniqueId)) {
    expandedTimeSheets.value = expandedTimeSheets.value.filter((item) => item !== uniqueId);
  } else {
    expandedTimeSheets.value.push(uniqueId);
  }
}

function toggleActivity(uniqueId: string) {
  if (expandedActivities.value.includes(uniqueId)) {
    expandedActivities.value = expandedActivities.value.filter((item) => item !== uniqueId);
  } else {
    expandedActivities.value.push(uniqueId);
  }
}

async function getTimeline(loadable = true) {
  try {
    if (loadable) {
      loader.start();
    }
    const searchParams = new URLSearchParams();
    searchParams.append('weekStart', weekStart.value.toString());
    searchParams.append('weekEnd', weekEnd.value.toString());
    const response = await api.users.events.timeline(authenticatedUser.uuid, { searchParams });
    timeline.value = response.data;
  } catch (error) {
    console.error(error);
  } finally {
    if (loadable) {
      loader.finish();
    }
  }
}

function isAllTasksDone(project: IEventTimelineWeek['tasks']['events'][0]['projects'][0]) {
  return project.tasks.every((task) => !!task.done_at);
}

async function loadStartWeek() {
  try {
    weekStartLoader.start();
    const week = weekStart.value - 1;
    const searchParams = new URLSearchParams();
    searchParams.append('weekStart', week.toString());
    searchParams.append('weekEnd', week.toString());
    const response = await api.users.events.timeline(authenticatedUser.uuid, { searchParams });
    weekStart.value = week;
    timeline.value = { ...response.data, ...timeline.value };
  } catch (error) {
    console.error(error);
  } finally {
    weekStartLoader.finish();
  }
}

async function loadEndWeek() {
  try {
    weekEndLoader.start();
    const week = weekEnd.value + 1;
    const searchParams = new URLSearchParams();
    searchParams.append('weekStart', week.toString());
    searchParams.append('weekEnd', week.toString());
    const response = await api.users.events.timeline(authenticatedUser.uuid, { searchParams });
    weekEnd.value = week;
    timeline.value = { ...timeline.value, ...response.data };
  } catch (error) {
    console.error(error);
  } finally {
    weekEndLoader.finish();
  }
}

async function onMoveWeekForward(week: string) {
  try {
    loadingWeeksForward.value.push(week);
    const searchParams = new URLSearchParams();
    searchParams.append('week', week);
    searchParams.append('user_uuid', authenticatedUser.uuid);
    await api.events.moveWeekForward({ searchParams });
    await getTimeline(false);
  } catch (error) {
    console.error(error);
  } finally {
    loadingWeeksForward.value = loadingWeeksForward.value.filter((w) => w !== week);
  }
}

function weekDragOver(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.box.droppable');
  if (element) element.classList.add('over');
}

function weekDragLeave(event: DragEvent) {
  const element = (event.target as HTMLElement).closest('.box.droppable');
  if (element) element.classList.remove('over');
}

async function weekDrop(event: DragEvent, toWeek: string) {
  event.preventDefault();
  event.stopPropagation();
  if (!event.dataTransfer || !event.dataTransfer.getData('event_ids')) return;
  const element = (event.target as HTMLElement).closest('.box.droppable');
  if (element) element.classList.remove('over');
  const fromWeek = event.dataTransfer.getData('from_week');
  const type = event.dataTransfer.getData('type');
  if (type === 'project_task') {
    const startDate = event.dataTransfer.getData('start_date');
    const endDate = event.dataTransfer.getData('end_date');
    const endWeek = Number(
      `${DateTime.fromISO(endDate).toFormat('kkkk')}${DateTime.fromISO(endDate)
        .weekNumber.toString()
        .padStart(2, '0')}`,
    );
    const startWeek = Number(
      `${DateTime.fromISO(startDate).toFormat('kkkk')}${DateTime.fromISO(startDate)
        .weekNumber.toString()
        .padStart(2, '0')}`,
    );
    if (parseInt(toWeek) > endWeek || parseInt(toWeek) < startWeek) return;
  }
  if (parseInt(toWeek) < getCurrentYearAndWeek() || fromWeek === toWeek) return;
  const ids = event.dataTransfer
    .getData('event_ids')
    .split(',')
    .map((id) => parseInt(id));
  try {
    loader.start();
    await api.events.move({ ids, week: parseInt(toWeek) });
    await getTimeline();
  } catch (error) {
    console.error(error);
  } finally {
    loader.finish();
  }
  return false;
}

function startProjectDrag(
  event: DragEvent,
  project: IEventTimelineWeek['tasks']['events'][0]['projects'][0],
  fromWeek: string,
) {
  (event.target as HTMLDivElement).classList.add('dragging');
  if (!event.dataTransfer) return;
  event.dataTransfer.dropEffect = 'move';
  event.dataTransfer.effectAllowed = 'move';
  event.dataTransfer.setData('from_week', fromWeek);
  event.dataTransfer.setData('type', 'project_task');
  event.dataTransfer.setData('start_date', project.start_date);
  event.dataTransfer.setData('end_date', project.end_date);
  event.dataTransfer.setData(
    'event_ids',
    project.tasks
      .filter((task) => task.done_at === null)
      .map(({ id }) => id)
      .join(','),
  );
}

function endProjectDrag(event: DragEvent) {
  (event.target as HTMLDivElement).classList.remove('dragging');
}

function taskStartDrag(
  event: DragEvent,
  id: number,
  fromWeek: string,
  type: 'project_task' | 'activity',
  startDate: string | null,
  endDate: string | null,
) {
  event.stopPropagation();
  if (!event.dataTransfer) return;
  (event.target as HTMLDivElement).classList.add('dragging');
  event.dataTransfer.dropEffect = 'move';
  event.dataTransfer.effectAllowed = 'move';
  event.dataTransfer.setData('from_week', fromWeek);
  if (type === 'project_task' && endDate && startDate) {
    event.dataTransfer.setData('start_date', startDate);
    event.dataTransfer.setData('end_date', endDate);
  }
  event.dataTransfer.setData('type', type);
  event.dataTransfer.setData('event_ids', id.toString());
}

function taskEndDrag(event: DragEvent) {
  (event.target as HTMLDivElement).classList.remove('dragging');
}

onMounted(getTimeline);

provide<Ref<number>>('hide_done', hideDone);
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-9">
        <div v-if="loader.isLoading.value" class="text-center">
          <AppLoader size="large" />
        </div>
        <div v-else>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h2 class="mb-0" v-text="t('dashboard.index.title')" />
            <Dropdown placement="bottom-end" :distance="10">
              <AppButton color="secondary">
                {{ t('dashboard.index.create') }}
                <FontIcon name="chevron-down" />
              </AppButton>
              <template #popper="{ hide }">
                <TimeEntryDropdownOptions
                  @on-absence="onActivityCreate($event)"
                  @on-project-task="onProjectTaskCreate($event)"
                  @on-internal="onActivityCreate($event)"
                  :hide="hide"
                />
              </template>
            </Dropdown>
          </div>
          <div class="form-group">
            <input
              id="hide_done"
              type="checkbox"
              class="form-check"
              v-model="hideDone"
              :true-value="1"
              :false-value="0"
            />
            <label for="hide_done" class="form-label" v-text="t('dashboard.index.hide_done')" />
          </div>
          <!-- Load start week start -->
          <AppButton
            class="mb-3"
            light
            size="small"
            @click.prevent="loadStartWeek"
            :loading="weekStartLoader.isLoading.value"
            :disabled="weekStartLoader.isLoading.value || weekEndLoader.isLoading.value"
          >
            {{ t('dashboard.buttons.load_prev_week') }}
            <FontIcon name="chevrons-up" />
          </AppButton>
          <!-- Load start week end -->

          <!-- Weeks start -->
          <TransitionGroup name="list" tag="div">
            <div class="mb-3" v-for="(week, weekNumber) in timeline" :key="weekNumber">
              <TimelineWeek
                :class="{ droppable: Number(weekNumber) >= currentWeekNumber }"
                :expanded="expandedWeeks.includes(weekNumber)"
                :data="week"
                :week-number="weekNumber"
                @toggle="toggleWeek($event)"
                @move-week-forward="onMoveWeekForward($event)"
                @drop.prevent="weekDrop($event, weekNumber)"
                @dragenter.prevent
                @dragover.prevent="weekDragOver"
                @dragleave.prevent="weekDragLeave"
                :is-current="getCurrentYearAndWeek() === +weekNumber"
                :is-completed="week.is_completed"
                :is-forward-week-loading="loadingWeeksForward.includes(weekNumber)"
              >
                <div v-if="expandedWeeks.includes(weekNumber)">
                  <!-- Project tasks -->
                  <div style="margin-top: 1px" v-for="client in week.tasks.events" :key="client.uuid + weekNumber">
                    <TimelineClient
                      :expanded="expandedClients.includes(client.uuid + weekNumber)"
                      :data="client"
                      :user-working-time-minutes="week.user_working_time_minutes"
                      @toggle="toggleClient($event + weekNumber)"
                      v-if="!(hideDone && client.projects.every((project) => isAllTasksDone(project)))"
                    />
                    <template v-if="expandedClients.includes(client.uuid + weekNumber)">
                      <div style="margin-top: 1px" v-for="project in client.projects" :key="project.id">
                        <TimelineProject
                          :class="{ draggable: !isAllTasksDone(project) }"
                          :draggable="!isAllTasksDone(project)"
                          :are-all-task-done="isAllTasksDone(project)"
                          :client-uuid="client.uuid"
                          :data="project"
                          :collapsed="collapsedProjects.includes(project.id + client.uuid + weekNumber)"
                          :loading-tasks-to-done="loadingProjectTasksToDone"
                          @dragstart="startProjectDrag($event, project, weekNumber)"
                          @dragend="endProjectDrag($event)"
                          @toggle="toggleProject($event + client.uuid + weekNumber)"
                          @task-edit="onProjectTaskEdit($event)"
                          @task-done="onProjectTaskDone($event)"
                          @task-start-drag="
                            (event, id) =>
                              taskStartDrag(event, id, weekNumber, 'project_task', project.start_date, project.end_date)
                          "
                          @task-end-drag="taskEndDrag($event)"
                        />
                      </div>
                    </template>
                  </div>
                  <!-- Internal activities -->
                  <TimelineActivities
                    :expanded="expandedActivities.includes(weekNumber + 'activity')"
                    :data="week.activities"
                    :week="weekNumber"
                    :loading-activities-to-done="loadingActivitiesToDone"
                    @toggle="toggleActivity($event + 'activity')"
                    @task-start-drag="(event, id) => taskStartDrag(event, id, weekNumber, 'activity', null, null)"
                    @task-end-drag="taskEndDrag($event)"
                    @edit="onActivityEdit($event)"
                    @done="onActivityDone($event)"
                  />

                  <!-- TimeSheet -->
                  <div style="border-top: 1px solid var(--color-neutral-200-hex)">
                    <TimelineSheets
                      :expanded="expandedTimeSheets.includes(weekNumber)"
                      :data="week.time_sheet"
                      :week="weekNumber"
                      @toggle="toggleTimeSheet($event)"
                      @activity-create="onActivityCreate($event)"
                      @project-task-create="onProjectTaskCreate($event)"
                      @activity-edit="onActivityEdit($event)"
                      @project-task-edit="onProjectTaskEdit($event)"
                    />
                  </div>
                </div>
              </TimelineWeek>
            </div>
          </TransitionGroup>
          <!-- Weeks end -->

          <!-- Load end week start -->
          <AppButton
            class="mt-2"
            light
            size="small"
            @click.prevent="loadEndWeek"
            :loading="weekEndLoader.isLoading.value"
            :disabled="weekEndLoader.isLoading.value || weekStartLoader.isLoading.value"
          >
            {{ t('dashboard.buttons.load_next_week') }}
            <FontIcon name="chevrons-down" />
          </AppButton>
          <!-- Load end week end -->
        </div>
      </div>
      <div class="col-md-3">
        <UserDeadlines
          ref="userDeadlinesComponent"
          :user-uuid="authenticatedUser.uuid"
          :week-number="getCurrentYearAndWeek()"
          @updated="getTimeline"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.draggable {
  cursor: pointer;
  user-select: none;
  &.dragging {
    opacity: 0.25;
    background-color: white;
  }
}
.droppable {
  &.over {
    background-color: var(--color-neutral-100);
  }
}
</style>
