export default {
  index: {
    title: 'Time sheets',
    help: '',
  },
  show: {
    title: 'Time sheet details: {month}-{year}, {username}',
    help: '',
  },
  form: {
    status: 'Status',
  },

  empty: 'No time sheets',

  // Attributes
  attributes: {
    total_tracked_time: 'Total tracked time',
    time_period: 'Time period',
    status: 'Status',
    review_status: 'Review status',
    user: 'User',
  },

  status: {
    unlocked: 'Unlocked',
    locked: 'Locked',
  },

  review_status: {
    none: 'Not reviewed',
    not_approved: 'Not approved',
    approved: 'Approved',
  },

  tooltip: {
    lock: 'Lock time sheet',
    unlock: 'Unlock time sheet',
    approve: 'Approve',
    not_approve: 'Not approve',
  },

  // Confirms
  confirm: {
    approve: {
      title: 'Approve the time sheet',
      text: 'Are you sure you want to approve or refuse this time sheet?',
    },
    lock: {
      title: 'Lock the time sheet',
      text: 'Are you sure you want to lock this time sheet?',
    },
    unlock: {
      title: 'Unlock the time sheet',
      text: 'Are you sure you want to unlock this time sheet?',
    },
  },
};
