import { RouteRecordRaw } from 'vue-router';

import MainLayout from '@/layouts/MainLayout.vue';
import TimeSheets from '@/views/TimeSheets/TimeSheets.vue';
import TimeSheet from '@/views/TimeSheets/TimeSheet.vue';

const prefix = 'time-sheets';

const routes: RouteRecordRaw[] = [
  {
    path: `/${prefix}`,
    name: `${prefix}.index`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: TimeSheets,
  },
  {
    path: `/${prefix}/:id`,
    name: `${prefix}.show`,
    meta: {
      requiresAuth: true,
      layout: MainLayout,
    },
    component: TimeSheet,
    props: (route) => ({
      ...route.params,
      id: Number(route.params.id),
    }),
  },
];

export default routes;
