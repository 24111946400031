import { createI18n } from 'vue-i18n';
import { useLocalStorage } from '@vueuse/core';

import { Locale } from '@/types/Locale';
import en from '@/i18n/en';
import sv from '@/i18n/sv';

type MessageSchema = typeof en;

const i18n = createI18n<[MessageSchema], Locale>({
  legacy: false,
  locale: useLocalStorage<Locale>('locale', 'sv').value,
  fallbackLocale: 'en',
  messages: { sv, en },
  warnHtmlMessage: false,
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'SEK',
        notation: 'standard',
      },
    },
    sv: {
      currency: {
        style: 'currency',
        currency: 'SEK',
        notation: 'standard',
      },
    },
  },
  datetimeFormats: {
    en: {
      weekday: {
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
      },
      short: {
        day: '2-digit',
        month: 'short',
      },
      monthyear: {
        month: 'short',
        year: '2-digit',
      },
      long: {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
      },
    },
    sv: {
      weekday: {
        weekday: 'short',
        day: '2-digit',
        month: '2-digit',
      },
      short: {
        day: '2-digit',
        month: 'short',
      },
      monthyear: {
        month: 'short',
        year: '2-digit',
      },
      long: {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
      },
    },
  },
});

export default i18n;
