export default {
  index: {
    title: 'Services',
    help: '',
    create: 'Create service',
    filters: {
      name: 'Service name',
    },
  },
  create: {
    title: 'Create service',
    help: '',
  },
  edit: {
    title: 'Edit service: {name}',
    help: '',
  },
  form: {
    general_info: 'General info',
  },

  checkpoint_frequency_options: {
    calendar_year_quarter: 'Calendar year quarter',
    calendar_year: 'Calendar year',
    financial_year_quarter: 'Fiscal year quarter',
    financial_year: 'Fiscal year',
    monthly: 'Monthly',
  },

  price_type: {
    fixed: 'Fixed',
    hourly: 'Hourly',
  },

  invoice_frequency: {
    once: 'Once',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    'semi-annually': 'Semi-annually',
    annually: 'Annually',
  },

  price_deadline: {
    op1: 'Calendar year end + 160 days',
    op2: 'Fiscal year end + 6 months',
    op3: 'Calendar quarter end + 40 days',
    op4: 'Month end + 26 days',
  },

  empty: 'No services',

  tasks: {
    title: 'Tasks',
    add: 'Add task',
    empty: 'No tasks',

    // Attributes
    attributes: {
      name: 'Name',
      default_role: 'Default role',
      default_time_budget: 'Estimated time',
      default_frequency: 'Default frequency',
      requires_visit: 'Requires visit',
      auto_include_in_planning: 'Auto-include in planning',
      order: 'Order',
    },

    modal: {
      add: 'New task',
    },
  },

  // Attributes
  attributes: {
    name: 'Service name',
    checkpoint_frequency: 'Checkpoint frequency',
    default_price_type: 'Default price type',
    default_invoice_frequency: 'Default invoice frequency',
    default_fixed_price: 'Default annual base fee',
    default_price_adjustment: 'Default price adjustment',
    default_hourly_price_adjustment: 'Default hourly price adjustment',
    default_dead_line: 'Project default deadline',
  },

  // Tooltips
  tooltip: {
    edit: 'Edit "{name}" service',
    destroy: 'Delete "{name}" service',
  },

  // Confirms
  confirm: {
    destroy: {
      title: 'Delete service',
      text: 'Are you sure you want to delete <strong>{name}</strong> from the system? <br /> Existing projects will not be affected.',
    },
  },
};
