import route from 'ziggy-js';
import { SearchParamsOption } from 'ky';
import httpClient from '@/services/api/ky';
import { IResponse } from '@/types/Response';
import { ITimeSheetPreviewResource, ITimeSheetRequestBody, ITimeSheetResource } from '@/types/TimeSheet';

export default {
  async index(searchParams?: SearchParamsOption): Promise<IResponse<ITimeSheetPreviewResource[]>> {
    return await httpClient.get(route('time-periods.time-sheets.index'), { searchParams }).json();
  },
  async get(timeSheet: number): Promise<IResponse<ITimeSheetResource>> {
    return await httpClient.get(route('time-periods.time-sheets.get', { timeSheet })).json();
  },
  async update(timeSheet: number, body: ITimeSheetRequestBody): Promise<IResponse<ITimeSheetResource>> {
    return await httpClient.patch(route('time-periods.time-sheets.update', { timeSheet }), { json: body }).json();
  },
};
