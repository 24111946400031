import validator from 'validator';
import orgnrValidator from './orgnr-validator';

export function isEmail(value: string): boolean {
  return validator.isEmail(value);
}

export function isLength(value: string, option?: { min?: number; max?: number }): boolean {
  return validator.isLength(value, option);
}

export function isPersonalNumber(value: string): boolean {
  const orgnumberRGEX = /^(19|20)[0-9]{6}[0-9]{4}$/;
  return orgnumberRGEX.test(value);
}

export function isOrgNumber(value: string): boolean {
  return orgnrValidator(value);
}
