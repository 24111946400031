<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { TimeSheetReviewStatusType } from '@/types/TimeSheet';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  status: TimeSheetReviewStatusType;
}>();

const color = computed(() => {
  switch (props.status) {
    case 'approved':
      return 'is-success';
    case 'not_approved':
      return 'is-danger';
    default:
      return '';
  }
});

const icon = computed(() => {
  switch (props.status) {
    case 'approved':
      return 'ti-check';
    case 'not_approved':
      return 'ti-ban';
    default:
      return 'ti-hourglass-high';
  }
});
</script>

<template>
  <span class="badge" :class="color">
    <i class="ti" :class="icon" />
    <span v-if="status" v-text="t(`time-sheet.review_status.${status}`)" />
    <span v-else v-text="t('time-sheet.review_status.none')" />
  </span>
</template>
