<script lang="ts" setup>
const props = defineProps<{
  nowrap?: boolean;
}>();
</script>

<template>
  <th v-bind="$attrs" :class="{ nowrap: props.nowrap }">
    <slot />
  </th>
</template>
