<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { VueFinalModal } from 'vue-final-modal';

import { AppButton, FormLabel, FormTextarea } from '@/components';

const { t } = useI18n({ useScope: 'global' });

const props = defineProps<{
  uid: string;
  initialNote: null | string;
}>();

const note = ref<null | string>(null);

const emit = defineEmits<{
  save: [uid: string, note: null | string];
}>();

onMounted(() => {
  note.value = props.initialNote;
});
</script>

<template>
  <VueFinalModal
    class="modal-overlay"
    content-class="modal-container"
    :click-to-close="false"
    :esc-to-close="true"
    v-slot="{ close }"
  >
    <form @submit.prevent="emit('save', uid, note)">
      <div class="modal-header">
        <h2 v-text="t('internal-projects.activities.table.note')" />
      </div>
      <div class="modal-content">
        <div class="form-group">
          <FormLabel html-for="note">{{ t('common.text') }}</FormLabel>
          <FormTextarea v-model="note" id="note" autosize />
        </div>
        <slot />
      </div>
      <div class="modal-footer">
        <div class="d-flex justify-content-between">
          <AppButton light @click.prevent="close">
            {{ t('common.close') }}
          </AppButton>
          <AppButton color="success">
            {{ t('common.save') }}
          </AppButton>
        </div>
      </div>
    </form>
  </VueFinalModal>
</template>
